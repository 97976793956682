<template>
  <svg
    v-if="filled"
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11.5723 2.18074V5.08699"
      stroke="#615DF4"
      stroke-width="2.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M16.4162 2.18074V5.08699"
      stroke="#615DF4"
      stroke-width="2.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M21.2597 2.18074V5.08699"
      stroke="#615DF4"
      stroke-width="2.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M7.21279 9.9308H25.619C25.876 9.9308 26.1224 10.0329 26.3041 10.2145C26.4857 10.3962 26.5878 10.6426 26.5878 10.8996V22.5246C26.5894 22.9067 26.5153 23.2853 26.3698 23.6386C26.2243 23.9919 26.0103 24.313 25.7401 24.5832C25.47 24.8533 25.1489 25.0674 24.7956 25.2128C24.4423 25.3583 24.0636 25.4324 23.6815 25.4308H9.15029C8.76819 25.4324 8.38955 25.3583 8.03623 25.2128C7.6829 25.0674 7.36188 24.8533 7.09169 24.5832C6.8215 24.313 6.60749 23.9919 6.46201 23.6386C6.31652 23.2853 6.24244 22.9067 6.24404 22.5246V10.8996C6.24404 10.6426 6.34611 10.3962 6.52778 10.2145C6.70946 10.0329 6.95587 9.9308 7.21279 9.9308Z"
      fill="#615DF4"
      stroke="#615DF4"
      stroke-width="2.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M30.463 11.8683L26.588 14.7745"
      stroke="#615DF4"
      stroke-width="2.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M2.36908 11.8683L6.24408 14.7745"
      stroke="#615DF4"
      stroke-width="2.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
  <svg
    v-else
    width="32"
    height="33"
    viewBox="0 0 32 33"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_951_327)">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M15.9893 2.49193C16.7192 2.49193 17.3109 3.08365 17.3109 3.81357V6.83257C17.3109 7.56249 16.7192 8.15421 15.9893 8.15421C15.2594 8.15421 14.6677 7.56249 14.6677 6.83257V3.81357C14.6677 3.08365 15.2594 2.49193 15.9893 2.49193Z"
        fill="#434263"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M10.9839 2.49193C11.7138 2.49193 12.3056 3.08365 12.3056 3.81357V6.83257C12.3056 7.56249 11.7138 8.15421 10.9839 8.15421C10.254 8.15421 9.66228 7.56249 9.66228 6.83257V3.81357C9.66228 3.08365 10.254 2.49193 10.9839 2.49193ZM21.021 2.49193C21.7509 2.49193 22.3426 3.08365 22.3426 3.81357V6.83257C22.3426 7.56249 21.7509 8.15421 21.021 8.15421C20.2911 8.15421 19.6994 7.56249 19.6994 6.83257V3.81357C19.6994 3.08365 20.2911 2.49193 21.021 2.49193ZM31.4712 14.9344C32.0551 14.4964 32.1735 13.668 31.7355 13.0841C31.2976 12.5001 30.4692 12.3818 29.8852 12.8198L27.8248 14.3651V12.5494C27.8248 11.3815 26.9151 10.4348 25.7929 10.4348H6.18579C5.06361 10.4348 4.1539 11.3815 4.1539 12.5494V14.3492L2.1147 12.8198C1.53076 12.3818 0.702359 12.5001 0.264406 13.0841C-0.173547 13.668 -0.0552036 14.4964 0.528733 14.9344L4.1539 17.6533V25.0557C4.1539 27.3914 5.97332 29.2849 8.21768 29.2849H23.761C26.0054 29.2849 27.8248 27.3914 27.8248 25.0557V17.6692L31.4712 14.9344ZM6.69376 25.0557V13.0781H25.285V25.0557C25.285 25.9316 24.6027 26.6417 23.761 26.6417H8.21768C7.37604 26.6417 6.69376 25.9316 6.69376 25.0557Z"
        fill="#434263"
      />
    </g>
    <defs>
      <clipPath id="clip0_951_327">
        <rect
          width="32"
          height="32"
          fill="white"
          transform="translate(0 0.400391)"
        />
      </clipPath>
    </defs>
  </svg>
</template>
<script>
export default {
  name: 'NutritionIcon',
  props: {
    filled: {
      type: Boolean,
      default: false,
    },
  },
}
</script>
