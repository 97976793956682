var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.source.additionalInfo.do && _vm.source.additionalInfo.do.length
    ? _c("div", { staticClass: "designation-tab" }, [
        _c("p", { staticClass: "title" }, [
          _vm._v(_vm._s(_vm.$t("origin.tab.label"))),
        ]),
        _vm._v(" "),
        _c("p", {
          staticClass: "content",
          domProps: { innerHTML: _vm._s(_vm.source.additionalInfo.do) },
        }),
      ])
    : _c("EmptyState", { attrs: { type: "designation", source: _vm.source } })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }