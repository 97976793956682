/**
 * Codifica el string proporcionado en un formato apto para URL.
 * Esta función se utiliza para preparar los parámetros shortLink para las solicitudes de API,
 * asegurando que cualquier carácter especial en el shortLink esté correctamente codificado
 * para evitar problemas con el análisis de URL.
 *
 * @param {string} url - El enlace a codificar.
 * @returns {string} La URL codificada, adecuada para su uso en solicitudes de API.
 *
 * @example
 * // Entrada:
 * const shortLink = '05410041001204?linkType=all';
 *
 * // Salida:
 * const encodedLink = encodedURL(shortLink);
 * console.log(encodedLink);
 * // Resultado: '%2005410041001204%3F%26linkType%3Dall'
 */
export const encodedURL = (url) => {
  return encodeURIComponent(encodeURIComponent(url));
}

