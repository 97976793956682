import { render, staticRenderFns } from "./NoContent.vue?vue&type=template&id=63ffc544&scoped=true"
import script from "./NoContent.vue?vue&type=script&lang=js"
export * from "./NoContent.vue?vue&type=script&lang=js"
import style0 from "./NoContent.vue?vue&type=style&index=0&id=63ffc544&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@nuxt/webpack/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "63ffc544",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/app/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('63ffc544')) {
      api.createRecord('63ffc544', component.options)
    } else {
      api.reload('63ffc544', component.options)
    }
    module.hot.accept("./NoContent.vue?vue&type=template&id=63ffc544&scoped=true", function () {
      api.rerender('63ffc544', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "components/ErrorRenderer/components/NoContent.vue"
export default component.exports