var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.source?.additionalInfo?.useMode &&
    _vm.source.additionalInfo.useMode
    ? _c("div", { staticClass: "ingredients-tab" }, [
        _c("p", { staticClass: "title" }, [
          _vm._v(_vm._s(_vm.$t("use.tab.label"))),
        ]),
        _vm._v(" "),
        _c("p", {
          staticClass: "content",
          domProps: { innerHTML: _vm._s(_vm.source.additionalInfo.useMode) },
        }),
        _vm._v(" "),
        _c("p", { staticClass: "title" }, [
          _vm._v(_vm._s(_vm.$t("composition.page.storage.title"))),
        ]),
        _vm._v(" "),
        _c("p", {
          staticClass: "content",
          domProps: { innerHTML: _vm._s(_vm.source.additionalInfo.storage) },
        }),
      ])
    : _c("EmptyState", { attrs: { type: "ingredients", source: _vm.source } })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }