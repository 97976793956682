export default {
  Afghanistan: '004',
  Albania: '008',
  Algeria: '012',
  'American Samoa': '016',
  Andorra: '020',
  Angola: '024',
  Anguilla: '660',
  Antarctica: '010',
  'Antigua and Barbuda': '028',
  Argentina: '032',
  Armenia: '051',
  Aruba: '533',
  Australia: '036',
  Austria: '040',
  Azerbaijan: '031',
  Bahamas: '044',
  Bahrain: '048',
  Bangladesh: '050',
  Barbados: '052',
  Belarus: '112',
  Belgium: '056',
  Belize: '084',
  Benin: '204',
  Bermuda: '060',
  Bhutan: '064',
  Bolivia: '068',
  'Bosnia and Herzegovina': '070',
  Botswana: '072',
  Brazil: '076',
  'British Virgin Islands': '092',
  'Brunei Darussalam': '096',
  Bulgaria: '100',
  'Burkina Faso': '854',
  Burundi: '108',
  'Cabo Verde': '132',
  Cambodia: '116',
  Cameroon: '120',
  Canada: '124',
  'Cayman Islands': '136',
  'Central African Republic': '140',
  Chad: '148',
  Chile: '152',
  China: '156',
  Colombia: '170',
  Comoros: '174',
  'Congo (Congo-Brazzaville)': '178',
  'Cook Islands': '184',
  'Costa Rica': '188',
  Croatia: '191',
  Cuba: '192',
  Cyprus: '196',
  'Czech Republic': '203',
  Denmark: '208',
  Djibouti: '262',
  Dominica: '212',
  'Dominican Republic': '214',
  Ecuador: '218',
  Egypt: '818',
  'El Salvador': '222',
  'Equatorial Guinea': '226',
  Eritrea: '232',
  Estonia: '233',
  Eswatini: '748',
  Ethiopia: '231',
  Fiji: '242',
  Finland: '246',
  France: '250',
  'French Guiana': '254',
  'French Polynesia': '258',
  Gabon: '266',
  Gambia: '270',
  Georgia: '268',
  Germany: '276',
  Ghana: '288',
  Gibraltar: '292',
  Greece: '300',
  Greenland: '304',
  Grenada: '308',
  Guadeloupe: '312',
  Guam: '316',
  Guatemala: '320',
  Guernsey: '831',
  Guinea: '324',
  'Guinea-Bissau': '624',
  Guyana: '328',
  Haiti: '332',
  Honduras: '340',
  'Hong Kong': '344',
  Hungary: '348',
  Iceland: '352',
  India: '356',
  Indonesia: '360',
  Iran: '364',
  Iraq: '368',
  Ireland: '372',
  'Isle of Man': '833',
  Israel: '376',
  Italy: '380',
  Jamaica: '388',
  Japan: '392',
  Jersey: '832',
  Jordan: '400',
  Kazakhstan: '398',
  Kenya: '404',
  Kiribati: '296',
  Kuwait: '414',
  Kyrgyzstan: '417',
  Laos: '418',
  Latvia: '428',
  Lebanon: '422',
  Lesotho: '426',
  Liberia: '430',
  Libya: '434',
  Liechtenstein: '438',
  Lithuania: '440',
  Luxembourg: '442',
  Madagascar: '450',
  Malawi: '454',
  Malaysia: '458',
  Maldives: '462',
  Mali: '466',
  Malta: '470',
  'Marshall Islands': '584',
  Martinique: '474',
  Mauritania: '478',
  Mauritius: '480',
  Mexico: '484',
  Moldova: '498',
  Monaco: '492',
  Mongolia: '496',
  Montenegro: '499',
  Montserrat: '500',
  Morocco: '504',
  Mozambique: '508',
  Myanmar: '104',
  Namibia: '516',
  Nauru: '520',
  Nepal: '524',
  Netherlands: '528',
  'New Caledonia': '540',
  'New Zealand': '554',
  Nicaragua: '558',
  Niger: '562',
  Nigeria: '566',
  Niue: '570',
  'Norfolk Island': '574',
  'North Korea': '408',
  'North Macedonia': '807',
  Norway: '578',
  Oman: '512',
  Pakistan: '586',
  Palau: '585',
  Panama: '591',
  'Papua New Guinea': '598',
  Paraguay: '600',
  Peru: '604',
  Philippines: '608',
  Poland: '616',
  Portugal: '620',
  'Puerto Rico': '630',
  Qatar: '634',
  Romania: '642',
  Russia: '643',
  Rwanda: '646',
  'Saint Kitts and Nevis': '659',
  'Saint Lucia': '662',
  'Saint Vincent and the Grenadines': '670',
  Samoa: '882',
  'San Marino': '674',
  'Sao Tome and Principe': '678',
  'Saudi Arabia': '682',
  Senegal: '686',
  Serbia: '688',
  Seychelles: '690',
  'Sierra Leone': '694',
  Singapore: '702',
  Slovakia: '703',
  Slovenia: '705',
  'Solomon Islands': '090',
  Somalia: '706',
  'South Africa': '710',
  'South Korea': '410',
  'South Sudan': '728',
  Spain: '724',
  'Sri Lanka': '144',
  Sudan: '729',
  Suriname: '740',
  Sweden: '752',
  Switzerland: '756',
  Syria: '760',
  Taiwan: '158',
  Tajikistan: '762',
  Tanzania: '834',
  Thailand: '764',
  'Timor-Leste': '626',
  Togo: '768',
  Tonga: '776',
  'Trinidad and Tobago': '780',
  Tunisia: '788',
  Turkey: '792',
  Turkmenistan: '795',
  Tuvalu: '798',
  Uganda: '800',
  Ukraine: '804',
  'United Arab Emirates': '784',
  'United Kingdom': '826',
  'United States': '840',
  Uruguay: '858',
  Uzbekistan: '860',
  Vanuatu: '548',
  'Vatican City': '336',
  Venezuela: '862',
  Vietnam: '704',
  Yemen: '887',
  Zambia: '894',
  Zimbabwe: '716',
}
