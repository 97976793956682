var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm._m(0)
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "content" }, [
      _c("div", { staticClass: "content-body" }, [
        _c("img", {
          staticClass: "logo",
          attrs: {
            src: require("assets/images/logo.svg"),
            alt: "digitallinkLogo",
          },
        }),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "h-100 d-flex flex-column justify-content-between" },
          [
            _c(
              "div",
              {
                staticClass: "mt-3 w-100 d-flex flex-column align-items-center",
              },
              [
                _c(
                  "p",
                  { staticClass: "text-center fw-bolder fs-3 mt-4 w-75" },
                  [
                    _vm._v(
                      "\n          Sorry, this QR code has been deactivated.\n        "
                    ),
                  ]
                ),
                _vm._v(" "),
                _c("p", { staticClass: "fw-400 w-75 fs-5 text-center" }, [
                  _vm._v(
                    "\n          Is this your QR? Log in to your digital-link.com account to reactivate it.\n        "
                  ),
                ]),
                _vm._v(" "),
                _c("img", {
                  staticClass: "headstone-image",
                  attrs: { src: require("assets/images/headstone.svg") },
                }),
              ]
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "d-flex flex-column align-items-center" },
              [
                _c("button", { staticClass: "button mb-3 black" }, [
                  _vm._v("Log in now"),
                ]),
                _vm._v(" "),
                _c("img", {
                  staticClass: "pt-4",
                  attrs: {
                    src: require("assets/images/powered-by-digitallink.svg"),
                  },
                }),
              ]
            ),
          ]
        ),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }