var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("transition", { attrs: { name: "modal" } }, [
    _vm.isVisible
      ? _c(
          "div",
          {
            staticClass: "modal-overlay",
            on: {
              click: function ($event) {
                if ($event.target !== $event.currentTarget) return null
                return _vm.closeModal.apply(null, arguments)
              },
            },
          },
          [
            _c(
              "div",
              { staticClass: "modal-content" },
              [
                _c("div", { staticClass: "modal-header" }, [
                  _vm._v("\n      " + _vm._s(_vm.title) + "\n    "),
                ]),
                _vm._v(" "),
                _vm._t("default"),
                _vm._v(" "),
                _c("div", { staticClass: "modal-footer" }, [
                  _c("button", { on: { click: _vm.closeModal } }, [
                    _vm._v(_vm._s(_vm.$t("component.button.close.label"))),
                  ]),
                ]),
              ],
              2
            ),
          ]
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }