<template>
  <div v-if="Boolean(impact)" class="instruction-wrapper">
    <div class="title">{{ $t('environmental.page.title') }}</div>
    <div class="instruction">
      <p v-html="impact" />
    </div>
  </div>
  <SmartPageEmptyStateComponent v-else :source="source" type="sustainability" />
</template>

<script>
import SmartPageEmptyStateComponent from '~/components/SmartPage/components/common/EmptyState.vue'

export default {
  components: { SmartPageEmptyStateComponent },
  props: {
    source: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    additionalInfo() {
      return this.source?.additionalInfo || {}
    },
    impact() {
      return this.additionalInfo?.impact || []
    },
  },
}
</script>

<style scoped lang="scss">
.instruction-wrapper {
  .title {
    color: #32312f;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }

  .instruction {
    font-family: Inter, serif;
    margin: 10px 0;

    .step {
      text-transform: uppercase;
      color: var(--DL-Black, #32312f);
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: 23px;
      margin-bottom: 10px;
    }
  }

  p {
    color: var(--DL-Black, #32312f);
    font-family: Inter, serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 23px;
  }
}
</style>
