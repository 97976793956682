var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.source.allergens.mayContain.length > 0 ||
    _vm.source.allergens.contains.length > 0
    ? _c("div", { staticClass: "allergens-tab" }, [
        _c("p", { staticClass: "title" }, [
          _vm._v(_vm._s(_vm.$t("allergens.page.title"))),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "body" }, [
          _c("p", [
            _vm._v(_vm._s(_vm.textFormatter(_vm.source.allergens.contains))),
          ]),
          _vm._v(" "),
          _vm.source.allergens.mayContain.length > 0
            ? _c("p", [
                _vm._v(
                  "\n      " +
                    _vm._s(_vm.$t("allergens.page.traces.label")) +
                    "\n      " +
                    _vm._s(_vm.textFormatter(_vm.source.allergens.mayContain)) +
                    "\n    "
                ),
              ])
            : _vm._e(),
        ]),
      ])
    : _c("EmptyState", { attrs: { type: "allergens", source: _vm.source } })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }