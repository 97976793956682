<template>
  <svg
    id="Layer_1"
    style="width: 35px"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    width="100%"
    viewBox="0 0 240 222"
    enable-background="new 0 0 240 222"
    xml:space="preserve"
  >
    <path
      fill="#FDFEFD"
      opacity="1.000000"
      stroke="none"
      d="
M241.000000,81.000000
	C241.000000,128.666672 241.000000,175.833328 241.000000,223.000000
	C161.000000,223.000000 81.000000,223.000000 1.000000,223.000000
	C1.000000,149.000000 1.000000,75.000000 1.000000,1.000000
	C81.000000,1.000000 161.000000,1.000000 241.000000,1.000000
	C241.000000,27.500000 241.000000,54.000000 241.000000,81.000000
M179.008621,198.359543
	C179.452026,197.952530 179.895447,197.545517 180.872940,197.088730
	C181.253983,196.740997 181.635025,196.393265 182.667770,195.949417
	C183.307938,195.374542 183.948090,194.799652 184.880112,194.216263
	C184.880112,194.216263 184.991211,193.946259 185.640579,193.854980
	C187.098465,192.574448 188.556351,191.293900 190.000000,190.000000
	C190.000000,190.000000 190.012634,190.014740 190.664093,189.872635
	C192.256607,188.303604 193.849121,186.734573 195.968857,184.960907
	C196.702225,184.119446 197.435593,183.277985 198.803818,182.171204
	C200.683487,179.959839 202.563141,177.748459 204.454269,175.439011
	C204.454269,175.439011 204.549973,175.414688 205.077072,175.059845
	C206.422501,173.064835 207.767929,171.069824 209.013031,169.025894
	C209.013031,169.025894 209.084656,169.111496 209.653397,168.829865
	C210.132019,167.904831 210.610657,166.979813 211.004379,166.017242
	C211.004379,166.017242 211.064362,166.088120 211.655197,165.757523
	C215.076889,158.169113 218.498596,150.580704 222.334412,142.485336
	C222.527527,141.650452 222.720627,140.815567 223.362335,139.449249
	C223.738953,137.621292 224.115570,135.793320 224.847137,133.356247
	C224.855026,132.892380 224.862915,132.428513 225.221237,131.437393
	C225.298508,130.612564 225.375778,129.787750 225.839508,128.367859
	C225.853210,127.901428 225.866913,127.434998 226.265518,126.268433
	C227.811234,113.444618 228.353409,100.660507 224.871353,87.471466
	C224.654068,86.953499 224.436783,86.435532 224.302292,85.397850
	C224.188248,85.264565 224.074203,85.131271 223.997757,84.294807
	C223.803528,83.875175 223.609299,83.455544 223.569366,82.374100
	C222.998444,80.931450 222.427521,79.488808 221.934311,77.464287
	C221.677078,76.957733 221.419861,76.451172 221.161530,75.265915
	C220.883316,74.924019 220.605118,74.582123 220.383362,73.579262
	C219.829880,72.393890 219.276398,71.208519 218.760559,69.375282
	C218.315292,68.656715 217.870010,67.938148 217.449783,66.740952
	C217.363480,66.409729 217.166718,66.168724 216.846191,65.306564
	C215.292404,62.703178 213.738602,60.099792 212.188690,57.167461
	C212.188690,57.167461 211.897369,57.014637 211.834793,56.373856
	C211.293152,55.725368 210.751511,55.076881 210.224945,54.141239
	C210.224945,54.141239 209.968613,54.010941 209.889267,53.365978
	C209.328461,52.721565 208.767654,52.077152 208.108368,50.951336
	C207.764832,50.624725 207.421280,50.298119 206.983551,49.373047
	C206.440155,48.801094 205.896759,48.229141 205.031418,47.979198
	C205.031418,47.979198 205.357117,47.654716 205.182465,47.001194
	C202.448242,44.012886 199.714020,41.024574 197.000610,37.999390
	C197.000610,37.999390 196.963669,38.020103 196.756241,37.444042
	C196.199722,37.216915 195.643219,36.989784 194.942703,36.274857
	C194.657471,36.100784 194.372223,35.926708 193.857666,35.126938
	C191.632065,33.181591 189.406464,31.236244 186.929077,28.713112
	C184.263550,27.052721 181.598007,25.392328 178.963852,23.266354
	C178.816620,23.013538 178.669388,22.760723 178.522125,22.507906
	C178.358170,22.882450 178.194214,23.256992 177.234161,23.746258
	C176.974579,23.813295 176.714996,23.880335 176.462509,24.038328
	C176.462509,24.038328 176.553238,24.047758 176.287201,23.536541
	C175.936417,23.089806 175.585632,22.643070 174.778915,21.788109
	C170.093292,19.217270 165.624176,16.085300 160.682297,14.178685
	C144.557617,7.957642 127.596680,6.276334 110.638893,8.301280
	C84.587387,11.412115 62.632065,23.176510 45.369507,43.085899
	C24.142012,67.568161 16.343822,96.215233 21.353941,127.931923
	C25.247143,152.577927 37.371265,173.178909 56.294464,189.556183
	C58.493221,191.459091 61.559994,192.359055 64.190392,193.744385
	C64.190392,193.744385 64.213142,193.762650 64.045906,194.490952
	C65.015717,195.016739 65.985527,195.542526 67.452164,196.124512
	C67.616936,196.099411 67.781700,196.074326 67.987694,195.999985
	C67.987694,195.999985 67.961617,196.057266 68.192528,196.682541
	C70.799484,198.452560 73.406433,200.222580 76.140175,202.230865
	C76.140175,202.230865 76.409676,202.216492 76.815765,202.816269
	C80.547302,204.576569 84.278839,206.336868 88.167679,208.272293
	C88.167679,208.272293 88.395020,208.211197 88.940971,208.742416
	C90.629471,209.166351 92.317963,209.590271 94.375610,210.329529
	C94.375610,210.329529 94.844780,210.457901 94.844780,210.457901
	C94.844780,210.457901 95.300003,210.286469 95.876457,210.824554
	C100.928398,211.737442 105.980339,212.650330 111.750526,213.851547
	C112.516792,213.842621 113.283058,213.833694 114.772575,214.175446
	C120.163109,214.058212 125.553650,213.940964 131.703186,213.981979
	C132.458771,213.844101 133.214355,213.706223 134.690552,213.781052
	C136.119629,213.495728 137.548706,213.210388 139.742706,213.043655
	C140.483826,212.858932 141.224960,212.674210 142.739685,212.615356
	C151.188004,209.764542 159.636322,206.913727 168.025192,204.005585
	C168.025192,204.005585 168.038452,204.087112 168.708969,204.074692
	C169.826736,203.399170 170.944489,202.723648 172.017288,201.993668
	C172.017288,201.993668 172.038681,202.060547 172.675751,202.032776
	C173.539413,201.450287 174.403076,200.867798 175.098419,200.135223
	C175.098419,200.135223 175.218674,200.326004 175.845184,200.255875
	C176.692688,199.672958 177.540192,199.090027 178.095856,197.999344
	C178.199066,198.165298 178.302277,198.331268 179.008621,198.359543
z"
    />
    <path
      fill="#D4E4A3"
      opacity="1.000000"
      stroke="none"
      d="
M176.553238,24.047760
	C176.553238,24.047758 176.462509,24.038328 176.652496,24.301758
	C180.191818,27.458588 183.541168,30.351984 187.034821,33.537346
	C189.093079,35.919697 191.007034,38.010086 192.942627,40.049042
	C192.964279,39.997601 192.888367,40.079449 193.000549,40.370773
	C193.680450,41.259151 194.248169,41.856197 194.877380,42.697433
	C195.269302,43.304169 195.599701,43.666714 195.986954,44.371326
	C197.386230,46.801006 198.728668,48.888611 200.107819,51.263157
	C200.458267,52.023464 200.772018,52.496830 201.101501,53.303719
	C201.677353,54.593769 202.237442,55.550297 202.789520,56.655144
	C202.781494,56.803463 203.004074,57.000191 203.028229,57.378555
	C205.416565,68.494934 207.780716,79.232956 210.081146,90.311111
	C210.059631,91.108742 210.101822,91.566231 210.042007,92.439529
	C209.568771,105.101555 206.350586,116.697304 201.808945,128.095413
	C201.710663,128.177704 201.763290,128.428619 201.494873,128.644409
	C200.804932,129.899551 200.383392,130.938919 199.982574,131.987122
	C200.003311,131.995972 199.973511,131.961456 199.693604,132.146164
	C197.282608,135.890915 195.151505,139.450943 193.009430,143.005936
	C192.998444,143.000900 193.012833,143.021454 192.747986,143.156097
	C192.232559,143.812881 191.981979,144.335022 191.788040,144.856750
	C191.844696,144.856323 191.783340,144.761047 191.497025,144.881866
	C190.495880,146.013321 189.781036,147.023956 189.033142,148.022675
	C189.000061,148.010727 189.038254,148.069778 188.734863,148.201096
	C185.649597,151.232788 182.867691,154.133148 179.843063,157.110138
	C179.396317,157.449112 179.192307,157.711456 178.676788,158.034210
	C177.471924,158.951996 176.578537,159.809387 175.734192,160.736847
	C175.783218,160.806915 175.762421,160.637146 175.466370,160.692749
	C174.735596,161.145859 174.300873,161.543396 173.656494,161.966003
	C173.161774,162.091705 172.965225,162.283173 172.558350,162.648056
	C171.819046,163.138672 171.378571,163.546707 170.967651,163.976456
	C170.997223,163.998169 170.959000,163.935562 170.630157,163.995575
	C168.848022,165.022430 167.394730,165.989288 165.966949,166.979431
	C165.992447,167.002716 165.962921,166.940460 165.615204,166.992004
	C163.008423,168.296021 160.749374,169.548523 158.345718,170.786713
	C158.201126,170.772415 158.007111,170.988770 157.657562,171.000168
	C156.057999,171.613266 154.808014,172.214966 153.400772,172.791992
	C153.243530,172.767349 152.996887,172.968597 152.734589,172.960052
	C152.321472,173.040161 152.170654,173.128815 151.736893,173.208130
	C150.970413,173.468338 150.486862,173.737915 149.629745,173.999893
	C147.632202,174.577118 146.008224,175.161942 144.149139,175.665756
	C143.568222,175.593887 143.263596,175.710510 142.597473,175.910782
	C140.143097,176.327225 138.091370,176.767548 135.652985,177.176147
	C134.521683,177.329086 133.777084,177.513748 132.635498,177.579224
	C129.458298,177.692902 126.678078,177.925781 123.259567,178.212128
	C123.259567,185.948669 123.259567,193.455597 123.259567,201.652863
	C119.676308,200.518997 116.900185,199.640533 114.095093,198.821732
	C114.066132,198.881378 114.180313,198.813934 113.979767,198.549286
	C112.560547,197.774277 111.341881,197.263947 110.107124,196.819061
	C110.091042,196.884491 110.190514,196.793564 110.025696,196.524811
	C108.909462,195.835205 107.958046,195.414352 107.003319,194.996735
	C107.000000,195.000000 107.005775,194.992996 106.841919,194.708160
	C104.781319,193.308777 102.884583,192.194229 100.901810,190.952423
	C100.815765,190.825165 100.510269,190.792770 100.331238,190.496948
	C97.439743,188.122223 94.727287,186.043335 91.869492,183.669403
	C89.522179,181.233078 87.320190,179.091812 85.031296,176.643982
	C83.937126,175.282776 82.929863,174.228149 81.933510,173.118958
	C81.944412,173.064392 81.833199,173.068176 81.761398,172.786987
	C81.155853,171.965652 80.622108,171.425491 80.055229,170.933929
	C80.022095,170.982513 80.120529,170.918121 80.053848,170.616669
	C79.328506,169.545929 78.669861,168.776642 78.007843,168.002167
	C78.004478,167.996979 77.997673,167.986710 77.933990,167.667847
	C76.955612,166.139648 76.040924,164.930313 75.081886,163.457199
	C74.717911,162.784592 74.398293,162.375748 74.043983,161.674194
	C73.655663,160.927216 73.302032,160.472977 72.916885,159.728851
	C72.556488,158.970306 72.227623,158.501633 71.872124,157.689850
	C70.913681,155.558945 69.981880,153.771164 69.034531,151.604370
	C68.968063,150.469666 68.917137,149.713974 69.081894,148.770355
	C69.439499,148.045975 69.581413,147.509491 69.875694,146.767349
	C70.096504,146.232071 70.164940,145.902435 70.510323,145.342072
	C72.189430,142.406250 73.591591,139.701172 74.996872,136.998047
	C75.000000,137.000000 74.995453,136.993530 75.276649,136.823364
	C76.721413,134.775269 77.884979,132.897339 79.272774,130.900818
	C79.727165,130.339462 79.957321,129.896729 80.466049,129.297119
	C81.190880,128.437943 81.637131,127.735634 82.307877,126.934830
	C82.820114,126.633606 82.976395,126.356682 83.266472,125.876640
	C83.799553,125.237396 84.067375,124.727066 84.265839,124.215698
	C84.196472,124.214661 84.303818,124.302544 84.613480,124.189804
	C89.964211,119.395760 95.005272,114.714455 100.024475,110.015747
	C100.002617,109.998344 100.029381,110.047173 100.322739,109.989670
	C101.183563,109.410042 101.751022,108.887917 102.272827,108.182503
	C102.227180,107.999214 102.295441,108.369064 102.618195,108.300247
	C104.360992,107.227753 105.781044,106.224068 107.121651,105.154938
	C107.042213,105.089500 107.162376,105.256630 107.475967,105.206375
	C108.639206,104.596588 109.488846,104.037056 110.157486,103.388168
	C109.976486,103.298805 110.352097,103.446335 110.714035,103.384377
	C114.823105,101.747528 118.570244,100.172638 123.329285,98.172455
	C123.329285,106.797089 123.329285,114.318001 123.329285,123.035362
	C126.657799,121.431770 128.937378,120.333527 131.162506,119.207794
	C131.108047,119.180305 131.147461,119.295746 131.517426,119.287773
	C133.782761,118.265625 135.678101,117.251442 137.701172,116.260117
	C137.828888,116.282959 137.992111,116.081223 138.270508,116.060036
	C138.790512,115.775360 139.032120,115.511871 139.207962,115.239517
	C139.142197,115.230652 139.231506,115.328835 139.570389,115.266312
	C141.939026,113.802704 143.968781,112.401611 145.999268,111.000259
	C146.000000,111.000000 146.000961,110.998947 146.318726,110.943413
	C147.775787,109.933159 148.915100,108.978439 150.282364,107.960701
	C150.809372,107.739891 150.996796,107.496246 151.391296,107.067833
	C153.441528,105.300049 155.173065,103.631180 157.145996,101.859985
	C157.578888,101.472977 157.770401,101.188278 158.250839,100.803787
	C159.305496,99.782333 160.071243,98.860687 161.076752,97.828995
	C161.604721,97.289818 161.892960,96.860680 162.490448,96.282707
	C164.860992,93.422272 166.922241,90.710670 169.101074,87.925095
	C169.218674,87.851112 169.242233,87.574265 169.532104,87.375069
	C171.672348,83.868652 173.522720,80.561424 175.374451,77.172264
	C175.375809,77.090324 175.332962,77.248451 175.586273,77.061172
	C176.237106,75.919830 176.634628,74.965767 177.237564,73.809784
	C177.599579,73.064255 177.756180,72.520630 178.149353,71.731491
	C176.073563,66.541039 173.761230,61.596100 171.279083,56.829430
	C171.109283,57.007694 171.429260,56.633812 171.364441,56.320637
	C170.543106,54.991444 169.786591,53.975426 169.014008,52.978432
	C168.997925,52.997452 169.041092,52.973476 168.974777,52.638123
	C166.592834,49.560234 164.277206,46.817707 161.980438,44.044926
	C161.999283,44.014671 161.930618,44.033867 161.863464,43.763390
	C161.360031,43.176407 160.923737,42.859901 160.275848,42.693375
	C160.197876,42.733616 160.331512,42.623882 160.382141,42.248619
	C159.872757,41.642319 159.446365,41.301548 158.899399,40.678009
	C157.867828,39.589905 156.956848,38.784565 155.910767,37.672325
	C147.367691,29.189297 137.613724,23.139507 126.673309,18.974142
	C124.713371,18.227932 122.520744,17.237736 123.245552,21.492733
	C123.206367,26.955832 123.271324,31.965549 123.199951,37.373817
	C123.028679,39.173668 122.993736,40.574970 122.980614,41.986984
	C123.002426,41.997692 122.975052,41.957363 122.544083,41.996567
	C119.142601,42.347385 116.167488,42.620670 113.202339,42.976906
	C95.574539,45.094715 79.994171,51.738865 66.972595,63.978996
	C66.999084,63.998917 66.962563,63.943581 66.664047,64.007515
	C65.857162,64.674904 65.348793,65.278351 64.897423,65.917419
	C64.954422,65.953049 64.881912,65.839836 64.571594,65.919510
	C63.440952,66.944763 62.620621,67.890343 61.843399,68.877502
	C61.886509,68.919083 61.871498,68.800232 61.607956,68.879189
	C61.162754,69.263924 60.981094,69.569695 60.857742,69.881638
	C60.916050,69.887802 60.835365,69.802704 60.533554,69.907104
	C59.441921,70.987061 58.652096,71.962608 57.644005,73.040207
	C57.160873,73.358345 57.015625,73.636826 56.729645,74.096893
	C56.060085,74.842400 55.650871,75.468704 55.161125,76.102921
	C55.080589,76.110832 55.112873,76.269424 54.830181,76.426506
	C52.575695,79.631630 50.603905,82.679680 48.649277,85.789886
	C48.666447,85.852043 48.651505,85.736076 48.372581,85.920151
	C41.701725,96.863396 38.026478,108.471237 37.109150,121.343307
	C37.044605,122.482552 37.072918,123.248123 36.954865,124.378403
	C37.480621,132.003326 38.152744,139.263550 38.748631,146.774261
	C38.785892,147.349411 38.899391,147.674088 38.974754,148.395172
	C39.890396,151.708206 40.844177,154.624832 41.747261,157.664581
	C41.696568,157.787704 41.870274,157.989532 41.865059,158.365967
	C42.850883,160.963379 43.841927,163.184357 44.838890,165.576538
	C44.844807,165.747726 45.072716,166.003494 45.076408,166.262726
	C45.299278,166.768890 45.518452,167.015823 45.752857,167.203033
	C45.768089,167.143341 45.660709,167.203796 45.688232,167.503174
	C46.048328,168.279099 46.380901,168.755661 46.750214,169.113571
	C46.786949,168.994919 46.672878,169.215607 46.711967,169.544632
	C47.448898,170.970367 48.146744,172.067078 48.875530,173.118317
	C48.906475,173.072861 48.798592,173.094269 48.822369,173.394928
	C49.223789,174.140625 49.601433,174.585648 49.990746,175.016510
	C50.002422,175.002350 49.970066,175.017776 50.017448,175.353821
	C51.364292,177.475037 52.663750,179.260193 53.981888,181.023239
	C54.000572,181.001114 53.952545,181.033142 54.018517,181.342957
	C54.659855,182.260376 55.235229,182.867966 55.891487,183.750214
	C56.625023,184.693451 57.277672,185.362015 58.018005,186.353668
	C60.145241,189.025848 62.184795,191.374924 64.224350,193.723999
	C61.559994,192.359055 58.493221,191.459091 56.294464,189.556183
	C37.371265,173.178909 25.247143,152.577927 21.353941,127.931923
	C16.343822,96.215233 24.142012,67.568161 45.369507,43.085899
	C62.632065,23.176510 84.587387,11.412115 110.638893,8.301280
	C127.596680,6.276334 144.557617,7.957642 160.682297,14.178685
	C165.624176,16.085300 170.093292,19.217270 175.110657,22.265739
	C175.812683,23.178165 176.182953,23.612963 176.553238,24.047760
z"
    />
    <path
      fill="#03624D"
      opacity="1.000000"
      stroke="none"
      d="
M71.898758,158.032959
	C72.227623,158.501633 72.556488,158.970306 72.696434,159.909332
	C73.031227,160.908768 73.554955,161.437851 74.078674,161.966919
	C74.398293,162.375748 74.717911,162.784592 75.045685,163.840302
	C76.035118,165.653702 77.016396,166.820206 77.997673,167.986710
	C77.997673,167.986710 78.004478,167.996979 78.001740,168.361435
	C78.706169,169.456635 79.413345,170.187378 80.120529,170.918121
	C80.120529,170.918121 80.022095,170.982513 80.053795,171.255707
	C80.668060,172.041977 81.250626,172.555084 81.833191,173.068176
	C81.833199,173.068176 81.944412,173.064392 81.952339,173.475906
	C83.012909,174.908463 84.065552,175.929504 85.118202,176.950546
	C87.320190,179.091812 89.522179,181.233078 91.892105,184.020203
	C94.876793,186.708298 97.693535,188.750534 100.510269,190.792770
	C100.510269,190.792770 100.815765,190.825165 100.942596,191.307617
	C103.048210,192.857712 105.026993,193.925354 107.005775,194.992996
	C107.005775,194.992996 107.000000,195.000000 107.056824,195.354004
	C108.139275,196.069855 109.164894,196.431702 110.190514,196.793549
	C110.190514,196.793564 110.091042,196.884491 110.235046,197.143585
	C111.646141,197.873108 112.913223,198.343521 114.180313,198.813934
	C114.180313,198.813934 114.066132,198.881378 114.198654,199.174377
	C115.448685,200.098755 116.516624,200.848099 117.691940,201.341919
	C124.244606,204.095093 124.980972,203.578064 124.992340,196.340744
	C125.001137,190.736557 124.994095,185.132339 124.994095,179.944519
	C128.180969,179.054031 130.606720,178.376236 133.032486,177.698425
	C133.777084,177.513748 134.521683,177.329086 135.926514,177.412811
	C138.724548,177.099014 140.862350,176.516846 143.000153,175.934662
	C143.263596,175.710510 143.568222,175.593887 144.591156,175.738495
	C146.846603,175.263977 148.424957,174.635742 150.003311,174.007492
	C150.486862,173.737915 150.970413,173.468338 151.854660,173.345276
	C152.502533,173.317383 152.749710,173.142990 152.996887,172.968597
	C152.996887,172.968597 153.243530,172.767349 153.784851,172.847961
	C155.553146,172.281982 156.780136,171.635376 158.007111,170.988770
	C158.007111,170.988770 158.201126,170.772415 158.758453,170.840469
	C161.531479,169.585831 163.747208,168.263153 165.962921,166.940460
	C165.962921,166.940460 165.992447,167.002716 166.340271,166.997391
	C168.111740,165.973236 169.535370,164.954407 170.959000,163.935577
	C170.959000,163.935562 170.997223,163.998169 171.303894,163.962875
	C172.026093,163.473557 172.441635,163.019516 172.857162,162.565475
	C172.965225,162.283173 173.161774,162.091705 173.988892,161.965149
	C174.941467,161.505188 175.351944,161.071167 175.762421,160.637146
	C175.762421,160.637146 175.783218,160.806915 176.095566,160.818695
	C177.268036,159.878235 178.128174,158.926025 178.988297,157.973801
	C179.192307,157.711456 179.396317,157.449112 180.202820,157.105316
	C184.935806,155.313721 188.213486,152.819443 189.038254,148.069778
	C189.038254,148.069778 189.000061,148.010727 189.380066,147.978394
	C190.434494,146.884384 191.108917,145.822708 191.783340,144.761047
	C191.783340,144.761047 191.844696,144.856323 192.089890,144.769165
	C192.561005,144.128479 192.786911,143.574966 193.012833,143.021454
	C193.012833,143.021454 192.998444,143.000900 193.358505,142.970734
	C198.329330,140.719849 199.401016,136.482849 199.973511,131.961456
	C199.973511,131.961456 200.003311,131.995972 200.365814,131.923248
	C201.073318,130.709885 201.418304,129.569260 201.763290,128.428619
	C201.763290,128.428619 201.710663,128.177704 202.133575,127.945847
	C207.525238,121.352119 208.424484,113.532478 209.839676,106.034889
	C210.694382,101.506676 210.091797,96.703407 210.144012,92.023727
	C210.101822,91.566231 210.059631,91.108742 210.343964,90.005173
	C210.295074,83.631660 210.629486,77.738220 209.340134,72.224495
	C208.105438,66.944565 205.184113,62.059052 203.004074,57.000191
	C203.004074,57.000191 202.781494,56.803463 202.846161,56.264549
	C202.302475,54.807152 201.694122,53.888676 201.085770,52.970200
	C200.772018,52.496830 200.458267,52.023464 200.344208,51.053860
	C199.005981,48.381500 197.468048,46.205379 195.930115,44.029259
	C195.599701,43.666714 195.269302,43.304169 194.905014,42.326492
	C194.210220,41.167393 193.549286,40.623421 192.888367,40.079449
	C192.888367,40.079449 192.964279,39.997601 192.969757,39.668259
	C193.345810,38.143490 193.716400,36.948063 194.086990,35.752636
	C194.372223,35.926708 194.657471,36.100784 195.124817,36.808178
	C195.859192,37.567703 196.411423,37.793903 196.963669,38.020103
	C196.963669,38.020103 197.000610,37.999390 196.990051,38.383919
	C199.082199,42.380886 200.389664,46.743053 205.357117,47.654716
	C205.357117,47.654716 205.031418,47.979198 205.119629,48.167614
	C205.831146,48.894524 206.454437,49.433018 207.077728,49.971508
	C207.421280,50.298119 207.764832,50.624725 208.118683,51.561066
	C208.742188,52.784180 209.355392,53.397564 209.968613,54.010941
	C209.968613,54.010941 210.224945,54.141239 210.174042,54.648884
	C210.714554,55.775898 211.305954,56.395267 211.897369,57.014637
	C211.897369,57.014637 212.188690,57.167461 212.131882,57.739250
	C213.669876,60.880013 215.264679,63.448982 216.859497,66.017952
	C217.166718,66.168724 217.363480,66.409729 217.325333,67.340500
	C217.708221,68.634422 218.215561,69.328781 218.722900,70.023148
	C219.276398,71.208519 219.829880,72.393890 220.327286,74.261124
	C220.568359,75.276855 220.865509,75.610733 221.162643,75.944611
	C221.419861,76.451172 221.677078,76.957733 221.658722,77.961151
	C222.060455,79.983986 222.737762,81.509949 223.415070,83.035912
	C223.609299,83.455544 223.803528,83.875175 223.813873,84.754791
	C223.826492,85.449043 224.022980,85.683311 224.219482,85.917572
	C224.436783,86.435532 224.654068,86.953499 224.572998,88.044189
	C224.520615,100.499733 224.748871,112.382996 225.047562,124.264503
	C225.070374,125.172264 225.591248,126.067505 225.880615,126.968567
	C225.866913,127.434998 225.853210,127.901428 225.370560,128.747253
	C224.891342,130.072662 224.881073,131.018646 224.870804,131.964645
	C224.862915,132.428513 224.855026,132.892380 224.365265,133.767822
	C223.560181,136.113174 223.236969,138.046921 222.913757,139.980682
	C222.720627,140.815567 222.527527,141.650452 221.774231,142.806488
	C218.761765,148.425308 216.270691,153.705536 213.875153,159.028778
	C212.836777,161.336243 211.995270,163.732300 211.064362,166.088120
	C211.064362,166.088120 211.004379,166.017242 210.686707,166.067749
	C209.940918,167.116013 209.512787,168.113754 209.084656,169.111496
	C209.084656,169.111496 209.013031,169.025894 208.702606,169.059296
	C207.111450,171.200027 205.830704,173.307358 204.549957,175.414688
	C204.549973,175.414688 204.454269,175.439011 204.098145,175.364319
	C201.884338,177.671936 200.026657,180.054245 198.168961,182.436539
	C197.435593,183.277985 196.702225,184.119446 195.331543,185.032669
	C193.133713,186.741196 191.573166,188.377960 190.012634,190.014740
	C190.012634,190.014740 190.000000,190.000000 189.642746,189.993317
	C187.854065,191.306503 186.422638,192.626389 184.991211,193.946259
	C184.991211,193.946259 184.880112,194.216263 184.358307,194.179108
	C183.229691,194.776459 182.622879,195.410995 182.016083,196.045532
	C181.635025,196.393265 181.253983,196.740997 180.249023,197.095184
	C179.218567,197.566849 178.812027,198.032059 178.405502,198.497253
	C178.302277,198.331268 178.199066,198.165298 177.893860,198.121338
	C176.867462,198.937546 176.043076,199.631775 175.218674,200.326004
	C175.218674,200.326004 175.098419,200.135223 174.811005,200.127106
	C173.695282,200.766174 172.866974,201.413361 172.038666,202.060547
	C172.038681,202.060547 172.017288,201.993668 171.683334,201.995300
	C170.245743,202.693665 169.142105,203.390396 168.038452,204.087112
	C168.038452,204.087112 168.025192,204.005585 167.655884,204.008102
	C160.077896,206.220428 152.859085,208.398224 145.669800,210.669449
	C144.373001,211.079132 143.197556,211.873001 141.966080,212.489471
	C141.224960,212.674210 140.483826,212.858932 139.133514,212.727783
	C137.006210,212.797394 135.488068,213.182861 133.969940,213.568344
	C133.214355,213.706223 132.458771,213.844101 131.014343,213.634323
	C124.900108,213.466034 119.474716,213.645386 114.049324,213.824753
	C113.283058,213.833694 112.516792,213.842621 111.263214,213.363251
	C105.617264,212.012131 100.458633,211.149292 95.300003,210.286469
	C95.300003,210.286469 94.844780,210.457901 94.844780,210.457901
	C94.844780,210.457901 94.375610,210.329529 94.006592,209.827972
	C91.890060,208.954681 90.142540,208.582932 88.395020,208.211197
	C88.395020,208.211197 88.167679,208.272293 87.945297,207.865036
	C83.951843,205.710693 80.180756,203.963593 76.409668,202.216492
	C76.409676,202.216492 76.140175,202.230865 76.038788,201.763214
	C73.278809,199.549469 70.620216,197.803360 67.961617,196.057266
	C67.961617,196.057266 67.987694,195.999985 67.962662,195.675995
	C64.601852,192.244858 61.266083,189.137711 57.930321,186.030579
	C57.277672,185.362015 56.625023,184.693451 55.922379,183.381622
	C55.232441,182.169952 54.592491,181.601547 53.952545,181.033142
	C53.952545,181.033142 54.000572,181.001114 54.000160,180.638412
	C52.656521,178.523071 51.313293,176.770416 49.970066,175.017776
	C49.970066,175.017776 50.002422,175.002350 49.981758,174.703003
	C49.573593,173.967194 49.186092,173.530731 48.798592,173.094269
	C48.798592,173.094269 48.906475,173.072861 48.926460,172.746918
	C48.188591,171.352524 47.430733,170.284073 46.672874,169.215607
	C46.672878,169.215607 46.786949,168.994919 46.818069,168.779297
	C46.453030,168.110382 46.056870,167.657089 45.660713,167.203796
	C45.660709,167.203796 45.768089,167.143341 45.763580,166.942078
	C45.530285,166.495041 45.301502,166.249268 45.072716,166.003494
	C45.072716,166.003494 44.844807,165.747726 44.889168,165.163528
	C43.912441,162.382736 42.891357,160.186127 41.870274,157.989532
	C41.870274,157.989532 41.696568,157.787704 41.807625,157.207565
	C40.950085,153.751205 39.981487,150.874985 39.012890,147.998764
	C38.899391,147.674088 38.785892,147.349411 38.808475,146.286224
	C38.330112,138.369705 37.715672,131.191696 37.101231,124.013702
	C37.072918,123.248123 37.044605,122.482552 37.372200,121.089722
	C38.122177,119.559181 38.631371,118.687813 38.892120,117.747559
	C40.981979,110.211472 42.934177,102.635880 45.124866,95.129692
	C46.059258,91.928108 47.461735,88.863129 48.651505,85.736076
	C48.651505,85.736076 48.666447,85.852043 49.008904,85.762711
	C51.271866,82.538727 53.192371,79.404076 55.112877,76.269424
	C55.112873,76.269424 55.080589,76.110832 55.460670,76.029404
	C56.223831,75.291206 56.606911,74.634453 56.989990,73.977692
	C57.015625,73.636826 57.160873,73.358345 58.004578,73.029358
	C59.334064,71.878532 60.084717,70.840622 60.835365,69.802704
	C60.835365,69.802704 60.916050,69.887802 61.129135,69.812531
	C61.518646,69.424919 61.695072,69.112579 61.871498,68.800232
	C61.871498,68.800232 61.886509,68.919083 62.207626,68.901695
	C63.313129,67.869484 64.097519,66.854660 64.881912,65.839836
	C64.881912,65.839836 64.954422,65.953049 65.239059,65.922768
	C66.003319,65.242851 66.482941,64.593216 66.962555,63.943581
	C66.962563,63.943581 66.999084,63.998917 67.343765,63.995937
	C68.963219,63.331291 70.367538,62.839664 71.493103,61.982155
	C85.747566,51.122383 101.571815,44.542591 119.674332,43.899696
	C120.796150,43.859852 121.875786,42.632309 122.975052,41.957359
	C122.975052,41.957363 123.002426,41.997692 123.337547,41.939415
	C123.560539,40.245850 123.448410,38.610561 123.336273,36.975269
	C123.271324,31.965549 123.206367,26.955832 123.639488,21.484367
	C125.761429,21.190132 127.655373,20.875423 128.962891,21.607742
	C134.959457,24.966311 140.800766,28.603271 146.679855,32.169914
	C149.820663,34.075336 152.925049,36.040840 156.045868,37.979225
	C156.956848,38.784565 157.867828,39.589905 158.921204,41.001804
	C159.530777,41.910294 159.997971,42.212219 160.465149,42.514149
	C160.331512,42.623882 160.197876,42.733616 160.229614,43.007935
	C160.906876,43.459629 161.418747,43.746746 161.930618,44.033867
	C161.930618,44.033867 161.999283,44.014671 161.989029,44.406296
	C164.332886,47.523109 166.686981,50.248295 169.041092,52.973476
	C169.041092,52.973476 168.997925,52.997452 169.000092,53.344955
	C169.811249,54.672909 170.620255,55.653358 171.429260,56.633812
	C171.429260,56.633812 171.109283,57.007694 171.165024,57.204975
	C173.451447,62.260502 175.682114,67.118752 177.912781,71.976997
	C177.756180,72.520630 177.599579,73.064255 176.875458,73.867378
	C175.982941,75.167397 175.657944,76.207924 175.332962,77.248451
	C175.332962,77.248451 175.375809,77.090324 175.012665,77.209366
	C172.847092,80.743698 171.044662,84.158981 169.242233,87.574265
	C169.242233,87.574265 169.218674,87.851112 168.754730,87.957138
	C166.254257,90.852631 164.217728,93.642097 162.181183,96.431549
	C161.892960,96.860680 161.604721,97.289818 160.723572,97.848785
	C159.407745,98.953613 158.684830,99.928596 157.961914,100.903580
	C157.770401,101.188278 157.578888,101.472977 156.784576,101.868713
	C154.478729,103.708748 152.775681,105.437744 151.072617,107.166748
	C150.996796,107.496246 150.809372,107.739891 149.914917,107.951935
	C148.213333,109.003777 147.107147,110.001366 146.000977,110.998947
	C146.000961,110.998947 146.000000,111.000000 145.631470,110.996155
	C143.252457,112.437813 141.241989,113.883324 139.231506,115.328835
	C139.231506,115.328835 139.142197,115.230652 138.926788,115.250076
	C138.471619,115.540070 138.231857,115.810646 137.992111,116.081223
	C137.992111,116.081223 137.828888,116.282959 137.297333,116.194138
	C134.893005,117.168785 133.020233,118.232269 131.147461,119.295746
	C131.147461,119.295746 131.108047,119.180305 130.746582,119.127426
	C128.770355,119.213089 127.155609,119.351639 124.988350,119.537598
	C124.988350,113.384842 124.961044,107.429398 125.000832,101.474396
	C125.018532,98.824501 124.303123,96.129196 121.223747,97.297318
	C117.376022,98.756912 113.956490,101.345284 110.352097,103.446335
	C110.352097,103.446335 109.976486,103.298805 109.813583,103.258766
	C108.821236,103.898026 107.991806,104.577324 107.162376,105.256630
	C107.162376,105.256630 107.042213,105.089500 106.748672,105.088234
	C105.068573,106.181000 103.682007,107.275032 102.295441,108.369064
	C102.295441,108.369064 102.227180,107.999214 101.927071,108.100250
	C101.094429,108.816582 100.561905,109.431877 100.029381,110.047173
	C100.029381,110.047173 100.002617,109.998344 99.653671,110.003387
	C92.530067,112.911049 87.318962,117.454521 84.303818,124.302544
	C84.303818,124.302544 84.196472,124.214661 83.950882,124.274719
	C83.470596,124.891701 83.235901,125.448624 83.001213,126.005554
	C82.976395,126.356682 82.820114,126.633606 81.945694,126.978561
	C80.968513,127.898514 80.577995,128.676254 80.187469,129.453979
	C79.957321,129.896729 79.727165,130.339462 78.908417,130.929199
	C77.211708,133.048630 76.103584,135.021088 74.995453,136.993530
	C74.995453,136.993530 75.000000,137.000000 74.651062,137.050842
	C72.945869,139.925400 71.589622,142.749100 70.233368,145.572815
	C70.164940,145.902435 70.096504,146.232071 69.610916,146.807953
	C69.084579,147.688889 68.975395,148.323578 68.866211,148.958267
	C68.917137,149.713974 68.968063,150.469666 68.825348,151.814240
	C69.720718,154.279724 70.809738,156.156342 71.898758,158.032959
z"
    />
    <path
      fill="#186853"
      opacity="1.000000"
      stroke="none"
      d="
M226.073059,126.618500
	C225.591248,126.067505 225.070374,125.172264 225.047562,124.264503
	C224.748871,112.382996 224.520615,100.499733 224.513855,88.328598
	C228.353409,100.660507 227.811234,113.444618 226.073059,126.618500
z"
    />
    <path
      fill="#186853"
      opacity="1.000000"
      stroke="none"
      d="
M211.359772,165.922821
	C211.995270,163.732300 212.836777,161.336243 213.875153,159.028778
	C216.270691,153.705536 218.761765,148.425308 221.567184,143.059967
	C218.498596,150.580704 215.076889,158.169113 211.359772,165.922821
z"
    />
    <path
      fill="#186853"
      opacity="1.000000"
      stroke="none"
      d="
M142.352875,212.552414
	C143.197556,211.873001 144.373001,211.079132 145.669800,210.669449
	C152.859085,208.398224 160.077896,206.220428 167.685608,204.036774
	C159.636322,206.913727 151.188004,209.764542 142.352875,212.552414
z"
    />
    <path
      fill="#186853"
      opacity="1.000000"
      stroke="none"
      d="
M114.410950,214.000092
	C119.474716,213.645386 124.900108,213.466034 130.634842,213.555206
	C125.553650,213.940964 120.163109,214.058212 114.410950,214.000092
z"
    />
    <path
      fill="#2E6D54"
      opacity="1.000000"
      stroke="none"
      d="
M186.890503,33.245380
	C183.541168,30.351984 180.191818,27.458588 176.648956,24.256281
	C176.714996,23.880335 176.974579,23.813295 177.849319,23.781401
	C178.464478,23.816544 178.932480,23.731937 178.932480,23.731937
	C181.598007,25.392328 184.263550,27.052721 186.998016,29.497051
	C187.008133,31.269119 186.949326,32.257252 186.890503,33.245380
z"
    />
    <path
      fill="#186853"
      opacity="1.000000"
      stroke="none"
      d="
M187.034821,33.537346
	C186.949326,32.257252 187.008133,31.269119 187.123901,29.785944
	C189.406464,31.236244 191.632065,33.181591 193.972321,35.439789
	C193.716400,36.948063 193.345810,38.143490 192.948105,39.719696
	C191.007034,38.010086 189.093079,35.919697 187.034821,33.537346
z"
    />
    <path
      fill="#186853"
      opacity="1.000000"
      stroke="none"
      d="
M95.588226,210.555511
	C100.458633,211.149292 105.617264,212.012131 110.904091,213.219086
	C105.980339,212.650330 100.928398,211.737442 95.588226,210.555511
z"
    />
    <path
      fill="#186853"
      opacity="1.000000"
      stroke="none"
      d="
M205.269791,47.327957
	C200.389664,46.743053 199.082199,42.380886 196.979645,38.402355
	C199.714020,41.024574 202.448242,44.012886 205.269791,47.327957
z"
    />
    <path
      fill="#186853"
      opacity="1.000000"
      stroke="none"
      d="
M58.018002,186.353668
	C61.266083,189.137711 64.601852,192.244858 67.942047,195.700607
	C67.781700,196.074326 67.616936,196.099411 67.120155,195.784988
	C65.929817,194.884521 65.071480,194.323578 64.213142,193.762650
	C64.213142,193.762650 64.190392,193.744385 64.207367,193.734192
	C62.184795,191.374924 60.145241,189.025848 58.018002,186.353668
z"
    />
    <path
      fill="#186853"
      opacity="1.000000"
      stroke="none"
      d="
M76.612717,202.516388
	C80.180756,203.963593 83.951843,205.710693 87.866653,207.777466
	C84.278839,206.336868 80.547302,204.576569 76.612717,202.516388
z"
    />
    <path
      fill="#186853"
      opacity="1.000000"
      stroke="none"
      d="
M216.852844,65.662262
	C215.264679,63.448982 213.669876,60.880013 212.129944,57.903725
	C213.738602,60.099792 215.292404,62.703178 216.852844,65.662262
z"
    />
    <path
      fill="#186853"
      opacity="1.000000"
      stroke="none"
      d="
M68.077072,196.369904
	C70.620216,197.803360 73.278809,199.549469 75.975388,201.644104
	C73.406433,200.222580 70.799484,198.452560 68.077072,196.369904
z"
    />
    <path
      fill="#186853"
      opacity="1.000000"
      stroke="none"
      d="
M198.486389,182.303864
	C200.026657,180.054245 201.884338,177.671936 204.092407,175.413361
	C202.563141,177.748459 200.683487,179.959839 198.486389,182.303864
z"
    />
    <path
      fill="#186853"
      opacity="1.000000"
      stroke="none"
      d="
M204.813507,175.237274
	C205.830704,173.307358 207.111450,171.200027 208.752777,169.083771
	C207.767929,171.069824 206.422501,173.064835 204.813507,175.237274
z"
    />
    <path
      fill="#186853"
      opacity="1.000000"
      stroke="none"
      d="
M190.338348,189.943680
	C191.573166,188.377960 193.133713,186.741196 195.067932,185.134979
	C193.849121,186.734573 192.256607,188.303604 190.338348,189.943680
z"
    />
    <path
      fill="#186853"
      opacity="1.000000"
      stroke="none"
      d="
M185.315887,193.900635
	C186.422638,192.626389 187.854065,191.306503 189.649872,189.999985
	C188.556351,191.293900 187.098465,192.574448 185.315887,193.900635
z"
    />
    <path
      fill="#186853"
      opacity="1.000000"
      stroke="none"
      d="
M223.138046,139.714966
	C223.236969,138.046921 223.560181,136.113174 224.187805,134.072388
	C224.115570,135.793320 223.738953,137.621292 223.138046,139.714966
z"
    />
    <path
      fill="#186853"
      opacity="1.000000"
      stroke="none"
      d="
M134.330246,213.674698
	C135.488068,213.182861 137.006210,212.797394 138.751068,212.668488
	C137.548706,213.210388 136.119629,213.495728 134.330246,213.674698
z"
    />
    <path
      fill="#186853"
      opacity="1.000000"
      stroke="none"
      d="
M223.492218,82.705002
	C222.737762,81.509949 222.060455,79.983986 221.619873,78.252090
	C222.427521,79.488808 222.998444,80.931450 223.492218,82.705002
z"
    />
    <path
      fill="#186853"
      opacity="1.000000"
      stroke="none"
      d="
M88.667999,208.476807
	C90.142540,208.582932 91.890060,208.954681 93.822021,209.670319
	C92.317963,209.590271 90.629471,209.166351 88.667999,208.476807
z"
    />
    <path
      fill="#186853"
      opacity="1.000000"
      stroke="none"
      d="
M168.373718,204.080902
	C169.142105,203.390396 170.245743,202.693665 171.705811,202.022522
	C170.944489,202.723648 169.826736,203.399170 168.373718,204.080902
z"
    />
    <path
      fill="#186853"
      opacity="1.000000"
      stroke="none"
      d="
M225.046021,131.701019
	C224.881073,131.018646 224.891342,130.072662 225.177322,129.044800
	C225.375778,129.787750 225.298508,130.612564 225.046021,131.701019
z"
    />
    <path
      fill="#D4E4A3"
      opacity="1.000000"
      stroke="none"
      d="
M64.129524,194.126801
	C65.071480,194.323578 65.929817,194.884521 66.871735,195.756897
	C65.985527,195.542526 65.015717,195.016739 64.129524,194.126801
z"
    />
    <path
      fill="#186853"
      opacity="1.000000"
      stroke="none"
      d="
M209.369019,168.970673
	C209.512787,168.113754 209.940918,167.116013 210.729156,166.086517
	C210.610657,166.979813 210.132019,167.904831 209.369019,168.970673
z"
    />
    <path
      fill="#186853"
      opacity="1.000000"
      stroke="none"
      d="
M175.531921,200.290939
	C176.043076,199.631775 176.867462,198.937546 178.039780,198.375214
	C177.540192,199.090027 176.692688,199.672958 175.531921,200.290939
z"
    />
    <path
      fill="#186853"
      opacity="1.000000"
      stroke="none"
      d="
M172.357208,202.046661
	C172.866974,201.413361 173.695282,200.766174 174.895157,200.202148
	C174.403076,200.867798 173.539413,201.450287 172.357208,202.046661
z"
    />
    <path
      fill="#186853"
      opacity="1.000000"
      stroke="none"
      d="
M182.341919,195.997467
	C182.622879,195.410995 183.229691,194.776459 184.212372,194.183350
	C183.948090,194.799652 183.307938,195.374542 182.341919,195.997467
z"
    />
    <path
      fill="#186853"
      opacity="1.000000"
      stroke="none"
      d="
M207.030640,49.672279
	C206.454437,49.433018 205.831146,48.894524 205.280609,48.006607
	C205.896759,48.229141 206.440155,48.801094 207.030640,49.672279
z"
    />
    <path
      fill="#186853"
      opacity="1.000000"
      stroke="none"
      d="
M209.928940,53.688461
	C209.355392,53.397564 208.742188,52.784180 208.167923,51.801773
	C208.767654,52.077152 209.328461,52.721565 209.928940,53.688461
z"
    />
    <path
      fill="#186853"
      opacity="1.000000"
      stroke="none"
      d="
M211.866089,56.694244
	C211.305954,56.395267 210.714554,55.775898 210.166504,54.792461
	C210.751511,55.076881 211.293152,55.725368 211.866089,56.694244
z"
    />
    <path
      fill="#186853"
      opacity="1.000000"
      stroke="none"
      d="
M218.741730,69.699219
	C218.215561,69.328781 217.708221,68.634422 217.312805,67.579819
	C217.870010,67.938148 218.315292,68.656715 218.741730,69.699219
z"
    />
    <path
      fill="#186853"
      opacity="1.000000"
      stroke="none"
      d="
M196.859955,37.732075
	C196.411423,37.793903 195.859192,37.567703 195.196823,37.052078
	C195.643219,36.989784 196.199722,37.216915 196.859955,37.732075
z"
    />
    <path
      fill="#2E6D54"
      opacity="1.000000"
      stroke="none"
      d="
M176.420227,23.792150
	C176.182953,23.612963 175.812683,23.178165 175.338623,22.469852
	C175.585632,22.643070 175.936417,23.089806 176.420227,23.792150
z"
    />
    <path
      fill="#D4E4A3"
      opacity="1.000000"
      stroke="none"
      d="
M178.948166,23.499146
	C178.932480,23.731937 178.464478,23.816544 178.247360,23.724041
	C178.194214,23.256992 178.358170,22.882450 178.522125,22.507908
	C178.669388,22.760723 178.816620,23.013538 178.948166,23.499146
z"
    />
    <path
      fill="#186853"
      opacity="1.000000"
      stroke="none"
      d="
M178.707062,198.428406
	C178.812027,198.032059 179.218567,197.566849 179.981995,197.120087
	C179.895447,197.545517 179.452026,197.952530 178.707062,198.428406
z"
    />
    <path
      fill="#186853"
      opacity="1.000000"
      stroke="none"
      d="
M221.162079,75.605255
	C220.865509,75.610733 220.568359,75.276855 220.299057,74.591599
	C220.605118,74.582123 220.883316,74.924019 221.162079,75.605255
z"
    />
    <path
      fill="#186853"
      opacity="1.000000"
      stroke="none"
      d="
M224.260895,85.657715
	C224.022980,85.683311 223.826492,85.449043 223.795074,85.106377
	C224.074203,85.131271 224.188248,85.264565 224.260895,85.657715
z"
    />
    <path
      fill="#186853"
      opacity="1.000000"
      stroke="none"
      d="
M122.544083,41.996563
	C121.875786,42.632309 120.796150,43.859852 119.674332,43.899696
	C101.571815,44.542591 85.747566,51.122383 71.493103,61.982155
	C70.367538,62.839664 68.963219,63.331291 67.317276,63.976013
	C79.994171,51.738865 95.574539,45.094715 113.202339,42.976906
	C116.167488,42.620670 119.142601,42.347385 122.544083,41.996563
z"
    />
    <path
      fill="#186853"
      opacity="1.000000"
      stroke="none"
      d="
M110.714035,103.384377
	C113.956490,101.345284 117.376022,98.756912 121.223747,97.297318
	C124.303123,96.129196 125.018532,98.824501 125.000832,101.474396
	C124.961044,107.429398 124.988350,113.384842 124.988350,119.537598
	C127.155609,119.351639 128.770355,119.213089 130.801025,119.154907
	C128.937378,120.333527 126.657799,121.431770 123.329285,123.035362
	C123.329285,114.318001 123.329285,106.797089 123.329285,98.172455
	C118.570244,100.172638 114.823105,101.747528 110.714035,103.384377
z"
    />
    <path
      fill="#186853"
      opacity="1.000000"
      stroke="none"
      d="
M155.910767,37.672329
	C152.925049,36.040840 149.820663,34.075336 146.679855,32.169914
	C140.800766,28.603271 134.959457,24.966311 128.962891,21.607742
	C127.655373,20.875423 125.761429,21.190132 123.743629,21.030991
	C122.520744,17.237736 124.713371,18.227932 126.673309,18.974142
	C137.613724,23.139507 147.367691,29.189297 155.910767,37.672329
z"
    />
    <path
      fill="#186853"
      opacity="1.000000"
      stroke="none"
      d="
M132.635498,177.579224
	C130.606720,178.376236 128.180969,179.054031 124.994095,179.944519
	C124.994095,185.132339 125.001137,190.736557 124.992340,196.340744
	C124.980972,203.578064 124.244606,204.095093 117.691940,201.341919
	C116.516624,200.848099 115.448685,200.098755 114.227615,199.114746
	C116.900185,199.640533 119.676308,200.518997 123.259567,201.652863
	C123.259567,193.455597 123.259567,185.948669 123.259567,178.212128
	C126.678078,177.925781 129.458298,177.692902 132.635498,177.579224
z"
    />
    <path
      fill="#186853"
      opacity="1.000000"
      stroke="none"
      d="
M48.372581,85.920151
	C47.461735,88.863129 46.059258,91.928108 45.124866,95.129692
	C42.934177,102.635880 40.981979,110.211472 38.892120,117.747559
	C38.631371,118.687813 38.122177,119.559181 37.465057,120.716049
	C38.026478,108.471237 41.701725,96.863396 48.372581,85.920151
z"
    />
    <path
      fill="#186853"
      opacity="1.000000"
      stroke="none"
      d="
M210.042023,92.439529
	C210.091797,96.703407 210.694382,101.506676 209.839676,106.034889
	C208.424484,113.532478 207.525238,121.352119 202.231842,127.863556
	C206.350586,116.697304 209.568771,105.101555 210.042023,92.439529
z"
    />
    <path
      fill="#186853"
      opacity="1.000000"
      stroke="none"
      d="
M203.028229,57.378555
	C205.184113,62.059052 208.105438,66.944565 209.340134,72.224495
	C210.629486,77.738220 210.295074,83.631660 210.407684,89.665039
	C207.780716,79.232956 205.416565,68.494934 203.028229,57.378555
z"
    />
    <path
      fill="#186853"
      opacity="1.000000"
      stroke="none"
      d="
M84.613480,124.189804
	C87.318962,117.454521 92.530067,112.911049 99.675522,110.020782
	C95.005272,114.714455 89.964211,119.395760 84.613480,124.189804
z"
    />
    <path
      fill="#186853"
      opacity="1.000000"
      stroke="none"
      d="
M36.954865,124.378403
	C37.715672,131.191696 38.330112,138.369705 38.884712,146.035736
	C38.152744,139.263550 37.480621,132.003326 36.954865,124.378403
z"
    />
    <path
      fill="#186853"
      opacity="1.000000"
      stroke="none"
      d="
M178.149353,71.731491
	C175.682114,67.118752 173.451447,62.260502 171.334839,57.026711
	C173.761230,61.596100 176.073563,66.541039 178.149353,71.731491
z"
    />
    <path
      fill="#186853"
      opacity="1.000000"
      stroke="none"
      d="
M199.693604,132.146164
	C199.401016,136.482849 198.329330,140.719849 193.369476,142.975769
	C195.151505,139.450943 197.282608,135.890915 199.693604,132.146164
z"
    />
    <path
      fill="#186853"
      opacity="1.000000"
      stroke="none"
      d="
M188.734863,148.201096
	C188.213486,152.819443 184.935806,155.313721 180.445557,157.028702
	C182.867691,154.133148 185.649597,151.232788 188.734863,148.201096
z"
    />
    <path
      fill="#186853"
      opacity="1.000000"
      stroke="none"
      d="
M162.490463,96.282715
	C164.217728,93.642097 166.254257,90.852631 168.637146,88.031120
	C166.922241,90.710670 164.860992,93.422272 162.490463,96.282715
z"
    />
    <path
      fill="#186853"
      opacity="1.000000"
      stroke="none"
      d="
M168.974777,52.638123
	C166.686981,50.248295 164.332886,47.523109 161.970184,44.436554
	C164.277206,46.817707 166.592834,49.560234 168.974777,52.638123
z"
    />
    <path
      fill="#186853"
      opacity="1.000000"
      stroke="none"
      d="
M100.331238,190.496948
	C97.693535,188.750534 94.876793,186.708298 92.037445,184.315247
	C94.727287,186.043335 97.439743,188.122223 100.331238,190.496948
z"
    />
    <path
      fill="#186853"
      opacity="1.000000"
      stroke="none"
      d="
M54.830185,76.426506
	C53.192371,79.404076 51.271866,82.538727 48.991734,85.700546
	C50.603905,82.679680 52.575695,79.631630 54.830185,76.426506
z"
    />
    <path
      fill="#186853"
      opacity="1.000000"
      stroke="none"
      d="
M169.532104,87.375069
	C171.044662,84.158981 172.847092,80.743698 175.011292,77.291306
	C173.522720,80.561424 171.672348,83.868652 169.532104,87.375069
z"
    />
    <path
      fill="#186853"
      opacity="1.000000"
      stroke="none"
      d="
M38.974754,148.395172
	C39.981487,150.874985 40.950085,153.751205 41.858322,157.084442
	C40.844177,154.624832 39.890396,151.708206 38.974754,148.395172
z"
    />
    <path
      fill="#186853"
      opacity="1.000000"
      stroke="none"
      d="
M70.510315,145.342072
	C71.589622,142.749100 72.945869,139.925400 74.647934,137.048889
	C73.591591,139.701172 72.189430,142.406250 70.510315,145.342072
z"
    />
    <path
      fill="#186853"
      opacity="1.000000"
      stroke="none"
      d="
M195.986954,44.371330
	C197.468048,46.205379 199.005981,48.381500 200.307510,50.766918
	C198.728668,48.888611 197.386230,46.801006 195.986954,44.371330
z"
    />
    <path
      fill="#186853"
      opacity="1.000000"
      stroke="none"
      d="
M151.391296,107.067841
	C152.775681,105.437744 154.478729,103.708748 156.543182,101.971024
	C155.173065,103.631180 153.441528,105.300049 151.391296,107.067841
z"
    />
    <path
      fill="#186853"
      opacity="1.000000"
      stroke="none"
      d="
M75.276657,136.823364
	C76.103584,135.021088 77.211708,133.048630 78.684189,131.047791
	C77.884979,132.897339 76.721413,134.775269 75.276657,136.823364
z"
    />
    <path
      fill="#186853"
      opacity="1.000000"
      stroke="none"
      d="
M165.615204,166.992004
	C163.747208,168.263153 161.531479,169.585831 158.903046,170.854767
	C160.749374,169.548523 163.008423,168.296021 165.615204,166.992004
z"
    />
    <path
      fill="#186853"
      opacity="1.000000"
      stroke="none"
      d="
M106.841919,194.708160
	C105.026993,193.925354 103.048210,192.857712 101.028641,191.434875
	C102.884583,192.194229 104.781319,193.308777 106.841919,194.708160
z"
    />
    <path
      fill="#186853"
      opacity="1.000000"
      stroke="none"
      d="
M50.017448,175.353821
	C51.313293,176.770416 52.656521,178.523071 53.981476,180.660538
	C52.663750,179.260193 51.364292,177.475037 50.017448,175.353821
z"
    />
    <path
      fill="#186853"
      opacity="1.000000"
      stroke="none"
      d="
M139.570389,115.266312
	C141.241989,113.883324 143.252457,112.437813 145.630737,110.996414
	C143.968781,112.401611 141.939026,113.802704 139.570389,115.266312
z"
    />
    <path
      fill="#186853"
      opacity="1.000000"
      stroke="none"
      d="
M142.597473,175.910782
	C140.862350,176.516846 138.724548,177.099014 136.313202,177.444519
	C138.091370,176.767548 140.143097,176.327225 142.597473,175.910782
z"
    />
    <path
      fill="#186853"
      opacity="1.000000"
      stroke="none"
      d="
M41.865059,158.365967
	C42.891357,160.186127 43.912441,162.382736 44.883247,164.992340
	C43.841927,163.184357 42.850883,160.963379 41.865059,158.365967
z"
    />
    <path
      fill="#186853"
      opacity="1.000000"
      stroke="none"
      d="
M131.517426,119.287773
	C133.020233,118.232269 134.893005,117.168785 137.169617,116.171288
	C135.678101,117.251442 133.782761,118.265625 131.517426,119.287773
z"
    />
    <path
      fill="#186853"
      opacity="1.000000"
      stroke="none"
      d="
M71.872124,157.689850
	C70.809738,156.156342 69.720718,154.279724 68.840889,152.193237
	C69.981880,153.771164 70.913681,155.558945 71.872124,157.689850
z"
    />
    <path
      fill="#186853"
      opacity="1.000000"
      stroke="none"
      d="
M123.199951,37.373817
	C123.448410,38.610561 123.560539,40.245850 123.315735,41.928707
	C122.993736,40.574970 123.028679,39.173668 123.199951,37.373817
z"
    />
    <path
      fill="#186853"
      opacity="1.000000"
      stroke="none"
      d="
M102.618195,108.300247
	C103.682007,107.275032 105.068573,106.181000 106.828110,105.153671
	C105.781044,106.224068 104.360992,107.227753 102.618195,108.300247
z"
    />
    <path
      fill="#186853"
      opacity="1.000000"
      stroke="none"
      d="
M149.629745,173.999893
	C148.424957,174.635742 146.846603,175.263977 144.826263,175.819489
	C146.008224,175.161942 147.632202,174.577118 149.629745,173.999893
z"
    />
    <path
      fill="#186853"
      opacity="1.000000"
      stroke="none"
      d="
M170.630157,163.995575
	C169.535370,164.954407 168.111740,165.973236 166.314758,166.974106
	C167.394730,165.989288 168.848022,165.022430 170.630157,163.995575
z"
    />
    <path
      fill="#186853"
      opacity="1.000000"
      stroke="none"
      d="
M85.031296,176.643982
	C84.065552,175.929504 83.012909,174.908463 81.941437,173.530457
	C82.929863,174.228149 83.937126,175.282776 85.031296,176.643982
z"
    />
    <path
      fill="#186853"
      opacity="1.000000"
      stroke="none"
      d="
M77.933990,167.667847
	C77.016396,166.820206 76.035118,165.653702 75.090042,164.104095
	C76.040924,164.930313 76.955612,166.139648 77.933990,167.667847
z"
    />
    <path
      fill="#186853"
      opacity="1.000000"
      stroke="none"
      d="
M146.318726,110.943420
	C147.107147,110.001366 148.213333,109.003777 149.686951,108.014954
	C148.915100,108.978439 147.775787,109.933159 146.318726,110.943420
z"
    />
    <path
      fill="#186853"
      opacity="1.000000"
      stroke="none"
      d="
M60.533554,69.907104
	C60.084717,70.840622 59.334064,71.878532 58.222843,72.927307
	C58.652096,71.962608 59.441921,70.987061 60.533554,69.907104
z"
    />
    <path
      fill="#186853"
      opacity="1.000000"
      stroke="none"
      d="
M64.571594,65.919510
	C64.097519,66.854660 63.313129,67.869484 62.164513,68.860107
	C62.620621,67.890343 63.440952,66.944763 64.571594,65.919510
z"
    />
    <path
      fill="#186853"
      opacity="1.000000"
      stroke="none"
      d="
M178.676788,158.034210
	C178.128174,158.926025 177.268036,159.878235 176.046539,160.748627
	C176.578537,159.809387 177.471924,158.951996 178.676788,158.034210
z"
    />
    <path
      fill="#186853"
      opacity="1.000000"
      stroke="none"
      d="
M46.711964,169.544632
	C47.430733,170.284073 48.188591,171.352524 48.895515,172.792389
	C48.146744,172.067078 47.448898,170.970367 46.711964,169.544632
z"
    />
    <path
      fill="#186853"
      opacity="1.000000"
      stroke="none"
      d="
M157.657562,171.000183
	C156.780136,171.635376 155.553146,172.281982 153.942078,172.872620
	C154.808014,172.214966 156.057999,171.613266 157.657562,171.000183
z"
    />
    <path
      fill="#186853"
      opacity="1.000000"
      stroke="none"
      d="
M171.364441,56.320633
	C170.620255,55.653358 169.811249,54.672909 169.016159,53.325935
	C169.786591,53.975426 170.543106,54.991444 171.364441,56.320633
z"
    />
    <path
      fill="#186853"
      opacity="1.000000"
      stroke="none"
      d="
M191.497025,144.881866
	C191.108917,145.822708 190.434494,146.884384 189.413147,147.990326
	C189.781036,147.023956 190.495880,146.013321 191.497025,144.881866
z"
    />
    <path
      fill="#186853"
      opacity="1.000000"
      stroke="none"
      d="
M158.250839,100.803787
	C158.684830,99.928596 159.407745,98.953613 160.483826,97.958832
	C160.071243,98.860687 159.305496,99.782333 158.250839,100.803787
z"
    />
    <path
      fill="#186853"
      opacity="1.000000"
      stroke="none"
      d="
M110.025696,196.524811
	C109.164894,196.431702 108.139275,196.069855 107.060150,195.350739
	C107.958046,195.414352 108.909462,195.835205 110.025696,196.524811
z"
    />
    <path
      fill="#186853"
      opacity="1.000000"
      stroke="none"
      d="
M201.101501,53.303719
	C201.694122,53.888676 202.302475,54.807152 202.854187,56.116230
	C202.237442,55.550297 201.677353,54.593769 201.101501,53.303719
z"
    />
    <path
      fill="#186853"
      opacity="1.000000"
      stroke="none"
      d="
M175.586273,77.061172
	C175.657944,76.207924 175.982941,75.167397 176.670044,74.069290
	C176.634628,74.965767 176.237106,75.919830 175.586273,77.061172
z"
    />
    <path
      fill="#186853"
      opacity="1.000000"
      stroke="none"
      d="
M80.053848,170.616669
	C79.413345,170.187378 78.706169,169.456635 78.005104,168.366623
	C78.669861,168.776642 79.328506,169.545929 80.053848,170.616669
z"
    />
    <path
      fill="#186853"
      opacity="1.000000"
      stroke="none"
      d="
M107.475967,105.206375
	C107.991806,104.577324 108.821236,103.898026 109.994583,103.348129
	C109.488846,104.037056 108.639206,104.596588 107.475967,105.206375
z"
    />
    <path
      fill="#186853"
      opacity="1.000000"
      stroke="none"
      d="
M113.979767,198.549286
	C112.913223,198.343521 111.646141,197.873108 110.251129,197.078156
	C111.341881,197.263947 112.560547,197.774277 113.979767,198.549286
z"
    />
    <path
      fill="#186853"
      opacity="1.000000"
      stroke="none"
      d="
M201.494873,128.644409
	C201.418304,129.569260 201.073318,130.709885 200.345093,131.914398
	C200.383392,130.938919 200.804932,129.899551 201.494873,128.644409
z"
    />
    <path
      fill="#186853"
      opacity="1.000000"
      stroke="none"
      d="
M69.081894,148.770355
	C68.975395,148.323578 69.084579,147.688889 69.458542,147.013611
	C69.581413,147.509491 69.439499,148.045975 69.081894,148.770355
z"
    />
    <path
      fill="#186853"
      opacity="1.000000"
      stroke="none"
      d="
M54.018517,181.342957
	C54.592491,181.601547 55.232441,182.169952 55.841492,183.106964
	C55.235229,182.867966 54.659855,182.260376 54.018517,181.342957
z"
    />
    <path
      fill="#186853"
      opacity="1.000000"
      stroke="none"
      d="
M66.664047,64.007515
	C66.482941,64.593216 66.003319,65.242851 65.182060,65.887146
	C65.348793,65.278351 65.857162,64.674904 66.664047,64.007515
z"
    />
    <path
      fill="#186853"
      opacity="1.000000"
      stroke="none"
      d="
M74.043983,161.674194
	C73.554955,161.437851 73.031227,160.908768 72.727959,160.199219
	C73.302032,160.472977 73.655663,160.927216 74.043983,161.674194
z"
    />
    <path
      fill="#186853"
      opacity="1.000000"
      stroke="none"
      d="
M193.000549,40.370773
	C193.549286,40.623421 194.210220,41.167393 194.843506,42.082306
	C194.248169,41.856197 193.680450,41.259151 193.000549,40.370773
z"
    />
    <path
      fill="#186853"
      opacity="1.000000"
      stroke="none"
      d="
M80.466049,129.297119
	C80.577995,128.676254 80.968513,127.898514 81.721207,127.077057
	C81.637131,127.735634 81.190880,128.437943 80.466049,129.297119
z"
    />
    <path
      fill="#186853"
      opacity="1.000000"
      stroke="none"
      d="
M100.322739,109.989670
	C100.561905,109.431877 101.094429,108.816582 101.972717,108.283539
	C101.751022,108.887917 101.183563,109.410042 100.322739,109.989670
z"
    />
    <path
      fill="#186853"
      opacity="1.000000"
      stroke="none"
      d="
M81.761398,172.787003
	C81.250626,172.555084 80.668060,172.041977 80.086929,171.207108
	C80.622108,171.425491 81.155853,171.965652 81.761398,172.787003
z"
    />
    <path
      fill="#186853"
      opacity="1.000000"
      stroke="none"
      d="
M56.729645,74.096893
	C56.606911,74.634453 56.223831,75.291206 55.541206,76.021484
	C55.650871,75.468704 56.060085,74.842400 56.729645,74.096893
z"
    />
    <path
      fill="#186853"
      opacity="1.000000"
      stroke="none"
      d="
M48.822365,173.394928
	C49.186092,173.530731 49.573593,173.967194 49.970085,174.717163
	C49.601433,174.585648 49.223789,174.140625 48.822365,173.394928
z"
    />
    <path
      fill="#186853"
      opacity="1.000000"
      stroke="none"
      d="
M45.688232,167.503174
	C46.056870,167.657089 46.453030,168.110382 46.781334,168.897949
	C46.380901,168.755661 46.048328,168.279099 45.688232,167.503174
z"
    />
    <path
      fill="#186853"
      opacity="1.000000"
      stroke="none"
      d="
M161.863464,43.763390
	C161.418747,43.746746 160.906876,43.459629 160.441223,42.857956
	C160.923737,42.859901 161.360031,43.176407 161.863464,43.763390
z"
    />
    <path
      fill="#186853"
      opacity="1.000000"
      stroke="none"
      d="
M172.558350,162.648056
	C172.441635,163.019516 172.026093,163.473557 171.274323,163.941162
	C171.378571,163.546707 171.819046,163.138672 172.558350,162.648056
z"
    />
    <path
      fill="#186853"
      opacity="1.000000"
      stroke="none"
      d="
M175.466370,160.692749
	C175.351944,161.071167 174.941467,161.505188 174.198578,161.940063
	C174.300873,161.543396 174.735596,161.145859 175.466370,160.692749
z"
    />
    <path
      fill="#186853"
      opacity="1.000000"
      stroke="none"
      d="
M160.382141,42.248619
	C159.997971,42.212219 159.530777,41.910294 159.041794,41.284573
	C159.446365,41.301548 159.872757,41.642319 160.382141,42.248619
z"
    />
    <path
      fill="#186853"
      opacity="1.000000"
      stroke="none"
      d="
M192.747986,143.156097
	C192.786911,143.574966 192.561005,144.128479 192.033234,144.769592
	C191.981979,144.335022 192.232559,143.812881 192.747986,143.156097
z"
    />
    <path
      fill="#186853"
      opacity="1.000000"
      stroke="none"
      d="
M83.266472,125.876640
	C83.235901,125.448624 83.470596,124.891701 84.020241,124.275749
	C84.067375,124.727066 83.799553,125.237396 83.266472,125.876640
z"
    />
    <path
      fill="#186853"
      opacity="1.000000"
      stroke="none"
      d="
M61.607956,68.879189
	C61.695072,69.112579 61.518646,69.424919 61.070824,69.806366
	C60.981094,69.569695 61.162754,69.263924 61.607956,68.879189
z"
    />
    <path
      fill="#186853"
      opacity="1.000000"
      stroke="none"
      d="
M45.076412,166.262726
	C45.301502,166.249268 45.530285,166.495041 45.748344,167.001785
	C45.518452,167.015823 45.299278,166.768890 45.076412,166.262726
z"
    />
    <path
      fill="#186853"
      opacity="1.000000"
      stroke="none"
      d="
M152.734589,172.960052
	C152.749710,173.142990 152.502533,173.317383 152.137589,173.354630
	C152.170654,173.128815 152.321472,173.040161 152.734589,172.960052
z"
    />
    <path
      fill="#186853"
      opacity="1.000000"
      stroke="none"
      d="
M138.270508,116.060036
	C138.231857,115.810646 138.471619,115.540070 138.992554,115.258942
	C139.032120,115.511871 138.790512,115.775360 138.270508,116.060036
z"
    />
  </svg>
</template>

<script>
export default {
  name: 'RecyclingIcon',
}
</script>

<style scoped lang="scss"></style>
