export { default as AdRenderer } from '../../components/AdRenderer/AdRenderer.vue'
export { default as BlankPage } from '../../components/BlankPage/BlankPage.vue'
export { default as ErrorRenderer } from '../../components/ErrorRenderer/ErrorRenderer.vue'
export { default as LinkSet } from '../../components/LinkSetup/LinkSet.vue'
export { default as LoadingScreen } from '../../components/LoadingScreen/LoadingScreen.vue'
export { default as Redirecting } from '../../components/Redirecting/Redirecting.vue'
export { default as SmartPage } from '../../components/SmartPage/SmartPage.vue'
export { default as ErrorRendererComponentsExpired } from '../../components/ErrorRenderer/components/Expired.vue'
export { default as ErrorRendererComponentsInactive } from '../../components/ErrorRenderer/components/Inactive.vue'
export { default as ErrorRendererComponentsLimitExceeded } from '../../components/ErrorRenderer/components/LimitExceeded.vue'
export { default as ErrorRendererComponentsNoContent } from '../../components/ErrorRenderer/components/NoContent.vue'
export { default as ErrorRendererComponentsNotFound } from '../../components/ErrorRenderer/components/NotFound.vue'
export { default as ErrorRendererComponents } from '../../components/ErrorRenderer/components/index.js'
export { default as SmartPageBeerSmartPage } from '../../components/SmartPage/BeerSmartPage/BeerSmartPage.vue'
export { default as SmartPageCannabisSmartPage } from '../../components/SmartPage/CannabisSmartPage/CannabisSmartPage.vue'
export { default as SmartPageCleaningSmartPage } from '../../components/SmartPage/CleaningSmartPage/CleaningSmartPage.vue'
export { default as SmartPageCosmeticSmartPage } from '../../components/SmartPage/CosmeticSmartPage/CosmeticSmartPage.vue'
export { default as SmartPageFoodSmartPage } from '../../components/SmartPage/FoodSmartPage/FoodSmartPage.vue'
export { default as SmartPageWineSmartPage } from '../../components/SmartPage/WineSmartPage/WineSmartPage.vue'
export { default as SmartPageBeerSmartPageTabsContentCompany } from '../../components/SmartPage/BeerSmartPage/TabsContent/Company.vue'
export { default as SmartPageBeerSmartPageTabsContentDO } from '../../components/SmartPage/BeerSmartPage/TabsContent/DO.vue'
export { default as SmartPageBeerSmartPageTabsContentIngredients } from '../../components/SmartPage/BeerSmartPage/TabsContent/Ingredients.vue'
export { default as SmartPageBeerSmartPageTabsContentNutrition } from '../../components/SmartPage/BeerSmartPage/TabsContent/Nutrition.vue'
export { default as SmartPageCannabisSmartPageTabsContentCompany } from '../../components/SmartPage/CannabisSmartPage/TabsContent/Company.vue'
export { default as SmartPageCannabisSmartPageTabsContentComposition } from '../../components/SmartPage/CannabisSmartPage/TabsContent/Composition.vue'
export { default as SmartPageCannabisSmartPageTabsContentDO } from '../../components/SmartPage/CannabisSmartPage/TabsContent/DO.vue'
export { default as SmartPageCannabisSmartPageTabsContentUseMode } from '../../components/SmartPage/CannabisSmartPage/TabsContent/UseMode.vue'
export { default as SmartPageCleaningSmartPageTabsContentCompany } from '../../components/SmartPage/CleaningSmartPage/TabsContent/Company.vue'
export { default as SmartPageCleaningSmartPageTabsContentIngredients } from '../../components/SmartPage/CleaningSmartPage/TabsContent/Ingredients.vue'
export { default as SmartPageCleaningSmartPageTabsContentInstructions } from '../../components/SmartPage/CleaningSmartPage/TabsContent/Instructions.vue'
export { default as SmartPageCleaningSmartPageTabsContentSustainability } from '../../components/SmartPage/CleaningSmartPage/TabsContent/Sustainability.vue'
export { default as SmartPageCosmeticSmartPageTabsContentCompany } from '../../components/SmartPage/CosmeticSmartPage/TabsContent/Company.vue'
export { default as SmartPageCosmeticSmartPageTabsContentIngredients } from '../../components/SmartPage/CosmeticSmartPage/TabsContent/Ingredients.vue'
export { default as SmartPageCosmeticSmartPageTabsContentInstructions } from '../../components/SmartPage/CosmeticSmartPage/TabsContent/Instructions.vue'
export { default as SmartPageCosmeticSmartPageTabsContentSustainability } from '../../components/SmartPage/CosmeticSmartPage/TabsContent/Sustainability.vue'
export { default as SmartPageFoodSmartPageTabsContentAllergens } from '../../components/SmartPage/FoodSmartPage/TabsContent/Allergens.vue'
export { default as SmartPageFoodSmartPageTabsContentCompany } from '../../components/SmartPage/FoodSmartPage/TabsContent/Company.vue'
export { default as SmartPageFoodSmartPageTabsContentIngredients } from '../../components/SmartPage/FoodSmartPage/TabsContent/Ingredients.vue'
export { default as SmartPageFoodSmartPageTabsContentNutrition } from '../../components/SmartPage/FoodSmartPage/TabsContent/Nutrition.vue'
export { default as SmartPageWineSmartPageTabsContentCompany } from '../../components/SmartPage/WineSmartPage/TabsContent/Company.vue'
export { default as SmartPageWineSmartPageTabsContentDO } from '../../components/SmartPage/WineSmartPage/TabsContent/DO.vue'
export { default as SmartPageWineSmartPageTabsContentIngredients } from '../../components/SmartPage/WineSmartPage/TabsContent/Ingredients.vue'
export { default as SmartPageWineSmartPageTabsContentNutrition } from '../../components/SmartPage/WineSmartPage/TabsContent/Nutrition.vue'
export { default as SmartPageComponentsIconsDOIcon } from '../../components/SmartPage/components/Icons/DOIcon.vue'
export { default as SmartPageComponentsIconsRecyclingIcon } from '../../components/SmartPage/components/Icons/RecyclingIcon.vue'
export { default as SmartPageComponentsIconsAlcoholWarningLabelIcon } from '../../components/SmartPage/components/Icons/alcoholWarningLabelIcon.vue'
export { default as SmartPageComponentsIconsAllergensIcon } from '../../components/SmartPage/components/Icons/allergensIcon.vue'
export { default as SmartPageComponentsIconsCompanyEmptyStateIcon } from '../../components/SmartPage/components/Icons/companyEmptyStateIcon.vue'
export { default as SmartPageComponentsIconsCompanyIcon } from '../../components/SmartPage/components/Icons/companyIcon.vue'
export { default as SmartPageComponentsIconsDLinkIcon } from '../../components/SmartPage/components/Icons/dLinkIcon.vue'
export { default as SmartPageComponentsIconsIngredientsIcon } from '../../components/SmartPage/components/Icons/ingredientsIcon.vue'
export { default as SmartPageComponentsIconsNotResultIcon } from '../../components/SmartPage/components/Icons/notResultIcon.vue'
export { default as SmartPageComponentsIconsNutritionIcon } from '../../components/SmartPage/components/Icons/nutritionIcon.vue'
export { default as SmartPageComponentsIconsPlus21 } from '../../components/SmartPage/components/Icons/plus21.vue'
export { default as SmartPageComponentsIconsProductThumbnailPlaceholder } from '../../components/SmartPage/components/Icons/productThumbnailPlaceholder.vue'
export { default as SmartPageComponentsIconsSustainabilityIcon } from '../../components/SmartPage/components/Icons/sustainabilityIcon.vue'
export { default as SmartPageComponentsIconsUserManualIcon } from '../../components/SmartPage/components/Icons/userManualIcon.vue'
export { default as SmartPageComponentsCommonModal } from '../../components/SmartPage/components/common/CommonModal.vue'
export { default as SmartPageComponentsCommonEmptyState } from '../../components/SmartPage/components/common/EmptyState.vue'
export { default as SmartPageComponentsCommonFeaturedNutrientItem } from '../../components/SmartPage/components/common/FeaturedNutrientItem.vue'
export { default as SmartPageComponentsCommonHeader } from '../../components/SmartPage/components/common/Header.vue'
export { default as SmartPageComponentsCommonNutrientsTable } from '../../components/SmartPage/components/common/NutrientsTable.vue'
export { default as SmartPageComponentsCommonQualifiersModal } from '../../components/SmartPage/components/common/QualifiersModal.vue'
export { default as SmartPageComponentsCommonTabsTabContent } from '../../components/SmartPage/components/common/Tabs/TabContent.vue'
export { default as SmartPageComponentsCommonTabsTabLinks } from '../../components/SmartPage/components/common/Tabs/TabLinks.vue'
export { default as SmartPageComponentsCommonTabs } from '../../components/SmartPage/components/common/Tabs/Tabs.vue'
export { default as SmartPageComponentsCommonSelectCustomSelect } from '../../components/SmartPage/components/common/select/CustomSelect.vue'

// nuxt/nuxt.js#8607
function wrapFunctional(options) {
  if (!options || !options.functional) {
    return options
  }

  const propKeys = Array.isArray(options.props) ? options.props : Object.keys(options.props || {})

  return {
    render(h) {
      const attrs = {}
      const props = {}

      for (const key in this.$attrs) {
        if (propKeys.includes(key)) {
          props[key] = this.$attrs[key]
        } else {
          attrs[key] = this.$attrs[key]
        }
      }

      return h(options, {
        on: this.$listeners,
        attrs,
        props,
        scopedSlots: this.$scopedSlots,
      }, this.$slots.default)
    }
  }
}
