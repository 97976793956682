var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "smart-page__tabs" },
    _vm._l(_vm.tabs, function (tab, key) {
      return _c(
        "button",
        {
          key: key,
          class: { "tab-links": true, active: _vm.activeKey == tab.key },
          on: { click: (event) => _vm.$emit("change", { tab, event }) },
        },
        [
          tab.icon
            ? _c(tab.icon, {
                tag: "component",
                staticClass: "icon",
                attrs: { filled: _vm.activeKey == tab.key },
              })
            : _vm._e(),
          _vm._v(" "),
          _c("label", { staticClass: "label" }, [
            _vm._v(_vm._s(_vm.$t(`${tab.name}.tab.label`))),
          ]),
        ],
        1
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }