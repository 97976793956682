var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.filled
    ? _c(
        "svg",
        {
          attrs: {
            xmlns: "http://www.w3.org/2000/svg",
            width: "35",
            height: "35",
            viewBox: "0 0 35 35",
            fill: "none",
          },
        },
        [
          _c("path", {
            attrs: {
              d: "M8.44578 29C7.76232 29 7.18382 28.7632 6.71029 28.2897C6.23676 27.8162 6 27.2377 6 26.5542V8.44578C6 7.76232 6.23676 7.18382 6.71029 6.71029C7.18382 6.23676 7.76232 6 8.44578 6H26.5542C27.2377 6 27.8162 6.23676 28.2897 6.71029C28.7632 7.18382 29 7.76232 29 8.44578V26.5542C29 27.2377 28.7632 27.8162 28.2897 28.2897C27.8162 28.7632 27.2377 29 26.5542 29H8.44578ZM17.8382 16.9017L20.8824 15.0803L23.9265 16.9017V8.02941H17.8382V16.9017Z",
              fill: "#615DF4",
            },
          }),
        ]
      )
    : _c(
        "svg",
        {
          attrs: {
            xmlns: "http://www.w3.org/2000/svg",
            width: "35",
            height: "35",
            viewBox: "0 0 35 35",
            fill: "none",
          },
        },
        [
          _c("path", {
            attrs: {
              d: "M8.44578 29C7.76232 29 7.18382 28.7632 6.71029 28.2897C6.23676 27.8162 6 27.2377 6 26.5542V8.44578C6 7.76232 6.23676 7.18382 6.71029 6.71029C7.18382 6.23676 7.76232 6 8.44578 6H26.5542C27.2377 6 27.8162 6.23676 28.2897 6.71029C28.7632 7.18382 29 7.76232 29 8.44578V26.5542C29 27.2377 28.7632 27.8162 28.2897 28.2897C27.8162 28.7632 27.2377 29 26.5542 29H8.44578ZM8.02941 8.44578V26.5542C8.02941 26.6584 8.07282 26.7538 8.15963 26.8404C8.24622 26.9272 8.3416 26.9706 8.44578 26.9706H26.5542C26.6584 26.9706 26.7538 26.9272 26.8404 26.8404C26.9272 26.7538 26.9706 26.6584 26.9706 26.5542V8.44578C26.9706 8.3416 26.9272 8.24622 26.8404 8.15963C26.7538 8.07282 26.6584 8.02941 26.5542 8.02941H23.9265V16.9017L20.8824 15.0803L17.8382 16.9017V8.02941H8.44578C8.3416 8.02941 8.24622 8.07282 8.15963 8.15963C8.07282 8.24622 8.02941 8.3416 8.02941 8.44578Z",
              fill: "#434263",
            },
          }),
        ]
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }