import ipInt from 'ip-to-int'
import Transformer from '@/transformers'
// import { QR_VARIANTS } from '~/enums/qrVariants'

export const state = () => ({
  design: null,
  uniqueVisit: false,
  language: 'en',
  smartpageInfo: null,
  loading: null,
})

export const getters = {
  getDesign(state) {
    return state.design
  },
  getSmartPage(state) {
    return state.smartpageInfo
  },
  isLoading(state) {
    return state.loading
  },
}

export const mutations = {
  setState: (state, payload) => {
    state = { ...state, ...payload }
  },
  setSmartPage(state, payload) {
    state.smartpageInfo = payload
  },
  setDesign(state, payload) {
    state.design = payload
  },
  changeLanguage(state, payload) {
    state.language = payload
  },
  setLoading(state, payload) {
    state.loading = payload
  },
}

export const actions = {
  nuxtServerInit({ state }, { route }) {
    const pathName = route.params.shortlink
    const cookieName = this.$config.uniqueVisitCookie.concat(`_${pathName}`)
    const cookie = this.$cookiz.get(cookieName)

    if (!cookie) {
      state.uniqueVisit = true

      const expires = new Date(Date.now() + 365000 * 24 * 60 * 60 * 1000)

      this.$cookiz.set(cookieName, true, { expires })
    }
  },
  async getIp() {
    return await this.$axios.$get(this.$config.ipApiUrl)
  },
  async getLandingFromShortlink({ state }, shortLink) {
    const url = this.$config.apiRenderUrl.concat(
      encodeURIComponent(encodeURIComponent(shortLink))
    )
    try {
      return await Transformer.fetch(
        await this.$axios.$get(url, {
          paramsSerializer(params) {
            const result = ''
            // Build the query string
            return result
          },
        })
      )
    } catch (error) {
      return Transformer.error(error.response)
    }
  },
  async setLanguage(context, { route, language }) {
    context.commit('setLoading', true)
    this.$i18n.setLocale(language)
    this.$axios.setHeader('Accept-Language', language)
    context.commit('changeLanguage', language)
    const path = route.fullPath || route.path
    await context.dispatch('fetchDigitalLink', path)
    context.commit('setLoading', false)
  },
  async fetchDigitalLink(context, shortLink) {
    try {
      const { smartpageInfo, design } = await context.dispatch(
        'getDigitalLink',
        shortLink
      )
      context.commit('setSmartPage', smartpageInfo)
      context.commit('setDesign', design)
    } catch (error) {}
  },
  async getCountryClient() {
    const ip = await this.getIp()
    const url = this.$config.statsPinpointUrl.replace('{ip}', ipInt(ip).toInt())

    try {
      const { country } = await this.$axios.$get(url)
      return country || null
    } catch (error) {
      return null
    }
  },
  async getDigitalLink(state, shortLink) {
    try {
      shortLink = shortLink.replace(/^\//, '')
      const url = this.$config.apiRenderUrl.concat(
        encodeURIComponent(encodeURIComponent(shortLink))
      )
      return await Transformer.fetch(await this.$axios.$get(url))
    } catch (error) {
      return Transformer.error(error.response)
    }
  },
}
