var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "featured-nutrient" }, [
    _c("p", { staticClass: "featured-nutrient__value" }, [
      _vm._v("\n    " + _vm._s(_vm.nutrient.quantity) + "\n    "),
      _c("span", { staticClass: "unit" }, [_vm._v(_vm._s(_vm.nutrient.unit))]),
    ]),
    _vm._v(" "),
    _c("p", { staticClass: "featured-nutrient__label" }, [
      _vm.nutrient.name
        ? _c("span", [_vm._v(_vm._s(_vm.nutrient.name))])
        : _vm._e(),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }