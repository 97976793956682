var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    {
      attrs: {
        width: "19",
        height: "23",
        viewBox: "0 0 19 23",
        fill: "none",
        xmlns: "http://www.w3.org/2000/svg",
      },
    },
    [
      _c("rect", {
        attrs: {
          x: "2.83887",
          y: "0.94046",
          width: "2.94888",
          height: "16.1971",
          rx: "0.303133",
          fill: "url(#paint0_linear_341_5848)",
        },
      }),
      _vm._v(" "),
      _c("rect", {
        attrs: {
          x: "13.3238",
          y: "11.3836",
          width: "2.94888",
          height: "11.5568",
          rx: "0.303133",
          fill: "url(#paint1_linear_341_5848)",
        },
      }),
      _vm._v(" "),
      _c("path", {
        attrs: {
          d: "M8.0813 4.74564C8.0813 4.57822 8.21702 4.4425 8.38443 4.4425H10.727C10.8945 4.4425 11.0302 4.57822 11.0302 4.74564V20.3802C11.0302 20.5476 10.8945 20.6833 10.727 20.6833H8.38443C8.21702 20.6833 8.0813 20.5476 8.0813 20.3802V4.74564Z",
          fill: "url(#paint2_linear_341_5848)",
        },
      }),
      _vm._v(" "),
      _c("rect", {
        attrs: {
          x: "13.3238",
          y: "6.67511",
          width: "2.94888",
          height: "3.54584",
          rx: "0.303133",
          fill: "url(#paint3_linear_341_5848)",
        },
      }),
      _vm._v(" "),
      _c(
        "defs",
        [
          _c(
            "linearGradient",
            {
              attrs: {
                id: "paint0_linear_341_5848",
                x1: "4.31331",
                y1: "0.94046",
                x2: "4.31331",
                y2: "17.1375",
                gradientUnits: "userSpaceOnUse",
              },
            },
            [_c("stop", { attrs: { "stop-color": "#615DF4" } })],
            1
          ),
          _vm._v(" "),
          _c(
            "linearGradient",
            {
              attrs: {
                id: "paint1_linear_341_5848",
                x1: "14.7982",
                y1: "11.3836",
                x2: "14.7982",
                y2: "22.9405",
                gradientUnits: "userSpaceOnUse",
              },
            },
            [_c("stop", { attrs: { "stop-color": "#615DF4" } })],
            1
          ),
          _vm._v(" "),
          _c(
            "linearGradient",
            {
              attrs: {
                id: "paint2_linear_341_5848",
                x1: "9.55574",
                y1: "0.91592",
                x2: "9.55574",
                y2: "17.1568",
                gradientUnits: "userSpaceOnUse",
              },
            },
            [_c("stop", { attrs: { "stop-color": "#615DF4" } })],
            1
          ),
          _vm._v(" "),
          _c(
            "linearGradient",
            {
              attrs: {
                id: "paint3_linear_341_5848",
                x1: "14.7982",
                y1: "6.67511",
                x2: "14.7982",
                y2: "10.221",
                gradientUnits: "userSpaceOnUse",
              },
            },
            [_c("stop", { attrs: { "stop-color": "#5E5AEF" } })],
            1
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }