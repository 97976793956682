<template>
  <div class="nutrition-tab">
    <div class="header">
      <div class="product-info">
        <p class="product-info__description" v-html="description" />
        <p v-if="netContent" class="product-info__net-content">
          {{ $t('component.label.net.weight') }} {{ netContent }}
        </p>
        <p v-if="expiration" class="product-info__expiration">
          <span>{{ $t('common.text.exp') }}</span>
          <span v-html="expiration" />
        </p>
        <div v-if="isVariant">
          <button class="variants-btn" @click="showQualifiersContent = true">
            {{ $t('dashboard.key.qualifiers.label') }}
          </button>
          <QualifiersModal v-model="showQualifiersContent" :source="source" />
        </div>
        <AlcoholWarningLabelIcon />
      </div>
      <div class="image-wrapper" :class="{ placeholder: !source?.thumbnail }">
        <img v-if="source?.thumbnail" :src="source?.thumbnail" />
        <ProductThumbnailPlaceholder v-else />
      </div>
    </div>
    <div class="content">
      <p class="title">{{ $t('composition.page.terpenes.label') }}</p>
      <div class="featured-nutrients">
        <FeaturedNutrientItem
          v-for="(nutrient, key) in featuredNutrients"
          :key="key"
          :nutrient="nutrient"
        />
      </div>
      <div class="nutrients-table">
        <NutrientsTable :nutrient-info="source?.nutrientInfo" />
      </div>
    </div>
  </div>
</template>
<script>
import ProductThumbnailPlaceholder from '../../components/Icons/productThumbnailPlaceholder.vue'
import FeaturedNutrientItem from '../../components/common/FeaturedNutrientItem.vue'
import NutrientsTable from '../../components/common/NutrientsTable.vue'
import AlcoholWarningLabelIcon from '../../components/Icons/plus21.vue'
import QualifiersModal from '~/components/SmartPage/components/common/QualifiersModal.vue'

export default {
  name: 'NutritionTab',
  components: {
    QualifiersModal,
    ProductThumbnailPlaceholder,
    FeaturedNutrientItem,
    NutrientsTable,
    AlcoholWarningLabelIcon,
  },
  props: {
    source: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      description: this.source?.basicInfo?.description,
      featuredNutrients: this.source?.featuredNutrients,
      showQualifiersContent: false,
      expiration: this.source?.additionalInfo?.expiration
        ? new Date(this.source?.additionalInfo?.expiration).toLocaleDateString()
        : null,
    }
  },
  computed: {
    isVariant() {
      return this.source?.isVariant
    },
    netContent() {
      return this.source?.netContent?.quantity?.concat(
        this.source?.netContent?.unit
      )
    },
  },
}
</script>
<style lang="scss" scoped>
@import '~/assets/scss/tokens.scss';

.nutrition-tab {
  display: grid;
  grid-template-rows: 12rem 1fr;
  row-gap: 1rem;
  height: 100%;

  .header {
    display: grid;
    grid-template-columns: 60% 1fr;
    column-gap: 1.25rem;
    max-height: 12rem;

    .product-info {
      display: grid;
      grid-template-rows: repeat(3, auto);
      align-content: flex-start;
      row-gap: 0.5rem;

      p {
        margin: 0;
      }

      &__description {
        color: $DL-black;
        @include font-16-400;
        line-height: 23px;
      }

      &__expiration,
      &__net-content {
        @include font-12-500;
        color: $DL-gray-2;
      }

      &__variants {
        display: flex;
        cursor: pointer;
        font-family: Inter, serif;
        height: 32px;
        padding: 8px;
        align-items: flex-start;
        gap: 10px;
        flex-shrink: 0;
        color: #615df4;
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: 131.023%;
        background: transparent;
        border: none;
        transition: all 0.2s ease-in-out;

        &:hover {
          border-radius: 8px;
          background: rgba(97, 93, 244, 0.16);
        }
      }
    }

    .image-wrapper {
      width: inherit;
      height: inherit;
      max-width: inherit;
      min-height: inherit;
      max-height: 9rem;
      display: flex;
      justify-content: center;
      align-items: center;
      overflow: hidden;

      &.placeholder {
        display: flex;
        justify-content: flex-start;
        flex-direction: column;
      }

      img {
        max-width: 100%;
        max-height: 100%;
        width: auto;
        height: 100%;
        object-fit: contain;
      }
    }
  }

  .content {
    display: grid;
    grid-template-rows: 2rem auto 1fr;
    row-gap: 1rem;

    .title {
      color: $DL-black;
      @include font-24-700;
      margin: 0;
    }

    .featured-nutrients {
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      height: fit-content;

      div {
        border-right: 2px solid $DL-gray-1;
        padding: 0.25rem;

        &:last-of-type {
          border: 0;
        }
      }
    }
  }
}
</style>
