var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "nutrition-tab" }, [
    _c("div", { staticClass: "header" }, [
      _c("div", { staticClass: "product-info" }, [
        _c("p", { staticClass: "product-info__name" }, [
          _vm._v(_vm._s(_vm.description)),
        ]),
        _vm._v(" "),
        _vm.netContent
          ? _c("p", { staticClass: "product-info__net-content" }, [
              _vm._v(
                "\n        " +
                  _vm._s(_vm.$t("component.label.net.weight")) +
                  " " +
                  _vm._s(_vm.netContent) +
                  "\n      "
              ),
            ])
          : _vm._e(),
        _vm._v(" "),
        _vm.drainedWeight
          ? _c("p", { staticClass: "product-info__net-content" }, [
              _vm._v(
                "\n        " +
                  _vm._s(_vm.$t("component.label.drained.weight")) +
                  " " +
                  _vm._s(_vm.drainedWeight) +
                  "\n      "
              ),
            ])
          : _vm._e(),
        _vm._v(" "),
        _c("p", { staticClass: "product-info__ean" }, [
          _vm._v(
            "\n        " +
              _vm._s(_vm.$t("component.label.gtin")) +
              " " +
              _vm._s(_vm.ean) +
              "\n      "
          ),
        ]),
        _vm._v(" "),
        _vm.isVariant
          ? _c(
              "div",
              [
                _c(
                  "button",
                  {
                    staticClass: "variants-btn",
                    on: {
                      click: function ($event) {
                        _vm.showQualifiersContent = true
                      },
                    },
                  },
                  [
                    _vm._v(
                      "\n          " +
                        _vm._s(_vm.$t("dashboard.key.qualifiers.label")) +
                        "\n        "
                    ),
                  ]
                ),
                _vm._v(" "),
                _c("QualifiersModal", {
                  attrs: { source: _vm.source },
                  model: {
                    value: _vm.showQualifiersContent,
                    callback: function ($$v) {
                      _vm.showQualifiersContent = $$v
                    },
                    expression: "showQualifiersContent",
                  },
                }),
              ],
              1
            )
          : _vm._e(),
      ]),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "image-wrapper",
          class: { placeholder: !_vm.source?.thumbnail },
        },
        [
          _vm.source?.thumbnail
            ? _c("img", { attrs: { src: _vm.source?.thumbnail } })
            : _c("ProductThumbnailPlaceholder"),
        ],
        1
      ),
    ]),
    _vm._v(" "),
    _vm.featuredNutrients.length
      ? _c(
          "div",
          { staticClass: "featured-nutrients" },
          _vm._l(_vm.featuredNutrients, function (nutrient, key) {
            return _c("FeaturedNutrientItem", {
              key: key,
              attrs: { nutrient: nutrient },
            })
          }),
          1
        )
      : _vm._e(),
    _vm._v(" "),
    _c("div", { staticClass: "content" }, [
      _c("p", { staticClass: "title" }, [
        _vm._v(_vm._s(_vm.$t("nutrition.page.title"))),
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "nutrients-table" },
        [
          _c("NutrientsTable", {
            attrs: { "nutrient-info": _vm.source?.nutrientInfo },
          }),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }