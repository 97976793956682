var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "ingredients-tab" }, [
    _c("div", { staticClass: "header" }, [
      _c("div", { staticClass: "product-info" }, [
        _c("p", { staticClass: "product-info__name" }, [
          _vm._v(_vm._s(_vm.name)),
        ]),
        _vm._v(" "),
        _c("p", { staticClass: "product-info__net-content" }, [
          _vm._v(_vm._s(_vm.netContent)),
        ]),
        _vm._v(" "),
        _c("p", { staticClass: "product-info__ean" }, [
          _vm._v(_vm._s(_vm.ean)),
        ]),
        _vm._v(" "),
        _vm.isVariant
          ? _c(
              "div",
              [
                _c(
                  "button",
                  {
                    staticClass: "variants-btn",
                    on: {
                      click: function ($event) {
                        _vm.showQualifiersContent = true
                      },
                    },
                  },
                  [
                    _vm._v(
                      "\n          " +
                        _vm._s(_vm.$t("dashboard.key.qualifiers.label")) +
                        "\n        "
                    ),
                  ]
                ),
                _vm._v(" "),
                _c("QualifiersModal", {
                  attrs: { source: _vm.source },
                  model: {
                    value: _vm.showQualifiersContent,
                    callback: function ($$v) {
                      _vm.showQualifiersContent = $$v
                    },
                    expression: "showQualifiersContent",
                  },
                }),
              ],
              1
            )
          : _vm._e(),
      ]),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "image-wrapper",
          class: { placeholder: !_vm.hasThumbnail },
        },
        [
          _vm.hasThumbnail
            ? _c("img", {
                attrs: { src: _vm.source?.thumbnail, alt: "product image" },
              })
            : _c("product-thumbnail-placeholder"),
        ],
        1
      ),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "content" }, [
      _c("div", { staticClass: "container" }, [
        _c("p", { staticClass: "title" }, [
          _vm._v(_vm._s(_vm.$t("ingredients.page.title"))),
        ]),
        _vm._v(" "),
        _c("p", { staticClass: "content" }, [_vm._v(_vm._s(_vm.ingredients))]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "container" }, [
        _c("p", { staticClass: "title" }, [
          _vm._v(_vm._s(_vm.$t("ingredients.page.storage.title"))),
        ]),
        _vm._v(" "),
        _c("p", { staticClass: "content" }, [_vm._v(_vm._s(_vm.storage))]),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }