<template>
  <div class="featured-nutrient">
    <p class="featured-nutrient__value">
      {{ nutrient.quantity }}
      <span class="unit">{{ nutrient.unit }}</span>
    </p>
    <p class="featured-nutrient__label">
      <span v-if="nutrient.name">{{ nutrient.name }}</span>
    </p>
  </div>
</template>
<script>
export default {
  name: 'FeaturedNutrientItem',
  props: {
    nutrient: {
      type: Object,
      default: () => {},
    },
  },
}
</script>
<style lang="scss" scoped>
@import '~/assets/scss/tokens.scss';

.featured-nutrient {
  display: flex;
  flex-direction: column;
  gap: 0.25rem;

  p {
    text-align: center;
    color: $DL-black;
    margin: 0;

    &:nth-of-type(2) {
      @include capitalize-first-letter;
    }
  }

  &__value {
    display: flex;
    justify-content: center;
    @include font-20-600;

    .unit {
      @include font-12-600;
      display: flex;
      align-items: end;
    }
  }

  &__label {
    @include font-12-400;
    font-size: 12px;
    text-align: center;
    display: inline-block;
    max-width: 70px;
    overflow-wrap: break-word;
    white-space: normal;
    hyphens: auto;
    line-height: 10px;
  }
}
</style>
