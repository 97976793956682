var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm._m(0)
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "content" }, [
      _c("div", { staticClass: "content-body" }, [
        _c("img", {
          staticClass: "logo",
          attrs: {
            src: require("assets/images/logo.svg"),
            alt: "digitallinkLogo",
          },
        }),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass:
              "mt-3 w-100 d-flex flex-column align-items-center h-75",
          },
          [
            _c("p", { staticClass: "text-center fw-bolder fs-1 mt-5" }, [
              _vm._v("\n        This space is for something amazing.\n      "),
            ]),
            _vm._v(" "),
            _c("p", { staticClass: "fw-400 text-center fs-5" }, [
              _vm._v(
                "\n        Is this your QR? Log in to your digital-link.com account and finish its setup!.\n      "
              ),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "astronaut-image" }, [
              _c("img", {
                attrs: { src: require("assets/images/astronaut.gif") },
              }),
            ]),
          ]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "d-flex flex-column powered-image align-items-center",
          },
          [
            _c("button", { staticClass: "button mb-3 black w-100" }, [
              _vm._v("Log in now"),
            ]),
            _vm._v(" "),
            _c("img", {
              staticClass: "pt-4",
              attrs: {
                src: require("assets/images/powered-by-digitallink.svg"),
              },
            }),
          ]
        ),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }