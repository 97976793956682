import { render, staticRenderFns } from "./WineSmartPage.vue?vue&type=template&id=c64218a6"
import script from "./WineSmartPage.vue?vue&type=script&lang=js"
export * from "./WineSmartPage.vue?vue&type=script&lang=js"
import style0 from "./WineSmartPage.vue?vue&type=style&index=0&id=c64218a6&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/@nuxt/webpack/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/app/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('c64218a6')) {
      api.createRecord('c64218a6', component.options)
    } else {
      api.reload('c64218a6', component.options)
    }
    module.hot.accept("./WineSmartPage.vue?vue&type=template&id=c64218a6", function () {
      api.rerender('c64218a6', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "components/SmartPage/WineSmartPage/WineSmartPage.vue"
export default component.exports