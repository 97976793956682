<template>
  <div
    v-if="
      source.allergens.mayContain.length > 0 ||
      source.allergens.contains.length > 0
    "
    class="allergens-tab"
  >
    <p class="title">{{ $t('allergens.page.title') }}</p>
    <div class="body">
      <p>{{ textFormatter(source.allergens.contains) }}</p>
      <p v-if="source.allergens.mayContain.length > 0">
        {{ $t('allergens.page.traces.label') }}
        {{ textFormatter(source.allergens.mayContain) }}
      </p>
    </div>
  </div>
  <EmptyState v-else type="allergens" :source="source" />
</template>
<script>
import EmptyState from '../../components/common/EmptyState.vue'
export default {
  name: 'AllergensTab',
  components: { EmptyState },
  props: {
    source: {
      type: Object,
      default: () => {},
    },
  },
  methods: {
    textFormatter(text) {
      return text.join(', ').replace(/, ([^,]*)$/, ` ${this.$i18n.t('common.text.and')} $1.`)
    },
  },
}
</script>
<style lang="scss" scoped>
@import '~/assets/scss/tokens.scss';
.allergens-tab {
  display: grid;
  grid-row-gap: 16px;
  grid-template-rows: auto 1fr;
  flex-direction: column;
  height: 100%;
  .title {
    color: $DL-black;
    @include font-24-700;
    margin: 0;
  }

  .body {
    p {
      color: $DL-black;
      @include font-16-400;
      line-height: 23px;
      margin: 0;
    }
  }
}
</style>
