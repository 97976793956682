<template>
  <div class="smart-page__header">
    <DLinkIconVue />
    <p class="title">{{ title }}</p>
    <CustomSelect
      v-if="languageSelected"
      :options="languages"
      :default="languageSelected"
      class="select"
      @input="handleLanguageChange"
    />
  </div>
</template>
<script>
import DLinkIconVue from '../Icons/dLinkIcon.vue'
import CustomSelect from './select/CustomSelect.vue'
export default {
  name: 'SmartPageHeaderComponent',
  components: {
    DLinkIconVue,
    CustomSelect,
  },
  props: {
    title: {
      type: String,
      required: true,
      default: () => '',
    },
  },

  data() {
    return {
      items: ['ingredients', 'nutrition', 'allergens', 'company'],
      languages: [
        { id: 'en', name: 'English' },
        { id: 'es', name: 'Español' },
        { id: 'fr', name: 'Français' },
        { id: 'ca', name: 'Català' },
        { id: 'de', name: 'Deutsch' },
        { id: 'it', name: 'Italiano' },
        { id: 'pt', name: 'Português' },
        { id: 'zh', name: '中文' },
        { id: 'ja', name: '日本語' },
        { id: 'ko', name: '한국어' },
        { id: 'ru', name: 'Rus' },
        { id: 'ar', name: 'العربية' },
        { id: 'el', name: 'Ελληνικά' },
        { id: 'nl', name: 'Nederlands' },
        { id: 'pl', name: 'Polski' },
      ],
      languageSelected: '',
    }
  },
  mounted() {
    this.languageSelected = navigator.language.split('-')[0]
  },
  beforeMount() {},
  methods: {
    handleLanguageChange(language) {
      this.$store.dispatch('setLanguage', {
        route: this.$route,
        language: language.id,
      })
    },
  },
}
</script>
<style lang="scss" scoped>
@import '~/assets/scss/tokens.scss';
.smart-page {
  &__header {
    height: auto;
    border-bottom: 1px solid #f5f5f5;
    padding: 0 12px;
    display: grid;
    grid-template-columns: 1rem auto 4rem;
    align-items: center;
    -moz-column-gap: 10px;
    column-gap: 10px;

    .title {
      color: $DL-black;
      @include font-20-600;
      margin: 0;
      text-overflow: ellipsis;
      max-width: 100%;
      white-space: nowrap;
      overflow: hidden;
    }
  }
}
</style>
