var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "empty-state-wrapper" }, [
    _c(
      "div",
      { staticClass: "empty-state-content" },
      [
        _vm.emptyStateData.icon
          ? _c(_vm.emptyStateData.icon, {
              tag: "component",
              staticClass: "icon",
            })
          : _vm._e(),
        _vm._v(" "),
        _c("p", { staticClass: "title" }, [
          _vm._v(_vm._s(_vm.$t(`${_vm.emptyStateData.name}.empty.headline`))),
        ]),
        _vm._v(" "),
        _c("p", { staticClass: "body" }, [
          _vm._v(_vm._s(_vm.$t(`${_vm.emptyStateData.name}.empty.body`))),
        ]),
        _vm._v(" "),
        _c("a", { attrs: { href: _vm.emptyStateData.href } }, [
          _vm._v(
            "\n      " +
              _vm._s(_vm.$t(`${_vm.emptyStateData.name}.empty.cta`)) +
              "\n    "
          ),
        ]),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }