export default function ({ $axios, $config, store, req }) {
  $axios.onRequest((config) => {
    $axios.setHeader('Accept', 'application/json')

    const uniqueVisitValue = store.state.uniqueVisit ? '1' : '0'
    $axios.setHeader('X-Unique-Visit', uniqueVisitValue)

    const referer = process.client ? document.referrer : req.headers.referer
    $axios.setHeader('Referer', referer)

    let origin

    if (process.client) {
      origin = window.location.origin
    } else {
      origin = `${req.protocol}://${req.headers.host}`
    }
    $axios.setHeader('Origin', origin)

    return config
  })

  // $axios.onResponse(response => {
  //   console.log('Respuesta recibida', response)
  //   return response
  // })

  // $axios.onError(error => {
  //   const code = parseInt(error.response && error.response.status)
  //   if (code === 400) {
  //     redirect('/400')
  //   }
  //   return Promise.reject(error)
  // })
}
