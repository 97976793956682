/**
 * Convierte una fecha entre dos formatos específicos.
 *
 * @param {string} date - La fecha a convertir. está en formato YYMMDD
 * @returns {string} La fecha convertida en el formato dd/mm/yyyy o "" si la entrada es inválida.
 *
 * @example
 * // Convierte de YYMMDD a dd/mm/yyyy
 * console.log(convertDate("221024", DateFormat.DDMMYYYY)); // "24/10/2022"
 */
export const convertDate = (date) => {
  if (/^\d{6}$/.test(date)) {
    let year = date.slice(0, 2)
    const month = date.slice(2, 4)
    const day = date.slice(4, 6)
    year = year >= 70 ? `19${year}` : `20${year}`
    return `${day}/${month}/${year}`
  }
  return ''
}
