<template>
  <div class="content">
    <div class="content-body">
      <img src="~/assets/images/logo.svg" class="logo" alt="digitallinkLogo" />
      <div class="w-100 position-relative d-flex justify-content-center h-50">
        <div class="mt-5 fw-500 d-flex flex-column align-items-center">
          <p class="text-center fs-2 px-2">
            oio iioioioioioio oiooiioiioi ioioio ioioio io ioi
          </p>
        </div>

        <img class="dog-image" src="~/assets/images/dog.svg" />
      </div>
      <div class="d-flex flex-column powered-image align-items-center">
        <img class="pt-4" src="~/assets/images/powered-by-digitallink.svg" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AdRendererComponent',
}
</script>

<style scoped>
.content {
  background: url('~@/assets/images/bg-yellow.svg');
  height: 100vh;
  display: flex;
  justify-content: center;
  background-position: bottom;
  background-color: #fadb83;
  background-repeat: repeat no-repeat;
  background-size: cover;
}
.content-body {
  max-width: 900px;
  width: 100%;
  padding-top: 5rem;
  padding-bottom: 3rem;
  position: relative;
  display: flex;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.content-body .logo {
  width: 10rem;
}

.dog-image {
  width: 21rem;
  position: absolute;
  top: 50%;
}

.powered-image {
  position: absolute;
  bottom: 5%;
}

.text-center {
  text-align: center;
}

img {
  max-width: 100% !important;
}
</style>
