var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { class: ["company-tab-wrapper", { "custom-landing": _vm.landing }] },
    [
      _vm.callToActions.length
        ? _c("div", { staticClass: "company-tab" }, [
            _c("p", { staticClass: "title" }, [
              _vm._v(_vm._s(_vm.$t("company.page.title"))),
            ]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "body" },
              _vm._l(_vm.callToActions, function (item, key) {
                return _c(
                  "a",
                  { key: key, attrs: { target: "_blank", href: item.href } },
                  [
                    item.icon
                      ? _c("span", { staticClass: "icon" }, [
                          _vm._v(_vm._s(item.icon)),
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _c("span", { staticClass: "label" }, [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.$t(`company.button.${item.name}.label`))
                      ),
                    ]),
                  ]
                )
              }),
              0
            ),
            _vm._v(" "),
            _c("div", { staticClass: "footer" }, [
              _c(
                "a",
                {
                  attrs: { href: _vm.$config.privacyPageUrl, target: "_blank" },
                },
                [
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm.$t("company.page.privacy.policy")) +
                      "\n      "
                  ),
                ]
              ),
            ]),
          ])
        : !_vm.callToActions.length && !_vm.landing
        ? _c("EmptyState", { attrs: { type: "company", source: _vm.source } })
        : _vm._e(),
      _vm._v(" "),
      _c(
        "client-only",
        [
          _vm.landing
            ? _c("LandingRenderer", {
                attrs: {
                  "container-styles": _vm.containerStyles,
                  "component-styles": _vm.componentStyles,
                },
                model: {
                  value: _vm.landing,
                  callback: function ($$v) {
                    _vm.landing = $$v
                  },
                  expression: "landing",
                },
              })
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }