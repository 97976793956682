import BaseTransformer from '@/transformers/BaseTransformer'
import { QR_VARIANTS } from '~/enums/qrVariants'
import { convertDate } from '~/helpers'

const typologies = [
  {
    iri: '/api/qr-typologies/4db1daa1-59a4-4e7a-91b8-b536e103ae17',
    name: 'blank',
  },
  {
    iri: '/api/qr-typologies/7a53f360-7c5e-4709-af9a-b0a44340a8e4',
    name: 'website',
  },
  {
    iri: '/api/qr-typologies/fab7b863-5eac-42df-aa29-7f3d6dba9bb8',
    name: 'builder',
  },
  {
    iri: '/api/qr-typologies/c29a14ed-7e8c-4fed-8135-e223532a51b0',
    name: 'smartpage',
  },
]

const qrStatus = {
  EREN01_NF: 'NotFound',
  EREN02: 'Inactive',
  EREN03: 'Expired',
  EREN04: 'Expired',
  EREN05: 'Expired',
  EREN06_NA: 'NeedAuth',
  EREN07: 'LimitExceeded',
  'Not Found': 'NotFound',
}

function setProductInfo(_productInfo) {
  const allergens = {
    mayContain: [],
    contains: [],
  }

  _productInfo.allergens.forEach((allergen) => {
    if (allergen.level === 'MAY_CONTAIN')
      allergens.mayContain = [...allergens.mayContain, allergen.name]
    if (allergen.level === 'CONTAINS')
      allergens.contains = [...allergens.contains, allergen.name]
  })

  _productInfo = { ..._productInfo, allergens }

  return _productInfo
}

class Transformer extends BaseTransformer {
  static async fetch({ data }) {
    const { qr, productInfo, linkset = [] } = data
    const isFree = qr.isFree
    const isVariant = data?.isVariant
    const { qualifiers = [], attributes = [] } = qr
    let design,
      error,
      redirect,
      isSmartpage,
      smartpageInfo,
      isBlankPage,
      isBuilderPage

    function _setDesign() {
      const { components, name } = qr.design?.landing
      const theme = qr.design?.landing?.theme ?? { data: null }
      if (
        qr.design?.landing.style &&
        Object.keys(qr.design?.landing.style).length > 0
      ) {
        theme.data = {
          ...theme.data,
          ...qr.design.landing.style,
        }
      }
      design = {
        name,
        components,
        theme: theme?.data,
      }
    }

    function _setRedirectOptions() {
      const components = qr.design?.landing?.components || []

      if (components.length === 1 && components[0]?.type === 'url') {
        redirect = { ...components[0].inputs }
      } else _setError(qr)
    }

    function _setError() {
      error = 'NoContent'
    }

    async function _setSmartPageOptions() {
      isSmartpage = true
      // Format expiration date to dd/mm/yyyy
      // TODO: replace this condition for QR_VARIANTS.EXPIRATION_DATE in attributes
      const isNewExpDate = attributes[QR_VARIANTS.EXPIRATION_DATE]
      const isOldExpDate = attributes[QR_VARIANTS.EXPIRATION_DATE_OLD]
      if (isNewExpDate || isOldExpDate) {
        const date =
          attributes[QR_VARIANTS.EXPIRATION_DATE] ||
          attributes[QR_VARIANTS.EXPIRATION_DATE_OLD]
        isNewExpDate
          ? (attributes[QR_VARIANTS.EXPIRATION_DATE] = convertDate(date))
          : (attributes[QR_VARIANTS.EXPIRATION_DATE_OLD] = convertDate(date))
      }
      _setDesign()
      smartpageInfo = {
        qualifiers,
        attributes,
        isVariant,
        ...(await setProductInfo(productInfo)),
        gtin: isVariant ? productInfo?.gtin : qr?.pathName,
      }
    }

    function _setBlankPageOptions() {
      isBlankPage = true
    }

    if (qr.design) {
      const typology = typologies.find(({ iri }) => iri === qr.design?.typology)

      switch (typology.name) {
        case 'website':
          await _setRedirectOptions()
          break
        case 'smartpage':
          await _setSmartPageOptions()
          break
        case 'blank':
          await _setBlankPageOptions()
          break
        case 'builder':
          isBuilderPage = true
          await _setDesign()
          break
        default:
          break
      }
    } else {
      _setError()
    }

    return {
      design,
      error,
      redirect,
      smartpageInfo,
      isFree,
      isSmartpage,
      isBlankPage,
      isBuilderPage,
      linkset,
      qr,
    }
  }

  static error({ data, headers }) {
    const code = data['hydra:description']

    return {
      code,
      error: qrStatus[code] || qrStatus['Not Found'],
      link_after: headers['x-link-after'],
    }
  }
}

export default Transformer
