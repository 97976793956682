<template>
  <div
    v-if="source.ingredients && source.ingredients.length"
    class="ingredients-tab"
  >
    <p class="title">{{ $t('ingredients.page.title') }}</p>
    <p class="content" v-html="source.ingredients" />
  </div>
  <EmptyState v-else type="ingredients" :source="source" />
</template>
<script>
import EmptyState from '../../components/common/EmptyState.vue'
export default {
  name: 'IngredientsTab',
  components: { EmptyState },
  props: {
    source: {
      type: Object,
      default: () => {},
    },
  },
}
</script>
<style lang="scss" scoped>
@import '~/assets/scss/tokens.scss';
.ingredients-tab {
  display: grid;
  grid-row-gap: 16px;
  grid-template-rows: auto 1fr;
  flex-direction: column;
  height: 100%;
  .title {
    color: $DL-black;
    @include font-24-700;
    margin: 0;
  }

  .content {
    color: $DL-black;
    @include font-16-400;
    line-height: 23px;
    margin: 0;
  }
}
</style>
