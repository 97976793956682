var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "smart-page-wrapper" },
    [
      _c("SmartPageHeaderComponent", { attrs: { title: _vm.productName } }),
      _vm._v(" "),
      _c("SmartPageTabs", {
        attrs: { source: _vm.productInfo, tabs: _vm.tabs },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }