<template>
  <div
    v-if="source?.additionalInfo?.useMode && source.additionalInfo.useMode"
    class="ingredients-tab"
  >
    <p class="title">{{ $t('use.tab.label') }}</p>
    <p class="content" v-html="source.additionalInfo.useMode" />
    <p class="title">{{ $t('composition.page.storage.title') }}</p>
    <p class="content" v-html="source.additionalInfo.storage" />
  </div>
  <EmptyState v-else type="ingredients" :source="source" />
</template>
<script>
import EmptyState from '../../components/common/EmptyState.vue'

export default {
  name: 'UseModeTab',
  components: { EmptyState },
  props: {
    source: {
      type: Object,
      default: () => {},
    },
  },
}
</script>
<style lang="scss" scoped>
@import '~/assets/scss/tokens.scss';

.ingredients-tab {
  display: flex;
  gap: 10px;
  flex-direction: column;
  height: 100%;

  .title {
    color: $DL-black;
    @include font-24-700;
    margin: 0;
  }

  .content {
    color: $DL-black;
    @include font-16-400;
    line-height: 23px;
    margin: 0;
  }
}
</style>
