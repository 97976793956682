<template>
  <svg
    v-if="filled"
    width="32"
    height="33"
    viewBox="0 0 32 33"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M14.4304 14.2675L12.9062 12.7433C12.6918 12.5405 12.4397 12.4449 12.1501 12.4565C11.8606 12.4681 11.6143 12.5753 11.4115 12.7781C11.1971 12.9809 11.0899 13.2271 11.0899 13.5167C11.0899 13.8063 11.1971 14.0525 11.4115 14.2554L13.6259 16.4349C13.8509 16.6599 14.1191 16.7724 14.4304 16.7724C14.7416 16.7724 15.0156 16.6599 15.2522 16.4349L20.2735 11.3879C20.4764 11.1851 20.5764 10.9402 20.5736 10.6534C20.5708 10.3666 20.468 10.1218 20.2652 9.91896C20.0624 9.71614 19.8176 9.61473 19.5308 9.61473C19.2439 9.61473 18.9991 9.71614 18.7963 9.91896L14.4304 14.2675ZM15.8176 29.1649C15.5628 29.1649 15.311 29.1215 15.0623 29.0347C14.8135 28.948 14.5908 28.8148 14.394 28.6351C13.5368 27.893 12.5458 26.9737 11.421 25.8774C10.2961 24.7811 9.23057 23.5673 8.22428 22.2361C7.21802 20.9049 6.36967 19.4869 5.67922 17.9822C4.98878 16.4774 4.64355 14.9514 4.64355 13.404C4.64355 9.91365 5.76814 7.13219 8.01733 5.0596C10.2665 2.98699 12.8666 1.95068 15.8176 1.95068C18.7685 1.95068 21.37 2.98699 23.622 5.0596C25.8739 7.13219 26.9999 9.91365 26.9999 13.404C26.9999 14.9514 26.6533 16.4774 25.9601 17.9822C25.2668 19.4869 24.4171 20.9049 23.4108 22.2361C22.4045 23.5673 21.339 24.7811 20.2142 25.8774C19.0893 26.9737 18.101 27.893 17.2494 28.6351C17.0471 28.8148 16.8216 28.948 16.5728 29.0347C16.3241 29.1215 16.0723 29.1649 15.8176 29.1649Z"
      fill="#615DF4"
    />
  </svg>

  <svg
    v-else
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M14.4206 13.8671L12.8964 12.3429C12.682 12.1401 12.43 12.0445 12.1404 12.0561C11.8508 12.0677 11.6046 12.1749 11.4018 12.3777C11.1873 12.5805 11.0801 12.8267 11.0801 13.1164C11.0801 13.4059 11.1873 13.6522 11.4018 13.855L13.6161 16.0345C13.8411 16.2595 14.1093 16.372 14.4206 16.372C14.7319 16.372 15.0058 16.2595 15.2425 16.0345L20.2638 10.9875C20.4666 10.7847 20.5666 10.5398 20.5639 10.253C20.5611 9.96621 20.4583 9.72138 20.2555 9.51857C20.0526 9.31575 19.8078 9.21434 19.521 9.21434C19.2342 9.21434 18.9893 9.31575 18.7865 9.51857L14.4206 13.8671ZM15.8078 26.4737C18.8319 23.7104 21.0695 21.2179 22.5205 18.9962C23.9715 16.7746 24.6971 14.777 24.6971 13.0036C24.6971 10.316 23.8395 8.11736 22.1244 6.40777C20.4092 4.69818 18.3037 3.84338 15.8078 3.84338C13.3119 3.84338 11.2064 4.69818 9.49123 6.40777C7.77609 8.11736 6.91852 10.316 6.91852 13.0036C6.91852 14.777 7.66144 16.7732 9.14728 18.9921C10.6331 21.211 12.8533 23.7049 15.8078 26.4737ZM15.8078 28.7645C15.553 28.7645 15.3012 28.7211 15.0525 28.6344C14.8038 28.5476 14.581 28.4144 14.3843 28.2347C13.5271 27.4926 12.5361 26.5733 11.4112 25.477C10.2864 24.3807 9.22081 23.1669 8.21452 21.8357C7.20826 20.5045 6.3599 19.0865 5.66946 17.5818C4.97901 16.0771 4.63379 14.551 4.63379 13.0036C4.63379 9.51327 5.75838 6.7318 8.00756 4.65921C10.2567 2.5866 12.8568 1.55029 15.8078 1.55029C18.7588 1.55029 21.3602 2.5866 23.6122 4.65921C25.8642 6.7318 26.9902 9.51327 26.9902 13.0036C26.9902 14.551 26.6435 16.0771 25.9503 17.5818C25.2571 19.0865 24.4073 20.5045 23.4011 21.8357C22.3948 23.1669 21.3292 24.3807 20.2044 25.477C19.0795 26.5733 18.0913 27.4926 17.2396 28.2347C17.0373 28.4144 16.8118 28.5476 16.5631 28.6344C16.3143 28.7211 16.0626 28.7645 15.8078 28.7645Z"
      fill="#434263"
    />
  </svg>
</template>
<script>
export default {
  name: 'DOIcon',
  props: {
    filled: {
      type: Boolean,
      default: false,
    },
  },
}
</script>
