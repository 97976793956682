<template>
  <div
    class="smart-page__tabs-content"
    :style="activeKey === 3 ? source?.background : ''"
  >
    <slot v-bind="$attrs"></slot>
  </div>
</template>
<script>
export default {
  name: 'TabContent',
  props: {
    source: {
      type: Object,
      default: () => {},
    },
    activeKey: { type: Number, required: true },
  },
}
</script>
<style lang="scss" scoped>
@import '~/assets/scss/tokens.scss';

.smart-page {
  &__tabs-content {
    > div {
      overflow-y: auto;
      overflow-x: hidden;
      max-height: calc(100% - 4rem);
      padding: 1.5rem 1rem;
    }

    .title {
      color: $DL-black;
      @include font-24-700;
      margin: 0;
    }
  }
}
</style>
