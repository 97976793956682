<template>
  <CommonModal
    :is-visible="model"
    :title="$t('dashboard.key.qualifiers.label')"
    @close="model = false"
  >
    <div
      v-for="(qualifierValue, i) in qualifiers"
      :key="i"
      class="qualifiers_content"
    >
      <span class="qualifiers_content__label">{{
        $t(generateVariantLabel(i))
      }}</span>
      <span class="qualifiers_content__value">{{ qualifierValue }}</span>
    </div>
    <div
      v-for="(attributeValue, i) in attributes"
      :key="i"
      class="qualifiers_content"
    >
      <span class="qualifiers_content__label">{{
        $t(generateVariantLabel(i))
      }}</span>
      <span class="qualifiers_content__value">{{
        getOriginCountry(attributeValue, i)
      }}</span>
    </div>
  </CommonModal>
</template>

<script>
import CommonModal from '~/components/SmartPage/components/common/CommonModal.vue'
import { QR_VARIANTS, QR_VARIANTS_LABELS } from '~/enums/qrVariants'
import isoCodes from '~/const/isoCodes'

export default {
  name: 'QualifiersModal',
  components: { CommonModal },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    source: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    model: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      },
    },
    qualifiers() {
      return this.source?.qualifiers
    },
    attributes() {
      return this.source?.attributes
    },
  },
  methods: {
    generateVariantLabel(index) {
      return QR_VARIANTS_LABELS[index]
    },
    getOriginCountry(value, key) {
      if (+key !== QR_VARIANTS.COUNTRY_OF_ORIGIN) return value
      return (
        Object.entries(isoCodes).find(([clave, val]) => val === value)?.[0] +
        ` (${value})`
      )
    },
  },
}
</script>

<style scoped lang="scss">
.qualifiers_content {
  display: flex;
  padding-top: 10px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  //gap: 8px;
  align-self: stretch;
  font-family: Inter, serif;

  &__label {
    overflow: hidden;
    color: rgba(18, 18, 18, 0.6);
    text-overflow: ellipsis;
    white-space: nowrap;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px; /* 166.667% */
  }

  &__value {
    overflow: hidden;
    color: #121212;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 22px; /* 157.143% */
  }
}
</style>
