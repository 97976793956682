<template>
  <svg
    v-if="filled"
    width="32"
    height="33"
    viewBox="0 0 32 33"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M10.2985 2.10004C11.0375 2.10004 11.6366 2.69912 11.6366 3.43813V4.10262H14.6635V3.43813C14.6635 2.69912 15.2626 2.10004 16.0016 2.10004C16.7406 2.10004 17.3397 2.69912 17.3397 3.43813V4.10262H20.3666V3.43813C20.3666 2.69912 20.9657 2.10004 21.7047 2.10004C22.4438 2.10004 23.0428 2.69912 23.0428 3.43813V4.10262H25.3353C25.9504 4.10262 26.5493 4.33809 26.9981 4.77135C27.4484 5.20616 27.7105 5.80609 27.7105 6.44198V25.466C27.7105 26.633 27.2299 27.7423 26.3906 28.5526C25.5529 29.3614 24.4264 29.8079 23.2613 29.8079H8.74298C7.57785 29.8079 6.45139 29.3614 5.6137 28.5526C4.7744 27.7423 4.29382 26.633 4.29382 25.466V6.44198C4.29382 5.80609 4.55589 5.20615 5.00622 4.77135C5.45496 4.33809 6.05387 4.10262 6.66894 4.10262H8.96038V3.43813C8.96038 2.69912 9.55946 2.10004 10.2985 2.10004ZM10.5145 14.62C10.5145 13.881 11.1136 13.282 11.8526 13.282H20.1488C20.8878 13.282 21.4869 13.881 21.4869 14.62C21.4869 15.3591 20.8878 15.9581 20.1488 15.9581H11.8526C11.1136 15.9581 10.5145 15.3591 10.5145 14.62ZM11.8526 18.2883C11.1136 18.2883 10.5145 18.8874 10.5145 19.6264C10.5145 20.3654 11.1136 20.9645 11.8526 20.9645H20.1488C20.8878 20.9645 21.4869 20.3654 21.4869 19.6264C21.4869 18.8874 20.8878 18.2883 20.1488 18.2883H11.8526Z"
      fill="#5652E9"
    />
  </svg>
  <svg
    v-else
    width="32"
    height="33"
    viewBox="0 0 32 33"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M10.5139 15.9535C10.5139 15.2145 11.113 14.6154 11.852 14.6154H20.1482C20.8872 14.6154 21.4863 15.2145 21.4863 15.9535C21.4863 16.6925 20.8872 17.2916 20.1482 17.2916H11.852C11.113 17.2916 10.5139 16.6925 10.5139 15.9535Z"
      fill="#434263"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M10.5139 20.9598C10.5139 20.2208 11.113 19.6217 11.852 19.6217H20.1482C20.8872 19.6217 21.4863 20.2208 21.4863 20.9598C21.4863 21.6988 20.8872 22.2979 20.1482 22.2979H11.852C11.113 22.2979 10.5139 21.6988 10.5139 20.9598Z"
      fill="#434263"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M6.96794 6.77836V25.4655C6.96794 25.8919 7.1429 26.3106 7.47049 26.6269C7.79967 26.9447 8.25583 27.1312 8.74091 27.1312H23.2592C23.7443 27.1312 24.2005 26.9447 24.5297 26.6269C24.8572 26.3106 25.0322 25.8919 25.0322 25.4655V6.77836H6.96794ZM5.00415 4.7709C5.45288 4.33764 6.0518 4.10217 6.66686 4.10217H25.3333C25.9483 4.10217 26.5473 4.33764 26.996 4.7709C27.4463 5.20571 27.7084 5.80564 27.7084 6.44153V25.4655C27.7084 26.6325 27.2278 27.7418 26.3885 28.5522C25.5508 29.361 24.4244 29.8074 23.2592 29.8074H8.74091C7.57577 29.8074 6.44932 29.361 5.61162 28.5522C4.77233 27.7418 4.29175 26.6325 4.29175 25.4655V6.44153C4.29175 5.80564 4.55381 5.20571 5.00415 4.7709Z"
      fill="#434263"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M10.2965 2.09961C11.0355 2.09961 11.6346 2.69869 11.6346 3.4377V7.44276C11.6346 8.18177 11.0355 8.78085 10.2965 8.78085C9.55746 8.78085 8.95837 8.18177 8.95837 7.44276V3.4377C8.95837 2.69869 9.55746 2.09961 10.2965 2.09961Z"
      fill="#434263"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M16.0001 2.09961C16.7391 2.09961 17.3382 2.69869 17.3382 3.4377V7.44276C17.3382 8.18177 16.7391 8.78085 16.0001 8.78085C15.2611 8.78085 14.662 8.18177 14.662 7.44276V3.4377C14.662 2.69869 15.2611 2.09961 16.0001 2.09961Z"
      fill="#434263"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M21.7037 2.09961C22.4427 2.09961 23.0418 2.69869 23.0418 3.4377V7.44276C23.0418 8.18177 22.4427 8.78085 21.7037 8.78085C20.9647 8.78085 20.3656 8.18177 20.3656 7.44276V3.4377C20.3656 2.69869 20.9647 2.09961 21.7037 2.09961Z"
      fill="#434263"
    />
  </svg>
</template>
<script>
export default {
  name: 'IngredientsIcon',
  props: {
    filled: {
      type: Boolean,
      default: false,
    },
  },
}
</script>
