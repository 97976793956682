<template>
  <table>
    <thead>
      <tr>
        <th v-for="(column, key) in tableColumns" :key="key">
          <p>
            {{
              $t(`nutrition.table.column.${key + 1}.title`, {
                ...column.titleProps,
              })
            }}
          </p>
        </th>
      </tr>
    </thead>
    <tbody>
      <tr v-for="(row, key) in tableRows" :key="key">
        <td
          v-for="(column, columnKey) in tableColumns"
          :key="`${columnKey}`"
          :class="row.class"
        >
          <span v-if="column.key === 'name'"> {{ row[column.dataIndex] }}</span>
          <span v-else>
            {{ convertKjToCal(row.code, row.quantity, row.unit) }}</span
          >
        </td>
      </tr>
    </tbody>
  </table>
</template>
<script>
const PRIORITIZED_CODES = [
  'energy',
  'ener-',
  'fat',
  'choavl',
  'carbohydrates',
  'fiber',
  'fibtg',
  'proteins',
  'pro-',
]
const ORDER_CODES = {
  energy: 0,
  'ener-': 1,
  fat: 2,
  fasat: 3,
  'saturated-fat': 4,
  famscis: 5,
  'monounsaturated-fat': 6,
  fapucis: 7,
  'polyunsaturated-fat': 8,
  'trans-fat': 9,
  choavl: 10,
  carbohydrates: 11,
  sugar: 12,
  sugars: 13,
  'sugar-': 14,
  starch: 15,
  fiber: 16,
  fibtg: 17,
  proteins: 18,
  'pro-': 19,
  salteq: 20,
  salt: 21,
}
const PRIORITIZED_CODES_CHILDS = {
  fasat: 'fat',
  'saturated-fat': 'fat',
  famscis: 'fat',
  'monounsaturated-fat': 'fat',
  fapucis: 'fat',
  'polyunsaturated-fat': 'fat',
  'trans-fat': 'fat',
  sugar: 'choavl',
  sugars: 'choavl',
  'sugar-': 'choavl',
  starch: 'choavl',
}

const CODES_FOR_CONVERTION = {
  'ener-': 'ener-',
  energy: 'energy',
}

export default {
  name: 'NutrientsTable',
  props: {
    nutrientInfo: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      tableColumns: [],
      tableRows: [],
    }
  },
  watch: {
    nutrientInfo: {
      handler() {
        this.setTableData()
      },
      deep: true,
    },
  },
  beforeMount() {
    this.setTableData()
  },
  methods: {
    convertKjToCal(code, quantity, unit) {
      const lowerUnit = unit.toLowerCase()
      if (CODES_FOR_CONVERTION[code]) {
        if (lowerUnit === 'kcal')
          return quantity === 0
            ? `${quantity}kj / ${quantity}kcal`
            : `${Math.round(quantity * 4.184)}kj / ${quantity}${unit}`
        if (lowerUnit === 'kj')
          return quantity === 0
            ? `${quantity}kj / ${quantity}kcal`
            : `${quantity}${unit} / ${Math.round(quantity / 4.184)}kcal`
      }
      return `${quantity}${unit}`
    },
    findOutChildCode(code) {
      return PRIORITIZED_CODES.includes(PRIORITIZED_CODES_CHILDS[code])
    },
    setTableData() {
      this.tableColumns = [
        {
          dataIndex: 'name',
          key: 'name',
        },
        {
          dataIndex: 'quantity',
          key: 'quantity',
          titleProps: {
            quantity: this.nutrientInfo?.serving?.quantity,
            unit: this.nutrientInfo?.serving?.unit,
          },
        },
      ]
      this.tableRows = this.nutrientInfo?.nutrients
        .map((nutrient) => {
          const row = {
            name: nutrient.name,
            quantity: nutrient.quantity,
            unit: nutrient.unit,
            code: nutrient.code,
            class: PRIORITIZED_CODES.includes(nutrient.code) ? 'font-bold' : '',
            order: ORDER_CODES[nutrient.code] ?? 99,
          }

          if (this.findOutChildCode(nutrient.code)) {
            row.class = 'pl-5'
          }
          return row
        })
        .sort((a, b) => a.order - b.order)
    },
  },
}
</script>
<style lang="scss" scoped>
@import '~/assets/scss/tokens.scss';

table {
  width: 100%;
  color: $DL-black;

  thead {
    tr {
      th {
        border-right: 3px solid $white-elevation-0;
        background: #f9f9f9;
        padding: 0.5rem;
        @include font-14-700;

        &:first-of-type {
          border-top-left-radius: 0.5rem;
          border-bottom-left-radius: 0.5rem;
          text-align: start;
        }

        &:last-of-type {
          border-right: 0;
          border-top-right-radius: 0.5rem;
          border-bottom-left-radius: 0.5rem;
          text-align: end;
        }

        p {
          margin: 0;
        }
      }
    }
  }

  tbody {
    tr {
      &:nth-child(even) {
        td {
          background: #f9f9f9;
        }
      }

      td {
        border-right: 3px solid $white-elevation-0;
        color: $DL-black;
        @include font-14-400;
        padding: 0.5rem;

        &:first-of-type {
          @include capitalize-first-letter;
        }

        &:last-of-type {
          text-align: end;
        }
      }
    }
  }
}

.pl-5 {
  padding-left: 2em;
}

.font-bold {
  font-weight: bold;
}
</style>
