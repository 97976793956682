var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.filled
    ? _c(
        "svg",
        {
          attrs: {
            xmlns: "http://www.w3.org/2000/svg",
            width: "35",
            height: "35",
            viewBox: "0 0 35 35",
            fill: "none",
          },
        },
        [
          _c("path", {
            attrs: {
              d: "M16.6562 28.9903C15.9458 28.9903 15.23 28.9095 14.5089 28.7481C13.7877 28.5866 13.0503 28.3552 12.2969 28.0538C12.5552 25.4489 13.3087 23.0163 14.5573 20.7559C15.8059 18.4955 17.4097 16.5042 19.3687 14.7819C17.0007 15.9875 14.9502 17.5805 13.2172 19.5611C11.4842 21.5417 10.2733 23.8021 9.58437 26.3423C9.49826 26.2778 9.41753 26.2078 9.34219 26.1325L9.1 25.8903C8.08819 24.8785 7.31858 23.7482 6.79115 22.4996C6.26372 21.251 6 19.9486 6 18.5923C6 17.1285 6.29062 15.7292 6.87187 14.3944C7.45312 13.0597 8.26042 11.8757 9.29375 10.8423C11.0375 9.0986 13.2979 7.96301 16.075 7.43557C18.8521 6.90814 22.7486 6.85971 27.7646 7.29026C28.1521 12.4354 28.0875 16.3588 27.5708 19.0606C27.0542 21.7623 25.9347 23.9743 24.2125 25.6965C23.1576 26.7514 21.979 27.564 20.6766 28.1345C19.3741 28.705 18.034 28.9903 16.6562 28.9903Z",
              fill: "#615DF4",
            },
          }),
        ]
      )
    : _c(
        "svg",
        {
          attrs: {
            xmlns: "http://www.w3.org/2000/svg",
            width: "35",
            height: "35",
            viewBox: "0 0 35 35",
            fill: "none",
          },
        },
        [
          _c("path", {
            attrs: {
              d: "M10.1 25.8484C9.13125 24.8796 8.3724 23.7602 7.82344 22.4901C7.27448 21.2199 7 19.9067 7 18.5505C7 17.1942 7.25833 15.8541 7.775 14.5302C8.29167 13.2062 9.13125 11.963 10.2938 10.8005C11.0472 10.047 11.9783 9.40118 13.087 8.86298C14.1957 8.32479 15.5089 7.89961 17.0266 7.58746C18.5443 7.27531 20.2773 7.08694 22.2255 7.02236C24.1738 6.95777 26.3535 7.03312 28.7646 7.2484C28.9368 9.53034 28.9906 11.6293 28.926 13.5453C28.8615 15.4612 28.6839 17.1888 28.3932 18.7281C28.1026 20.2673 27.6936 21.6128 27.1661 22.7645C26.6387 23.9163 25.9875 24.8796 25.2125 25.6546C24.0715 26.7956 22.8606 27.6298 21.5797 28.1573C20.2988 28.6847 18.991 28.9484 17.6563 28.9484C16.2569 28.9484 14.8899 28.6739 13.5552 28.125C12.2205 27.576 11.0688 26.8171 10.1 25.8484ZM13.7167 25.3317C14.341 25.6977 14.9814 25.9614 15.638 26.1229C16.2946 26.2843 16.9674 26.3651 17.6563 26.3651C18.6465 26.3651 19.626 26.1659 20.5948 25.7677C21.5635 25.3694 22.4892 24.729 23.3719 23.8463C23.7594 23.4588 24.1523 22.9152 24.5505 22.2156C24.9488 21.5159 25.2932 20.601 25.5839 19.4708C25.8745 18.3406 26.0951 16.9736 26.2458 15.3698C26.3965 13.7659 26.4181 11.8553 26.3104 9.63798C25.2556 9.59493 24.0661 9.57878 22.7422 9.58954C21.4182 9.60031 20.0997 9.70256 18.7865 9.89631C17.4733 10.0901 16.2247 10.4022 15.0406 10.8328C13.8566 11.2633 12.8878 11.8553 12.1344 12.6088C11.1656 13.5776 10.4983 14.5356 10.1323 15.4828C9.76632 16.43 9.58333 17.3449 9.58333 18.2276C9.58333 19.4977 9.82552 20.6118 10.3099 21.5698C10.7943 22.5277 11.2194 23.2005 11.5854 23.588C12.4896 21.8658 13.6844 20.2135 15.1698 18.6312C16.6552 17.0489 18.3882 15.7519 20.3688 14.7401C18.8188 16.0963 17.4679 17.6302 16.3161 19.3416C15.1644 21.0531 14.2979 23.0498 13.7167 25.3317Z",
              fill: "#434263",
            },
          }),
        ]
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }