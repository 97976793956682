<template>
  <div class="content">
    <div class="content-body">
      <img src="~/assets/images/logo.svg" class="logo" alt="digitallinkLogo" />
      <div class="mt-3 w-100 d-flex flex-column align-items-center h-75">
        <p class="text-center fw-bolder fs-1 mt-5">
          This space is for something amazing.
        </p>
        <p class="fw-400 text-center fs-5">
          Is this your QR? Log in to your digital-link.com account and finish its setup!.
        </p>
        <div class="astronaut-image">
          <img src="~/assets/images/astronaut.gif" />
        </div>
      </div>

      <div class="d-flex flex-column powered-image align-items-center">
        <button class="button mb-3 black w-100">Log in now</button>
        <img class="pt-4" src="~/assets/images/powered-by-digitallink.svg" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'NoContentComponent',
}
</script>

<style scoped>
@media only screen and (max-width: 600px) {
  .content {
    background: url('~@/assets/images/bg-aquamarine-mobile.svg');
    height: 100vh;
    display: flex;
    justify-content: center;
    background-repeat: repeat no-repeat;
    background-color: #9cd2bc;
    background-size: cover;
  }
  .astronaut-image img {
    position: absolute;
    margin: auto;
    right: 0;
    left: 0;
    bottom: 0;
    padding: 2rem;
    top: 0;
  }
}

@media only screen and (min-width: 600px) {
  .content {
    background: url('~@/assets/images/bg-aquamarine-desktop.svg');
    height: 100vh;
    display: flex;

    justify-content: center;
    background-position: bottom;
    background-repeat: no-repeat;
    background-color: #9cd2bc;
    background-size: cover;
  }
}

.content-body {
  max-width: 900px;
  width: 100%;
  padding: 2rem;
  position: relative;
  display: flex;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.content-body .logo {
  width: 10rem;
}

.astronaut-image {
  position: relative;
  width: 100%;
  height: inherit;
}

.astronaut-image img {
  position: absolute;
  margin: auto;
  right: 0;
  left: 0;
  bottom: 0;
  top: 0;
}

.powered-image {
  position: absolute;
  bottom: 0%;
}

.powered-image img {
  width: auto;
}

.url {
  font-weight: 700;
  background: white;
  padding: 1rem;
  padding: 1rem 2rem;
}

.text-center {
  text-align: center;
}

img {
  max-width: 100% !important;
}

.button {
  text-align: center;
  vertical-align: middle;
  display: inline-block;
  font-weight: 400;
  color: #3c4b64;
  padding: 0.375rem 5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  border: unset;
  position: relative;
  height: 3rem;
  max-width: 100%;
}
.button.black {
  background: black;
  color: white;
}
.button.white {
  background: white;
  color: black;
}
button:focus {
  outline: unset !important;
}
</style>
