var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return Boolean(_vm.impact)
    ? _c("div", { staticClass: "instruction-wrapper" }, [
        _c("div", { staticClass: "title" }, [
          _vm._v(_vm._s(_vm.$t("environmental.page.title"))),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "instruction" }, [
          _c("p", { domProps: { innerHTML: _vm._s(_vm.impact) } }),
        ]),
      ])
    : _c("SmartPageEmptyStateComponent", {
        attrs: { source: _vm.source, type: "sustainability" },
      })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }