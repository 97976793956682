var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "content" }, [
    _c("div", { staticClass: "content-body" }, [
      _c(
        "div",
        {
          staticClass:
            "mt-5 w-100 position-relative d-flex flex-column align-items-center text-container",
        },
        [
          _c("h1", [_vm._v(_vm._s(_vm.$t("notfound.page.title")))]),
          _vm._v(" "),
          _c("h4", { staticClass: "my-3" }, [
            _c("span", {
              domProps: { innerHTML: _vm._s(_vm.$t("notfound.page.body")) },
            }),
          ]),
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "d-flex flex-column align-items-center w-100 my-1" },
        [
          _c(
            "button",
            {
              staticClass: "button blue mb-3 w-100",
              on: { click: _vm.redirectToDashboard },
            },
            [
              _vm._v(
                "\n        " +
                  _vm._s(_vm.$t("notfound.page.cta.1")) +
                  "\n      "
              ),
            ]
          ),
          _vm._v(" "),
          _c(
            "button",
            {
              staticClass: "button white w-100",
              on: { click: _vm.redirectToSignUp },
            },
            [
              _vm._v(
                "\n        " +
                  _vm._s(_vm.$t("notfound.page.cta.2")) +
                  "\n      "
              ),
            ]
          ),
        ]
      ),
      _vm._v(" "),
      _c("div", { staticClass: "powered-image pointer" }, [
        _c("img", {
          staticClass: "logo",
          attrs: {
            src: require("assets/images/logo.svg"),
            alt: "digitallinkLogo",
          },
          on: { click: _vm.login },
        }),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }