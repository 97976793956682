<template>
  <div>
    <a id="landing_url" :href="externalURL"/>
  </div>
</template>
<script>
export default {
  name: 'RedirectComponent',
  props: {
    url: { type: String, default: '' },
  },
  data() {
    return { externalURL: this.url }
  },
  mounted() {
    window.location.replace(this.externalURL)
  },
  beforeMount() {
    if (!/^(http|https):\/\//i.test(this.externalURL)) {
      this.externalURL = `https://${this.externalURL}`
    }
  },
}
</script>
