var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm._m(0)
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "content" }, [
      _c("div", { staticClass: "content-body" }, [
        _c("img", {
          staticClass: "logo",
          attrs: {
            src: require("assets/images/logo.svg"),
            alt: "digitallinkLogo",
          },
        }),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass:
              "w-100 position-relative d-flex justify-content-center h-50",
          },
          [
            _c(
              "div",
              {
                staticClass:
                  "mt-5 fw-500 d-flex flex-column align-items-center",
              },
              [
                _c("p", { staticClass: "text-center fs-2 px-2" }, [
                  _vm._v(
                    "\n          oio iioioioioioio oiooiioiioi ioioio ioioio io ioi\n        "
                  ),
                ]),
              ]
            ),
            _vm._v(" "),
            _c("img", {
              staticClass: "dog-image",
              attrs: { src: require("assets/images/dog.svg") },
            }),
          ]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "d-flex flex-column powered-image align-items-center",
          },
          [
            _c("img", {
              staticClass: "pt-4",
              attrs: {
                src: require("assets/images/powered-by-digitallink.svg"),
              },
            }),
          ]
        ),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }