<template>
  <div class="content">
    <div class="content-body">
      <div
        class="mt-5 w-100 position-relative d-flex flex-column align-items-center text-container"
      >
        <h1>{{ $t('notfound.page.title') }}</h1>
        <h4 class="my-3">
          <span v-html="$t('notfound.page.body')" />
        </h4>
      </div>

      <div class="d-flex flex-column align-items-center w-100 my-1">
        <button class="button blue mb-3 w-100" @click="redirectToDashboard">
          {{ $t('notfound.page.cta.1') }}
        </button>
        <button class="button white w-100" @click="redirectToSignUp">
          {{ $t('notfound.page.cta.2') }}
        </button>
      </div>

      <div class="powered-image pointer">
        <img
          src="~/assets/images/logo.svg"
          class="logo"
          alt="digitallinkLogo"
          @click="login"
        />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'NotFoundComponent',
  methods: {
    login() {
      window.open(this.$config.digitalUrlDomain)
    },
    redirectToDashboard() {
      window.open(this.$config.digitalUrlDomain + 'en/your-content')
    },
    redirectToSignUp() {
      window.open(this.$config.digitalUrlDomain + 'en/signup/trial')
    },
  },
}
</script>

<style scoped>
.content {
  height: 100vh;
  display: flex;
  justify-content: center;
}

.content-body {
  max-width: 900px;
  width: 100%;
  padding: 2rem 17rem;
  position: relative;
  display: flex;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.text-container h1 {
  text-align: center;
  font-weight: 700;
  font-size: 32px;
  line-height: 113%;
}

.text-container h4 {
  font-weight: 300;
  font-size: 18px;
  line-height: 146%;
}

.button {
  text-align: center;
  vertical-align: middle;
  display: inline-block;
  font-weight: 400;
  color: #3c4b64;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  border: unset;
  position: relative;
  height: 3rem;
  max-width: 100%;
  width: 100%;
  margin-bottom: 1rem;
}

.button.blue {
  background: #615df4;
  border-radius: 8px;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  color: white;
}

.button.white {
  background: white;
  border: 1px solid #32312f;
  border-radius: 8px;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  color: #32312f;
}

.content-body .logo {
  width: 7rem;
}

.powered-image {
  position: absolute;
  bottom: 2%;
}

.powered-image img {
  width: auto;
}

img {
  max-width: 100% !important;
}

.pointer {
  cursor: pointer;
}

@media (max-width: 600px) {
  .content {
    height: 100%;
    display: flex;
    justify-content: center;
  }

  .content-body {
    padding: 1rem 2rem;
  }

  .content-body .logo {
    width: 6rem;
  }
}
</style>
