<template>
  <div class="instruction-wrapper">
    <div class="title">{{ $t('manual.tab.label') }}</div>
    <div
      v-for="(instruction, key) in instructions"
      :key="key"
      class="instruction"
    >
      <span class="step">{{
        $t('instruction.tab.step.label', { step: key + 1 })
      }}</span>
      <p>{{ instruction }}</p>
    </div>
    <div class="title" style="margin: 10px 0">
      {{ $t('manual.page.precautions.title') }}
    </div>
    <p v-html="precautions" />
  </div>
</template>

<script>
export default {
  props: {
    source: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    additionalInfo() {
      return this.source?.additionalInfo || {}
    },
    instructions() {
      return this.additionalInfo?.instructions || []
    },
    precautions() {
      return this.additionalInfo?.precautions || ''
    },
  },
}
</script>

<style scoped lang="scss">
.instruction-wrapper {
  .title {
    color: #32312f;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }

  .instruction {
    font-family: Inter, serif;
    margin: 10px 0;

    .step {
      text-transform: uppercase;
      color: var(--DL-Black, #32312f);
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: 23px;
      margin-bottom: 10px;
    }
  }

  p {
    color: var(--DL-Black, #32312f);
    font-family: Inter, serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 23px;
  }
}
</style>
