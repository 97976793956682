<template>
  <transition name="modal">
  <div v-if="isVisible" class="modal-overlay" @click.self="closeModal">
    <div class="modal-content">
      <div class="modal-header">
        {{ title }}
      </div>
        <slot></slot>
      <div class="modal-footer">
        <button @click="closeModal">{{$t('component.button.close.label')}}</button>
      </div>
    </div>
  </div>
  </transition>
</template>

<script>
export default {
  props: {
    isVisible: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      default: ''
    }
  },
  methods: {
    closeModal() {
      this.$emit('close');
    }
  }
};
</script>

<style lang="scss" scoped>
.modal {
  &-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(18, 18, 18, 0.12);
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 0.2s ease-in-out;
    cursor: pointer;
  }

  &-content {
    display: flex;
    flex-direction: column;
    width: 330px;
    padding: 0 24px;
    border-radius: 8px;
    border: 1px solid rgba(18, 18, 18, 0.08);
    background: #FFF;
    box-shadow: 0 4px 12px 0 rgba(18, 18, 18, 0.12);
    font-family: Inter, serif;
    position: relative;
    bottom: 100px;
    overflow: hidden;
  }

  &-header {
    display: flex;
    height: 56px;
    padding: 24px 24px 0 0;
    align-items: center;
    gap: 10px;
    color: #121212;
    font-size: 20px;
    font-weight: 600;
    line-height: 28px; // 140%
  }

  &-footer {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 16px 0;
    gap: 16px;

    > button {
      border: none;
      cursor: pointer;
      display: flex;
      width: 73px;
      height: 32px;
      padding: 0 16px;
      border-radius: 4px;
      background: #615DF4;
      color: #FFF;
      font-size: 14px;
      font-weight: 600;
      line-height: 22px; // 157.143%
      gap: 8px;
      align-items: center;
      justify-content: center;
    }
  }

  &-enter-active,
  &-leave-active {
    transition: opacity 0.5s;
  }

  &-enter,
  &-leave-to {
    opacity: 0;
  }
}
</style>

