var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { class: { loader: true, fadeout: !_vm.isLoading } }, [
    _c(
      "svg",
      {
        staticClass: "animated-spin",
        attrs: {
          width: "74",
          height: "79",
          viewBox: "0 0 74 79",
          fill: "none",
          xmlns: "http://www.w3.org/2000/svg",
        },
      },
      [
        _c("circle", {
          attrs: {
            opacity: "0.5",
            cx: "23.2",
            cy: "25.2",
            r: "9.2",
            fill: "#615DF4",
          },
        }),
        _vm._v(" "),
        _c("circle", {
          attrs: { cx: "23.2", cy: "52.8001", r: "9.2", fill: "#615DF4" },
        }),
        _vm._v(" "),
        _c("circle", {
          attrs: {
            opacity: "0.3",
            cx: "50.8001",
            cy: "25.2",
            r: "9.2",
            fill: "#615DF4",
          },
        }),
        _vm._v(" "),
        _c("circle", {
          attrs: {
            opacity: "0.6",
            cx: "50.8001",
            cy: "52.8001",
            r: "9.2",
            fill: "#615DF4",
          },
        }),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }