<template>
  <div class="ingredients-tab">
    <div class="header">
      <div class="product-info">
        <p class="product-info__name">{{ description || name }}</p>
        <p class="product-info__net-content">{{ netContent }}</p>
        <p class="product-info__ean">{{ ean }}</p>
        <!--        <img-->
        <!--          style="margin: 10px auto"-->
        <!--          class="cruelty-free-logo"-->
        <!--          :src="crueltyFreeLogo"-->
        <!--          alt="cruelty free logo"-->
        <!--        />-->
      </div>
      <div v-if="isVariant">
        <button class="variants-btn" @click="showQualifiersContent = true">
          {{ $t('dashboard.key.qualifiers.label') }}
        </button>
        <QualifiersModal v-model="showQualifiersContent" :source="source" />
      </div>
      <div class="image-wrapper" :class="{ placeholder: !hasThumbnail }">
        <img v-if="hasThumbnail" :src="source?.thumbnail" alt="product image" />
        <product-thumbnail-placeholder v-else />
      </div>
    </div>
    <div class="content">
      <div class="container">
        <p class="title">{{ $t('ingredients.page.active.title') }}</p>
        <p class="content" v-html="ingredients" />
      </div>
      <div class="container">
        <p class="title">{{ $t('ingredients.page.inci.title') }}</p>
        <p class="content" v-html="inci" />
      </div>
    </div>
  </div>
</template>

<script>
import ProductThumbnailPlaceholder from '@/components/SmartPage/components/Icons/productThumbnailPlaceholder.vue'
import crueltyFreeLogo from '@/assets/images/cruelty-free-logo.png'
import QualifiersModal from '~/components/SmartPage/components/common/QualifiersModal.vue'

export default {
  components: {
    QualifiersModal,
    ProductThumbnailPlaceholder,
  },
  props: {
    source: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      crueltyFreeLogo,
      showQualifiersContent: false,
    }
  },
  computed: {
    ingredients() {
      return this.source?.ingredients || ''
    },

    isVariant() {
      return this.source?.isVariant
    },
    inci() {
      return this.source?.additionalInfo?.inci || ''
    },
    hasThumbnail() {
      return this.source?.thumbnail
    },
    description() {
      return this.source?.basicInfo?.description
    },
    name() {
      return this.source?.basicInfo?.name
    },
    ean() {
      return this.source?.gtin || ''
    },
    netContent() {
      const { quantity = '', unit = '' } = this.source?.netContent || {}
      return `${quantity} ${unit}`
    },
  },
}
</script>

<style scoped lang="scss">
@import '@/assets/scss/tokens.scss';

.cruelty-free-logo {
  height: 35px;
  margin: 15px 0;
}

.ingredients-tab {
  display: grid;
  grid-row-gap: 16px;
  grid-template-rows: auto 1fr;
  flex-direction: column;
  height: 100%;
  font-family: Inter, serif;

  .header {
    display: grid;
    grid-template-columns: 60% 1fr;
    column-gap: 1.25rem;

    .product-info {
      width: 100%;

      p {
        margin: 10px 0;
      }

      &__name {
        color: var(--DL-Black, #32312f);
        text-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 23px; /* 143.75% */
      }

      &__net-content,
      &__ean {
        @include font-12-500;
        color: $DL-gray-2;
      }
    }
  }

  .content {
    color: $DL-black;
    @include font-16-400;
    line-height: 23px;
    margin: 10px 0;

    .container {
      display: flex;
      flex-direction: column;
      gap: 12px;

      .title {
        margin: 0;
        color: #32312f;
        font-size: 24px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
      }

      .content {
        color: #32312f;
        text-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 23px; /* 143.75% */
      }
    }
  }
}

.image-wrapper {
  width: inherit;
  height: inherit;
  max-width: inherit;
  min-height: inherit;
  max-height: 9rem;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;

  &.placeholder {
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
  }

  img {
    max-width: 100%;
    max-height: 100%;
    width: auto;
    height: 100%;
    object-fit: contain;
  }
}
</style>
