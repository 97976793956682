<template>
  <div class="nutrition-tab">
    <div class="header">
      <div class="product-info">
        <p class="product-info__name">{{ description }}</p>
        <p v-if="netContent" class="product-info__net-content">
          {{ $t('component.label.net.weight') }} {{ netContent }}
        </p>
        <p v-if="drainedWeight" class="product-info__net-content">
          {{ $t('component.label.drained.weight') }} {{ drainedWeight }}
        </p>
        <p class="product-info__ean">
          {{ $t('component.label.gtin') }} {{ ean }}
        </p>
        <div v-if="isVariant">
          <button class="variants-btn" @click="showQualifiersContent = true">
            {{ $t('dashboard.key.qualifiers.label') }}
          </button>
          <QualifiersModal v-model="showQualifiersContent" :source="source" />
        </div>
      </div>
      <div class="image-wrapper" :class="{ placeholder: !source?.thumbnail }">
        <img v-if="source?.thumbnail" :src="source?.thumbnail" />
        <ProductThumbnailPlaceholder v-else />
      </div>
    </div>
    <div v-if="featuredNutrients.length" class="featured-nutrients">
      <FeaturedNutrientItem
        v-for="(nutrient, key) in featuredNutrients"
        :key="key"
        :nutrient="nutrient"
      />
    </div>
    <div class="content">
      <p class="title">{{ $t('nutrition.page.title') }}</p>
      <div class="nutrients-table">
        <NutrientsTable :nutrient-info="source?.nutrientInfo" />
      </div>
    </div>
  </div>
</template>
<script>
import ProductThumbnailPlaceholder from '../../components/Icons/productThumbnailPlaceholder.vue'
import FeaturedNutrientItem from '../../components/common/FeaturedNutrientItem.vue'
import NutrientsTable from '../../components/common/NutrientsTable.vue'
import QualifiersModal from '~/components/SmartPage/components/common/QualifiersModal.vue'

const featuredNutrientsCodes = [
  {
    code: 'kcal',
    childs: ['energy', 'ener-'],
  },
  {
    code: 'fat',
    childs: ['fat'],
  },
  {
    code: 'hidr', // hidrato de carbono,
    childs: ['choavl', 'carbohydrates'],
  },
  {
    code: 'prot',
    childs: ['proteins', 'pro-'],
  },
  {
    code: 'fiber',
    childs: ['fiber', 'fibtg'],
  },
]
export default {
  name: 'NutritionTab',
  components: {
    QualifiersModal,
    ProductThumbnailPlaceholder,
    FeaturedNutrientItem,
    NutrientsTable,
  },
  props: {
    source: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      showQualifiersContent: false,
    }
  },
  computed: {
    isVariant() {
      return this.source?.isVariant
    },
    description() {
      return this.source?.basicInfo?.description || this.source?.basicInfo?.name
    },
    netContent() {
      return `${this.source?.netContent?.quantity ?? ''} ${
        this.source?.netContent?.unit ?? ''
      }`
    },
    drainedWeight() {
      return this.source?.drainedWeight?.quantity?.concat(
        this.source?.drainedWeight?.unit
      )
    },
    ean() {
      return this.source?.gtin
    },
    featuredNutrients() {
      const nutrients = this.source?.nutrientInfo?.nutrients || []
      // Buscar nutrientes según los 'childs' de 'featuredNutrientsCodes'
      return featuredNutrientsCodes
        .map((nutrient) =>
          nutrients.find((item) => nutrient.childs.includes(item.code))
        )
        .slice(0, 5)
        .filter((nutrient) => nutrient)
    },
  },
}
</script>
<style lang="scss" scoped>
@import '~/assets/scss/tokens.scss';

.nutrition-tab {
  display: flex;
  flex-direction: column;
  gap: 15px;
  height: 100%;

  .header {
    display: grid;
    grid-template-columns: 60% 1fr;
    column-gap: 1.25rem;

    .product-info {
      display: grid;
      grid-template-rows: repeat(3, auto);
      align-content: flex-start;
      row-gap: 0.5rem;

      p {
        margin: 0;
      }

      &__name {
        color: $DL-black;
        @include font-16-400;
      }

      &__net-content {
        @include font-12-500;
        color: $DL-gray-2;
      }

      &__ean {
        @include font-12-700;
        color: $DL-gray-2;
      }
    }

    .image-wrapper {
      width: inherit;
      height: inherit;
      max-width: inherit;
      min-height: inherit;
      max-height: 9rem;
      display: flex;
      justify-content: center;
      align-items: center;
      overflow: hidden;

      &.placeholder {
        display: flex;
        justify-content: flex-start;
        flex-direction: column;
      }

      img {
        max-width: 100%;
        max-height: 100%;
        width: auto;
        height: 100%;
        object-fit: contain;
      }
    }
  }

  .content {
    display: grid;
    grid-template-rows: 2rem auto 1fr;
    row-gap: 1rem;

    .title {
      color: $DL-black;
      @include font-24-700;
      margin: 0;
    }
  }

  .featured-nutrients {
    display: grid;
    margin-bottom: 20px;
    grid-template-columns: repeat(5, minmax(0, 1fr));
    height: fit-content;

    div {
      border-right: 2px solid $DL-gray-1;
      padding: 0.25rem;

      &:last-of-type {
        border: 0;
      }
    }
  }
}
</style>
