<template>
  <div
    v-click-outside="() =>(open = false)"
    class="custom-select"
    :class="{ 'custom-select--footer': footer }"
    :tabindex="tabindex"

  >
    <div
      class="selected"
      :class="{ open: open, 'selected--footer': footer }"
      @click="open = !open"
    >
      {{
        footer ? selectedOption?.[labelValue] : selectedOption?.[propertyValue]
      }}
      <svg
        v-if="footer"
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="17"
        viewBox="0 0 16 17"
        fill="none"
      >
        <path
          d="M13.8127 4.97852H12.6408C12.5611 4.97852 12.4861 5.01758 12.4393 5.08164L8.0002 11.2004L3.56114 5.08164C3.51426 5.01758 3.43926 4.97852 3.35957 4.97852H2.1877C2.08614 4.97852 2.02676 5.09414 2.08614 5.17695L7.59551 12.7723C7.79551 13.0473 8.20489 13.0473 8.40332 12.7723L13.9127 5.17695C13.9736 5.09414 13.9143 4.97852 13.8127 4.97852Z"
          fill="#121212"
          fill-opacity="0.6"
        />
      </svg>
      <svg
        v-else
        xmlns="http://www.w3.org/2000/svg"
        width="9"
        height="14"
        viewBox="0 0 9 14"
        fill="none"
      >
        <path
          d="M4.6143 2C4.54149 2 4.47159 2.01602 4.4046 2.04806C4.34053 2.08009 4.27208 2.12524 4.19927 2.18349L1.41203 4.43775C1.29262 4.53386 1.23291 4.66201 1.23291 4.82219C1.23291 4.92122 1.25767 5.01005 1.30718 5.08869C1.35669 5.16732 1.42077 5.22994 1.4994 5.27654C1.57804 5.32314 1.66396 5.34644 1.75716 5.34644C1.82123 5.34644 1.88531 5.33333 1.94938 5.30712C2.01345 5.28091 2.07753 5.24305 2.1416 5.19353L4.61867 3.2014L7.09573 5.19353C7.15689 5.24305 7.21951 5.28091 7.28359 5.30712C7.34766 5.33333 7.41174 5.34644 7.47581 5.34644C7.56901 5.34644 7.65493 5.32314 7.73357 5.27654C7.8122 5.22994 7.87628 5.16732 7.92579 5.08869C7.9753 5.01005 8.00006 4.92122 8.00006 4.82219C8.00006 4.66201 7.94181 4.53386 7.82531 4.43775L5.0337 2.18349C4.96088 2.12524 4.89099 2.08009 4.824 2.04806C4.75992 2.01602 4.69002 2 4.6143 2ZM4.6143 12C4.69002 12 4.75992 11.984 4.824 11.9519C4.89099 11.9199 4.96088 11.8748 5.0337 11.8165L7.82531 9.55789C7.94181 9.46177 8.00006 9.33508 8.00006 9.17781C8.00006 9.07587 7.9753 8.98558 7.92579 8.90695C7.87628 8.8254 7.8122 8.76278 7.73357 8.71909C7.65493 8.6754 7.56901 8.65356 7.47581 8.65356C7.41174 8.65356 7.34766 8.66667 7.28359 8.69288C7.21951 8.71618 7.15689 8.75404 7.09573 8.80647L4.61867 10.7986L2.1416 8.80647C2.07753 8.75404 2.01345 8.71618 1.94938 8.69288C1.88531 8.66667 1.82123 8.65356 1.75716 8.65356C1.66396 8.65356 1.57804 8.6754 1.4994 8.71909C1.42077 8.76278 1.35669 8.8254 1.30718 8.90695C1.25767 8.98558 1.23291 9.07587 1.23291 9.17781C1.23291 9.33508 1.29262 9.46177 1.41203 9.55789L4.19927 11.8165C4.27208 11.8719 4.34053 11.9155 4.4046 11.9476C4.47159 11.9825 4.54149 12 4.6143 12Z"
          fill="#121212"
          fill-opacity="0.6"
        />
      </svg>
    </div>
    <SimpleBar
      :class="{ 'items-hidden': !open, 'items--footer': footer }"
      class="items"
      :style="{ top: footer ? `-${itemsHeight}px` : '41px' }"
    >
      <div
        v-for="(option, i) of options"
        :key="i"
        :class="{ 'item-selected': selectedOption === option }"
        @click="
          selected = option[propertyValue]
          open = false
          $emit('input', option)
        "
      >
        {{ footer ? option?.[labelValue] : option?.[propertyValue] }}
      </div>
    </SimpleBar>
  </div>
</template>

<script>
export default {
  props: {
    options: {
      type: Array,
      required: true,
    },
    default: {
      type: String,
      required: false,
      default: null,
    },
    tabindex: {
      type: Number,
      required: false,
      default: 0,
    },
    labelValue: {
      type: String,
      required: false,
      default: 'name',
    },
    propertyValue: {
      type: String,
      required: false,
      default: 'id',
    },
    footer: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      selected: this.default
        ? this.default
        : this.options.length > 0
        ? this.options[0]
        : null,
      open: false,
      itemsHeight: 0,
    }
  },
  computed: {
    selectedOption() {
      return this.options.find(
        (option) => option[this.propertyValue] === this.selected
      )
    },
  },
  mounted() {
    this.itemsHeight = 200
  },
}
</script>

<style lang="scss" scoped>
.custom-select {
  position: relative;
  text-align: left;
  outline: none;
  height: 30px;
  line-height: 30px;
  text-transform: uppercase;

  &--footer {
    text-transform: capitalize;
  }
}

.custom-select .selected {
  cursor: pointer;
  user-select: none;
  border-radius: 4px;
  background: #fafafa;
  color: #615df4;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 42px;
  padding: 4px 8px;

  &--footer {
    width: 130px;
    color: rgba(18, 18, 18, 0.86);
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    border: 1px solid rgba(18, 18, 18, 0.16);

    .custom-select .selected {
      width: 100%;
    }
  }
}

.custom-select .selected.open {
  background: rgba(97, 93, 244, 0.08);
  color: #615df4;
}

.custom-select--footer .selected.open {
  border-radius: 4px;
  background: #fafafa;
  border: 2px solid #615df4;
  box-shadow: 0px 0px 4px 0px rgba(255, 255, 255, 0.5);
}

.custom-select .items {
  // overflow-y: hidden;
  position: absolute;
  left: 0;
  right: 0;
  top: 41px;
  z-index: 1;
  background: white;
  border-radius: 12px;
  border: 1px solid rgba(18, 18, 18, 0.08);
  background: #fff;
  box-shadow: 0px 4px 12px 0px rgba(18, 18, 18, 0.12);
  padding: 8px;
  max-height: 180px;
  // padding-right: 0;
}

.custom-select .items.items-hidden {
  display: none;
}

.custom-select .items div {
  border-radius: 4px;
  border: 0 solid #000;
  background: white;
  height: 33px;
  display: flex;
  padding: 0px 8px;
  padding-right: 0;
  align-items: center;
  gap: 8px;
  font-size: 12px;
  cursor: pointer;
  color: black;
  user-select: none;
}

.custom-select .items .item-selected {
  border-radius: 4px;
  border: 0 solid #000;
  background: #615df4;
  height: 33px;
  display: flex;
  padding: 0px 8px;
  align-items: center;
  gap: 8px;
  color: white;
  user-select: none;
}

.custom-select .items div:hover {
  border-radius: 4px;
  border: 0px solid #000;
  background: rgba(70, 99, 198, 0.08);
  color: #4663c6;
  cursor: pointer;
  font-family: Inter;
  font-size: 12px;
  font-weight: 600;
}

/* custom scrollbar */
// ::-webkit-scrollbar {
//   width: 20px;
//   background: transparent;
//   border-radius: 12px;
// }

// ::-webkit-scrollbar-track {
//   background-color: transparent;
// }

// ::-webkit-scrollbar-thumb {
//   background-color: #d6dee1;
//   border-radius: 20px;
//   border: 6px solid transparent;
//   background-clip: content-box;
// }

// ::-webkit-scrollbar-thumb:hover {
//   background-color: #a8bbbf;
// }
</style>
