import {paramCase} from 'change-case'

function buildStyles(theme) {
  const _styles = {}

  Object.keys(theme.style || {}).forEach(element => {
    const component = theme.style[element]
    const keys = Object.keys(component)
    _styles[element] = {}

    keys.forEach(key => {
      const newKey = paramCase(key)
      _styles[element][newKey] = component[key]
    })
  })

  return _styles
}

/**
 * Convert styles from an object K => V to an String separated with ;
 * changes _ to - for css
 * @param {*} styles
 */
function stylesToString(styles) {
  const keys = Object.keys(styles)

  return keys.map(k => {
    const key = k.replace('_', '-').toLowerCase()
    const value = styles[k]
    return `${key}: ${value}`
  }).join(';') + ';'
}

export default ({ app }, inject) => {
  inject('buildStyles', buildStyles)
  inject('stylesToString', stylesToString)
}
