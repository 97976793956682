import NotFound from './NotFound'
import LimitExceeded from './LimitExceeded'
import Expired from './Expired'
import NoContent from './NoContent'
import Inactive from './Inactive'

export default {
  NotFound,
  LimitExceeded,
  Expired,
  NoContent,
  Inactive
}
