export const QR_VARIANTS_LABELS = {
  10: 'component.modal.add.data.batch.label', // batch
  17: 'component.modal.add.data.exp.date.label', // expiration date
  // 22: 'component.modal.add.data.exp.date.label', // expiration date
  21: 'component.modal.add.data.serial.number.label', // serial number,
  22: 'component.modal.add.data.cpv.label',
  13: 'component.modal.add.data.packaging.date.label',
  3100: 'component.modal.add.data.net.weight.label',
  422: 'component.modal.add.data.country.origin.label',
}

export const QR_VARIANTS = {
  BATCH: 10,
  SERIAL_NUMBER: 21,
  EXPIRATION_DATE: 17,
  EXPIRATION_DATE_OLD: 22,
  CPV: 22,
  PACKAGING_DATE: 13,
  NET_WEIGHT: 3100,
  COUNTRY_OF_ORIGIN: 422,
  VARIANT: 'qrVariant',
}
