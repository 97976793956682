var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.filled
    ? _c(
        "svg",
        {
          attrs: {
            width: "32",
            height: "33",
            viewBox: "0 0 32 33",
            fill: "none",
            xmlns: "http://www.w3.org/2000/svg",
          },
        },
        [
          _c("path", {
            attrs: {
              "fill-rule": "evenodd",
              "clip-rule": "evenodd",
              d: "M15.9999 3.12973C8.67073 3.12973 2.72925 9.07121 2.72925 16.4004C2.72925 23.7296 8.67073 29.6711 15.9999 29.6711C23.3291 29.6711 29.2706 23.7296 29.2706 16.4004C29.2706 9.07121 23.3291 3.12973 15.9999 3.12973ZM15.9998 9.12067C16.7114 9.12067 17.2882 9.69752 17.2882 10.4091V16.8995C17.2882 17.611 16.7114 18.1879 15.9998 18.1879C15.2882 18.1879 14.7114 17.611 14.7114 16.8995V10.4091C14.7114 9.69752 15.2882 9.12067 15.9998 9.12067ZM17.9967 21.8923C17.9967 22.9952 17.1026 23.8893 15.9996 23.8893C14.8967 23.8893 14.0026 22.9952 14.0026 21.8923C14.0026 20.7894 14.8967 19.8953 15.9996 19.8953C17.1026 19.8953 17.9967 20.7894 17.9967 21.8923Z",
              fill: "#615DF4",
            },
          }),
        ]
      )
    : _c(
        "svg",
        {
          attrs: {
            width: "32",
            height: "33",
            viewBox: "0 0 32 33",
            fill: "none",
            xmlns: "http://www.w3.org/2000/svg",
          },
        },
        [
          _c("path", {
            attrs: {
              "fill-rule": "evenodd",
              "clip-rule": "evenodd",
              d: "M16 5.70654C10.094 5.70654 5.3062 10.4943 5.3062 16.4004C5.3062 22.3064 10.094 27.0942 16 27.0942C21.9061 27.0942 26.6939 22.3064 26.6939 16.4004C26.6939 10.4943 21.9061 5.70654 16 5.70654ZM2.72937 16.4004C2.72937 9.0712 8.67085 3.12971 16 3.12971C23.3292 3.12971 29.2707 9.0712 29.2707 16.4004C29.2707 23.7296 23.3292 29.6711 16 29.6711C8.67085 29.6711 2.72937 23.7296 2.72937 16.4004Z",
              fill: "#434263",
            },
          }),
          _vm._v(" "),
          _c("path", {
            attrs: {
              "fill-rule": "evenodd",
              "clip-rule": "evenodd",
              d: "M16.0001 9.12065C16.7117 9.12065 17.2885 9.69749 17.2885 10.4091V16.8995C17.2885 17.611 16.7117 18.1879 16.0001 18.1879C15.2885 18.1879 14.7117 17.611 14.7117 16.8995V10.4091C14.7117 9.69749 15.2885 9.12065 16.0001 9.12065Z",
              fill: "#434263",
            },
          }),
          _vm._v(" "),
          _c("path", {
            attrs: {
              d: "M16 23.8893C17.1029 23.8893 17.997 22.9952 17.997 21.8923C17.997 20.7893 17.1029 19.8952 16 19.8952C14.897 19.8952 14.0029 20.7893 14.0029 21.8923C14.0029 22.9952 14.897 23.8893 16 23.8893Z",
              fill: "#434263",
            },
          }),
        ]
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }