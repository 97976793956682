<template>
  <div class="smart-page-wrapper">
    <SmartPageHeaderComponent :title="productName" />
    <SmartPageTabs :source="productInfo" :tabs="tabs" />
  </div>
</template>

<script>
import SmartPageHeaderComponent from '../components/common/Header.vue'
import SmartPageTabs from '../components/common/Tabs/Tabs.vue'
import CompanyTab from './TabsContent/Company.vue'
import ingredientsIcon from '~/components/SmartPage/components/Icons/ingredientsIcon.vue'
import DetailsTab from '~/components/SmartPage/TextileSmartPage/TabsContent/Details.vue'
import circularityIcon from '~/components/SmartPage/components/Icons/circularityIcon.vue'
import ImpactTab from '~/components/SmartPage/TextileSmartPage/TabsContent/Impact.vue'
import sustainabilityIcon from '~/components/SmartPage/components/Icons/sustainabilityIcon.vue'
import CircularityTab from '~/components/SmartPage/TextileSmartPage/TabsContent/Circularity.vue'
import companyIcon from '~/components/SmartPage/components/Icons/companyIcon.vue'

export default {
  name: 'TextileSmartPage',
  components: {
    SmartPageHeaderComponent,
    SmartPageTabs,
  },
  props: {
    productInfo: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      tabs: [
        {
          name: 'details',
          key: 0,
          icon: ingredientsIcon,
          tabContent: DetailsTab,
        },
        {
          name: 'impact',
          key: 1,
          icon: sustainabilityIcon,
          tabContent: ImpactTab,
        },
        {
          name: 'circularity',
          key: 2,
          icon: circularityIcon,
          tabContent: CircularityTab,
        },
        {
          name: 'company',
          key: 3,
          icon: companyIcon,
          tabContent: CompanyTab,
        },
      ],
    }
  },
  computed: {
    productName() {
      return this.productInfo?.basicInfo?.name || ''
    },
  },
}
</script>

<style scoped lang="scss">
@media (min-width: 600px) {
  .smart-page-wrapper {
    max-width: 400px;
    border: 1px solid #eeeff4;
    border-radius: 0.5rem;
    overflow: hidden;
    max-height: 100%;
  }
}

body {
  margin: 0;

  .content-wrapper.smart-page {
    height: calc(100vh) !important;
    display: flex;
    justify-content: center;
    align-items: center;

    .smart-page-wrapper {
      height: 100%;
      width: 100%;
      display: grid;
      grid-template-rows: 4rem 1fr;
    }
  }
}
</style>
