<template>
  <div :class="['company-tab-wrapper', { 'custom-landing': landing }]">
    <div v-if="callToActions.length" class="company-tab">
      <p class="title">{{ $t('company.page.title') }}</p>
      <div class="body">
        <a
          v-for="(item, key) in callToActions"
          :key="key"
          target="_blank"
          :href="item.href"
        >
          <span v-if="item.icon" class="icon">{{ item.icon }}</span>
          <span class="label">
            {{ $t(`company.button.${item.name}.label`) }}</span
          >
        </a>
      </div>
      <div class="footer">
        <a :href="$config.privacyPageUrl" target="_blank">
          {{ $t('company.page.privacy.policy') }}
        </a>
      </div>
    </div>
    <EmptyState
      v-else-if="!callToActions.length && !landing"
      type="company"
      :source="source"
    />
    <client-only>
      <LandingRenderer
        v-if="landing"
        v-model="landing"
        :container-styles="containerStyles"
        :component-styles="componentStyles"
      />
    </client-only>
  </div>
</template>
<script>
import EmptyState from '../../components/common/EmptyState.vue'

export default {
  name: 'CompanyTab',
  components: { EmptyState },
  props: {
    source: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      containerStyles: ['my-own-container-styles'],
      componentStyles: ['my-own-component-styles'],
    }
  },
  computed: {
    callToActions() {
      let callToActions = []
      const actions = {
        email: (email) => ({
          icon: '✉️',
          href: `mailto:${email}`,
          name: 'email',
        }),
        website: (url) => {
          const href = this.buildURL(url)
          return {
            icon: '👩‍💻',
            href,
            name: 'website',
          }
        },
      }

      if (
        !this.source.companyInfo.landing &&
        Object.keys(this.source.companyInfo).length
      ) {
        callToActions = Object.keys(this.source.companyInfo).map((key) => {
          return actions[key](this.source.companyInfo[key])
        })
      }

      return callToActions
    },
    landing() {
      return this.source.companyInfo.landing
    },
  },
  methods: {
    buildURL(urlString) {
      const isValidUrl = (urlString) => {
        const urlPattern = new RegExp(
          '^(https?:\\/\\/)?' + // validate protocol
            '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // validate domain name
            '((\\d{1,3}\\.){3}\\d{1,3}))' + // validate OR ip (v4) address
            '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // validate port and path
            '(\\?[;&a-z\\d%_.~+=-]*)?' + // validate query string
            '(\\#[-a-z\\d_]*)?$',
          'i'
        ) // validate fragment locator
        return !!urlPattern.test(urlString)
      }
      return isValidUrl() ? urlString : `https://${urlString}`
    },
  },
}
</script>
<style lang="scss">
@import '~/assets/scss/tokens.scss';

.company-tab-wrapper {
  &.custom-landing {
    height: 100% !important;
    max-height: calc(100% - 4px) !important;
    max-width: 100%;
    padding: 0 !important;

    .qr-landing-renderer__wrapper {
      height: 100% !important;
      border: 0 !important;
      border-radius: 0 !important;
      max-height: 100% !important;
      display: grid !important;
      grid-template-columns: 1fr;

      .qr-landing-renderer__container {
        width: auto !important;
        height: calc(100% - 2rem) !important;
      }
    }
  }

  .company-tab {
    display: grid;
    grid-row-gap: 24px;
    grid-template-rows: 2rem auto 1fr;
    flex-direction: column;
    height: 100%;

    .title {
      color: $DL-black;
      @include font-24-700;
      margin: 0;
    }

    .body {
      display: flex;
      row-gap: 0.875rem;
      flex-flow: column;

      a {
        @include font-16-500;
        background: #32312f;
        border-radius: 2rem;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        color: $white-elevation-0;
        height: 3.625rem;
        position: relative;
        text-decoration: none;
        outline: none;
        user-select: none;

        .icon {
          position: absolute;
          left: 28px;
          top: 0;
          bottom: 0;
          margin: auto;
          line-height: 0px;
          height: fit-content;
        }
      }
    }

    .footer {
      display: flex;
      justify-content: center;

      a {
        @include font-11-400;
        color: $black-elevation-0;
        text-decoration-line: underline;
        height: fit-content;
        outline: none;
      }
    }
  }

  .my-own-container-styles {
    height: 100vh;
    width: 100%;
    padding: 10px 38%;
    overflow: hidden;
    overflow-y: auto;

    img {
      max-width: 100%;
    }
  }

  .my-own-component-styles {
    cursor: pointer;
    border: 1px transparent solid;

    h1,
    img {
      margin: 0;
    }

    iframe {
      min-height: 300px;
    }

    button {
      margin: 0;
    }

    > * {
      text-align: center;
      width: 100%;
      border: none;
    }
  }

  @media only screen and (max-width: 767px) {
    .my-own-container-styles {
      padding: 24px 16px 0 16px !important;
      max-height: calc(100% - 2.2rem);
      height: 100% !important;
    }

    .my-own-component-styles iframe {
      min-height: 200px;
    }
  }
}
</style>
