<template>
  <div class="smart-page-tabs-wrapper">
    <TabContent :source="source" :active-key="activekey">
      <component :is="activeTab?.tabContent" :source="source" />
    </TabContent>
    <TabLinksVue :tabs="tabs" :active-key="activekey" @change="setActiveTab" />
  </div>
</template>
<script>
import TabLinksVue from './TabLinks.vue'
import TabContent from './TabContent.vue'

export default {
  name: 'SmartPageTabsComponent',
  components: { TabLinksVue, TabContent },
  props: {
    source: {
      type: Object,
      default: () => {},
    },
    tabs: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      activekey: 0,
      activeTab: {},
    }
  },
  created() {
    this.setActiveTab({ tab: this.tabs[0] })
  },
  methods: {
    setActiveTab({ tab }) {
      this.activekey = tab.key
      this.activeTab = tab
    },
  },
}
</script>
<style lang="scss" scoped>
.smart-page-tabs-wrapper {
  display: grid;
  grid-template-rows: calc(100% - 4.1rem) 4rem;
  overflow: hidden;
}
</style>
