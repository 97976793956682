<template>
  <div :class="{ loader: true, fadeout: !isLoading }">
    <svg
      class="animated-spin"
      width="74"
      height="79"
      viewBox="0 0 74 79"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle opacity="0.5" cx="23.2" cy="25.2" r="9.2" fill="#615DF4" />
      <circle cx="23.2" cy="52.8001" r="9.2" fill="#615DF4" />
      <circle opacity="0.3" cx="50.8001" cy="25.2" r="9.2" fill="#615DF4" />
      <circle opacity="0.6" cx="50.8001" cy="52.8001" r="9.2" fill="#615DF4" />
    </svg>
  </div>
</template>

<script>
export default {
  name: 'LoadingScreen',
  props: {
    isLoading: Boolean,
  },
}
</script>

<style>
.loader {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 10000;
  background-color: rgba(0, 0, 0, 0.2);
  pointer-events: none;
  display: flex;
  justify-content: center;
  align-items: center;
}
.animated-spin {
  z-index: 10000;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.fadeout {
  animation: fadeout 2s infinite;
}

@keyframes fadeout {
  to {
    opacity: 0;
    visibility: hidden;
  }
}
</style>
