<template>
  <div class="smart-page-wrapper">
    <SmartPageHeaderComponent :title="productInfo?.basicInfo?.name" />
    <SmartPageTabs :source="productInfo" :tabs="tabs" />
  </div>
</template>
<script>
import SmartPageHeaderComponent from '../components/common/Header.vue'
import SmartPageTabs from '../components/common/Tabs/Tabs.vue'
import nutritionIconVue from '../components/Icons/nutritionIcon.vue'
import ingredientsIconVue from '../components/Icons/ingredientsIcon.vue'
import DOIcon from '../components/Icons/DOIcon.vue'
import companyIconVue from '../components/Icons/companyIcon.vue'
import NutritionTab from './TabsContent/Composition.vue'
import CompanyTab from './TabsContent/Company.vue'
import UseModeTab from './TabsContent/UseMode.vue'
import DOTab from './TabsContent/DO.vue'

export default {
  name: 'CannabisSmartPage',
  components: { SmartPageHeaderComponent, SmartPageTabs },
  props: {
    productInfo: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      tabs: [
        {
          name: 'composition',
          key: 0,
          icon: nutritionIconVue,
          tabContent: NutritionTab,
        },
        {
          name: 'use',
          key: 1,
          icon: ingredientsIconVue,
          tabContent: UseModeTab,
        },
        {
          name: 'origin',
          key: 2,
          icon: DOIcon,
          tabContent: DOTab,
        },
        {
          name: 'company',
          key: 3,
          icon: companyIconVue,
          tabContent: CompanyTab,
        },
      ],
    }
  },
}
</script>
<style lang="scss">
@media (min-width: 600px) {
  .smart-page-wrapper {
    max-width: 400px;
    border: 1px solid #eeeff4;
    border-radius: 0.5rem;
    overflow: hidden;
    max-height: 95%;
  }
}

body {
  margin: 0;

  .content-wrapper.smart-page {
    height: calc(100vh) !important;
    display: flex;
    justify-content: center;
    align-items: center;

    .smart-page-wrapper {
      height: 100%;
      width: 100%;
      display: grid;
      grid-template-rows: 4rem 1fr;
    }
  }
}
</style>
