<template>
  <div>
    <div v-if="source?.additionalInfo?.circularity">
      <div class="title" style="margin: 10px 0">
        {{ $t('circularity.page.title') }}
      </div>
      <div class="body" v-html="source?.additionalInfo?.circularity" />
    </div>
    <EmptyState v-else type="sustainability" :source="source" />
  </div>
</template>

<script>
import EmptyState from '../../components/common/EmptyState.vue'

export default {
  components: { EmptyState },
  props: {
    source: {
      type: Object,
      default: () => ({}),
    },
  },
}
</script>
<style scoped lang="scss">
.body {
  color: var(--DL-Black, #32312f);
  font-family: Inter, serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 23px;
}

.title {
  color: #32312f;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
</style>
