var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "click-outside",
          rawName: "v-click-outside",
          value: () => (_vm.open = false),
          expression: "() =>(open = false)",
        },
      ],
      staticClass: "custom-select",
      class: { "custom-select--footer": _vm.footer },
      attrs: { tabindex: _vm.tabindex },
    },
    [
      _c(
        "div",
        {
          staticClass: "selected",
          class: { open: _vm.open, "selected--footer": _vm.footer },
          on: {
            click: function ($event) {
              _vm.open = !_vm.open
            },
          },
        },
        [
          _vm._v(
            "\n    " +
              _vm._s(
                _vm.footer
                  ? _vm.selectedOption?.[_vm.labelValue]
                  : _vm.selectedOption?.[_vm.propertyValue]
              ) +
              "\n    "
          ),
          _vm.footer
            ? _c(
                "svg",
                {
                  attrs: {
                    xmlns: "http://www.w3.org/2000/svg",
                    width: "16",
                    height: "17",
                    viewBox: "0 0 16 17",
                    fill: "none",
                  },
                },
                [
                  _c("path", {
                    attrs: {
                      d: "M13.8127 4.97852H12.6408C12.5611 4.97852 12.4861 5.01758 12.4393 5.08164L8.0002 11.2004L3.56114 5.08164C3.51426 5.01758 3.43926 4.97852 3.35957 4.97852H2.1877C2.08614 4.97852 2.02676 5.09414 2.08614 5.17695L7.59551 12.7723C7.79551 13.0473 8.20489 13.0473 8.40332 12.7723L13.9127 5.17695C13.9736 5.09414 13.9143 4.97852 13.8127 4.97852Z",
                      fill: "#121212",
                      "fill-opacity": "0.6",
                    },
                  }),
                ]
              )
            : _c(
                "svg",
                {
                  attrs: {
                    xmlns: "http://www.w3.org/2000/svg",
                    width: "9",
                    height: "14",
                    viewBox: "0 0 9 14",
                    fill: "none",
                  },
                },
                [
                  _c("path", {
                    attrs: {
                      d: "M4.6143 2C4.54149 2 4.47159 2.01602 4.4046 2.04806C4.34053 2.08009 4.27208 2.12524 4.19927 2.18349L1.41203 4.43775C1.29262 4.53386 1.23291 4.66201 1.23291 4.82219C1.23291 4.92122 1.25767 5.01005 1.30718 5.08869C1.35669 5.16732 1.42077 5.22994 1.4994 5.27654C1.57804 5.32314 1.66396 5.34644 1.75716 5.34644C1.82123 5.34644 1.88531 5.33333 1.94938 5.30712C2.01345 5.28091 2.07753 5.24305 2.1416 5.19353L4.61867 3.2014L7.09573 5.19353C7.15689 5.24305 7.21951 5.28091 7.28359 5.30712C7.34766 5.33333 7.41174 5.34644 7.47581 5.34644C7.56901 5.34644 7.65493 5.32314 7.73357 5.27654C7.8122 5.22994 7.87628 5.16732 7.92579 5.08869C7.9753 5.01005 8.00006 4.92122 8.00006 4.82219C8.00006 4.66201 7.94181 4.53386 7.82531 4.43775L5.0337 2.18349C4.96088 2.12524 4.89099 2.08009 4.824 2.04806C4.75992 2.01602 4.69002 2 4.6143 2ZM4.6143 12C4.69002 12 4.75992 11.984 4.824 11.9519C4.89099 11.9199 4.96088 11.8748 5.0337 11.8165L7.82531 9.55789C7.94181 9.46177 8.00006 9.33508 8.00006 9.17781C8.00006 9.07587 7.9753 8.98558 7.92579 8.90695C7.87628 8.8254 7.8122 8.76278 7.73357 8.71909C7.65493 8.6754 7.56901 8.65356 7.47581 8.65356C7.41174 8.65356 7.34766 8.66667 7.28359 8.69288C7.21951 8.71618 7.15689 8.75404 7.09573 8.80647L4.61867 10.7986L2.1416 8.80647C2.07753 8.75404 2.01345 8.71618 1.94938 8.69288C1.88531 8.66667 1.82123 8.65356 1.75716 8.65356C1.66396 8.65356 1.57804 8.6754 1.4994 8.71909C1.42077 8.76278 1.35669 8.8254 1.30718 8.90695C1.25767 8.98558 1.23291 9.07587 1.23291 9.17781C1.23291 9.33508 1.29262 9.46177 1.41203 9.55789L4.19927 11.8165C4.27208 11.8719 4.34053 11.9155 4.4046 11.9476C4.47159 11.9825 4.54149 12 4.6143 12Z",
                      fill: "#121212",
                      "fill-opacity": "0.6",
                    },
                  }),
                ]
              ),
        ]
      ),
      _vm._v(" "),
      _c(
        "SimpleBar",
        {
          staticClass: "items",
          class: { "items-hidden": !_vm.open, "items--footer": _vm.footer },
          style: { top: _vm.footer ? `-${_vm.itemsHeight}px` : "41px" },
        },
        _vm._l(_vm.options, function (option, i) {
          return _c(
            "div",
            {
              key: i,
              class: { "item-selected": _vm.selectedOption === option },
              on: {
                click: function ($event) {
                  _vm.selected = option[_vm.propertyValue]
                  _vm.open = false
                  _vm.$emit("input", option)
                },
              },
            },
            [
              _vm._v(
                "\n      " +
                  _vm._s(
                    _vm.footer
                      ? option?.[_vm.labelValue]
                      : option?.[_vm.propertyValue]
                  ) +
                  "\n    "
              ),
            ]
          )
        }),
        0
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }