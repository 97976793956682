<template>
  <div class="empty-state-wrapper">
    <div class="empty-state-content">
      <component
        :is="emptyStateData.icon"
        v-if="emptyStateData.icon"
        class="icon"
      />

      <p class="title">{{ $t(`${emptyStateData.name}.empty.headline`) }}</p>
      <p class="body">{{ $t(`${emptyStateData.name}.empty.body`) }}</p>
      <a :href="emptyStateData.href">
        {{ $t(`${emptyStateData.name}.empty.cta`) }}
      </a>
    </div>
  </div>
</template>
<script>
import companyEmptyStateIcon from '../Icons/companyEmptyStateIcon.vue'
import notResultIcon from '../Icons/notResultIcon.vue'
export default {
  name: 'SmartPageEmptyStateComponent',
  components: { companyEmptyStateIcon, notResultIcon },
  props: {
    type: {
      type: String,
      required: true,
      default: () => '',
    },
    source: {
      type: Object,
      required: true,
      default: () => {},
    },
  },
  computed: {
    emptyStateData() {
      return {
        allergens: {
          icon: notResultIcon,
          name: 'allergens',
          href: `mailto:info@digital-link.com?subject=Edit info GTIN ${this.source?.gtin}`,
        },
        ingredients: {
          icon: notResultIcon,
          name: 'ingredients',
          href: `mailto:info@digital-link.com?subject=Edit info GTIN ${this.source?.gtin}`,
        },
        company: {
          icon: companyEmptyStateIcon,
          name: 'company',
          href: `mailto:info@digital-link.com?subject=Edit info GTIN ${this.source?.gtin}`,
        },
        designation: {
          icon: notResultIcon,
          name: 'designation',
          href: `mailto:info@digital-link.com?subject=Edit info GTIN ${this.source?.gtin}`,
        },
        sustainability: {
          icon: notResultIcon,
          name: 'sustainability',
          href: `mailto:info@digital-link.com?subject=Edit info GTIN ${this.source?.gtin}`,
        },
      }[this.type]
    },
  },
}
</script>
<style lang="scss" scoped>
@import '~/assets/scss/tokens.scss';

.empty-state-wrapper {
  display: flex;
  justify-content: center;
  text-align: center;
  height: 100%;
  width: 100%;
  .empty-state-content {
    display: flex;
    align-items: center;
    flex-direction: column;
    margin-top: 5rem;
    max-width: 18rem;
  }
  .icon {
    margin-bottom: 1rem;
  }
  .title {
    @include font-20-600;
    color: $DL-black;
    margin: 0 0 0.5rem 0;
  }

  .body {
    @include font-14-400;
    color: $DL-gray-3;
    line-height: 22px;
    margin: 0 0 0.5rem 0;
  }

  a {
    display: flex;
    width: 8rem;
    height: 2rem;
    padding: 0px 21px;
    border: 0;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 8px;
    flex-shrink: 0;
    border-radius: 4px;
    background: $primary-color;
    color: $white-elevation-0;
    text-decoration: unset;
    @include font-14-600;
    margin-top: 1.5rem;
  }
}
</style>
