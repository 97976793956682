<template>
  <div class="content">
    <div class="content-body">
      <img src="~/assets/images/logo.svg" class="logo" alt="digitallinkLogo" />
      <div class="mt-3 w-100 d-flex flex-column align-items-center h-75">
        <p class="text-center fw-bolder fs-2 mt-3">
          Sorry, this QR code Oiooi oio io io i oi oi o i
        </p>
        <p class="fw-400 text-center fs-5 m-0">
          Is this your QR? oio iioioioioioio oiooiioiioi ioioio ioioio io ioi
        </p>
        <div class="security-lock-image">
          <img src="~/assets/images/security-lock.svg" />
        </div>
      </div>

      <div class="d-flex flex-column powered-image align-items-center">
        <button class="button mb-3 w-100 black">Oioioioio oioi</button>
        <button class="button white w-100">Visit our Help Center</button>
        <img class="pt-4" src="~/assets/images/powered-by-digitallink.svg" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ExpiredComponent',
}
</script>

<style scoped>
.content {
  background: url('~@/assets/images/bg-gray.svg');
  height: 100vh;
  display: flex;
  justify-content: center;
  background-position: bottom;
  background-color: #d4d4d4;
  background-repeat: repeat no-repeat;
  background-size: cover;
}
.content-body {
  max-width: 900px;
  width: 100%;
  padding: 2rem;
  position: relative;
  display: flex;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.content-body .logo {
  width: 10rem;
}

.security-lock-image {
  position: relative;
  width: 100%;
  height: inherit;
}

.security-lock-image img {
  position: absolute;
  margin: auto;
  right: 0;
  left: 0;
  bottom: 0;
  top: -5%;
  height: 15rem;
  padding: 1rem;
}

.powered-image {
  position: absolute;
  bottom: 0%;
}

.powered-image img {
  width: auto;
}

.url {
  font-weight: 700;
  background: white;
  padding: 1rem;
  padding: 1rem 2rem;
}

.text-center {
  text-align: center;
}

img {
  max-width: 100% !important;
}

.button {
  text-align: center;
  vertical-align: middle;
  display: inline-block;
  font-weight: 400;
  color: #3c4b64;
  padding: 0.375rem 5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  border: unset;
  position: relative;
  height: 3rem;
  max-width: 100%;
}
.button.black {
  background: black;
  color: white;
}
.button.white {
  background: white;
  color: black;
}
button:focus {
  outline: unset !important;
}
</style>
