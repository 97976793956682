<template>
  <div class="smart-page-wrapper">
    <SmartPageHeaderComponent :title="productName" />
    <SmartPageTabs :source="productInfo" :tabs="tabs" />
  </div>
</template>

<script>
import SmartPageHeaderComponent from '../components/common/Header.vue'
import SmartPageTabs from '../components/common/Tabs/Tabs.vue'
import CompanyTab from './TabsContent/Company.vue'
import ingredientsIcon from '~/components/SmartPage/components/Icons/ingredientsIcon.vue'
import IngredientsTab from '~/components/SmartPage/CleaningSmartPage/TabsContent/Ingredients.vue'
import userManualIcon from '~/components/SmartPage/components/Icons/userManualIcon.vue'
import InstructionsTab from '~/components/SmartPage/CleaningSmartPage/TabsContent/Instructions.vue'
import sustainabilityIcon from '~/components/SmartPage/components/Icons/sustainabilityIcon.vue'
import SustainabilityTab from '~/components/SmartPage/CleaningSmartPage/TabsContent/Sustainability.vue'
import companyIcon from '~/components/SmartPage/components/Icons/companyIcon.vue'

export default {
  name: 'CleaningSmartPage',
  components: {
    SmartPageHeaderComponent,
    SmartPageTabs,
  },
  props: {
    productInfo: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      tabs: [
        {
          name: 'ingredients',
          key: 0,
          icon: ingredientsIcon,
          tabContent: IngredientsTab,
        },
        {
          name: 'manual',
          key: 1,
          icon: userManualIcon,
          tabContent: InstructionsTab,
        },
        {
          name: 'sustainability',
          key: 2,
          icon: sustainabilityIcon,
          tabContent: SustainabilityTab,
        },
        {
          name: 'company',
          key: 3,
          icon: companyIcon,
          tabContent: CompanyTab,
        },
      ],
    }
  },
  computed: {
    productName() {
      return this.productInfo?.basicInfo?.name || ''
    },
  },
}
</script>

<style scoped lang="scss">
@media (min-width: 600px) {
  .smart-page-wrapper {
    max-width: 400px;
    border: 1px solid #eeeff4;
    border-radius: 0.5rem;
    overflow: hidden;
    max-height: 100%;
  }
}

body {
  margin: 0;

  .content-wrapper.smart-page {
    height: calc(100vh) !important;
    display: flex;
    justify-content: center;
    align-items: center;

    .smart-page-wrapper {
      height: 100%;
      width: 100%;
      display: grid;
      grid-template-rows: 4rem 1fr;
    }
  }
}
</style>
