var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "smart-page__tabs-content",
      style: _vm.activeKey === 3 ? _vm.source?.background : "",
    },
    [_vm._t("default", null, null, _vm.$attrs)],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }