<template>
  <component :is="code" />
</template>

<script>
import components from './components'

export default {
  name: 'ErrorRenderer',
  components,
  props: {
    code: { type: String, default: '' },
    linkAfter: { type: String, default: '' },
  },
  beforeMount() {
    if (
      this.linkAfter &&
      this.linkAfter.length &&
      this.code === 'LimitExceeded'
    ) {
      window.open(this.linkAfter)
    }
  },
}
</script>
