<template>
  <svg
    v-if="filled"
    width="32"
    height="33"
    viewBox="0 0 32 33"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M16.0004 3.17104C15.4053 3.17104 14.8392 3.40098 14.4114 3.80401L14.4095 3.80583L4.22095 13.3633L4.20267 13.3808C3.97437 13.6034 3.79383 13.8685 3.66962 14.1595C3.54543 14.4504 3.47947 14.7625 3.47453 15.0777L3.47437 15.0983V27.2627C3.47437 27.8773 3.71048 28.4747 4.14341 28.9216C4.57771 29.3699 5.17581 29.6298 5.80861 29.6298H11.9237C12.5564 29.6298 13.1546 29.3699 13.5889 28.9216C14.0218 28.4747 14.2579 27.8773 14.2579 27.2627V21.2134H17.7428V27.2627C17.7428 27.8772 17.9789 28.4747 18.4119 28.9216C18.8462 29.3699 19.4443 29.6298 20.0771 29.6298H26.1921C26.8249 29.6298 27.423 29.3699 27.8573 28.9216C28.2902 28.4747 28.5264 27.8772 28.5264 27.2627V15.0983L28.5262 15.0777C28.5212 14.7625 28.4553 14.4504 28.3311 14.1595C28.2069 13.8685 28.0263 13.6034 27.798 13.3808L27.7798 13.3633L17.5912 3.80583L17.5893 3.80401C17.1616 3.40098 16.5954 3.17104 16.0004 3.17104Z"
      fill="#615DF4"
    />
  </svg>
  <svg
    v-else
    width="32"
    height="33"
    viewBox="0 0 32 33"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M16.0001 5.91969L6.10425 15.2026V26.9997H11.6275V20.9504C11.6275 20.3358 11.8636 19.7383 12.2966 19.2914C12.7309 18.8431 13.329 18.5832 13.9618 18.5832H18.0385C18.6713 18.5832 19.2694 18.8431 19.7037 19.2914C20.1366 19.7383 20.3727 20.3358 20.3727 20.9504V26.9997H25.896V15.2026L16.0001 5.91969ZM14.4112 3.80399C14.8389 3.40096 15.4051 3.17102 16.0001 3.17102C16.5951 3.17102 17.1613 3.40096 17.589 3.80399C17.5897 3.8046 17.5903 3.8052 17.591 3.80581L27.7795 13.3633L27.7978 13.3808C28.0261 13.6034 28.2066 13.8685 28.3309 14.1595C28.455 14.4504 28.521 14.7625 28.5259 15.0777L28.5261 15.0983V27.2627C28.5261 27.8772 28.29 28.4747 27.8571 28.9216C27.4228 29.3699 26.8247 29.6298 26.1919 29.6298H20.0768C19.444 29.6298 18.8459 29.3699 18.4116 28.9216C17.9787 28.4747 17.7426 27.8772 17.7426 27.2627V21.2134H14.2577V27.2627C14.2577 27.8772 14.0215 28.4747 13.5886 28.9216C13.1543 29.3699 12.5562 29.6298 11.9234 29.6298H5.80836C5.17557 29.6298 4.57747 29.3699 4.14316 28.9216C3.71023 28.4747 3.47412 27.8772 3.47412 27.2627V15.0983L3.47428 15.0777C3.47922 14.7625 3.54518 14.4504 3.66937 14.1595C3.79359 13.8685 3.97413 13.6034 4.20242 13.3808L4.2207 13.3633L14.4093 3.80581C14.4099 3.8052 14.4105 3.8046 14.4112 3.80399Z"
      fill="#434263"
    />
  </svg>
</template>
<script>
export default {
  name: 'CompanyIcon',
  props: {
    filled: {
      type: Boolean,
      default: false,
    },
  },
}
</script>
