<template>
  <div class="content">
    <div class="content-body">
      <img src="~/assets/images/logo.svg" class="logo" alt="digitallinkLogo" />
      <div class="h-100 d-flex flex-column justify-content-between">
        <div class="mt-3 w-100 d-flex flex-column align-items-center">
          <p class="text-center fw-bolder fs-3 mt-4 w-75">
            Sorry, this QR code has been deactivated.
          </p>
          <p class="fw-400 w-75 fs-5 text-center">
            Is this your QR? Log in to your digital-link.com account to reactivate it.
          </p>
          <img class="headstone-image" src="~/assets/images/headstone.svg" />
        </div>

        <div class="d-flex flex-column align-items-center">
          <button class="button mb-3 black">Log in now</button>
          <img class="pt-4" src="~/assets/images/powered-by-digitallink.svg" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'LimitExceededComponent',
}
</script>

<style scoped>
.content {
  background: url('~@/assets/images/bg-gray.svg');
  height: 100vh;
  display: flex;
  justify-content: center;
  background-position: bottom;
  background-color: #d4d4d4;
  background-repeat: repeat no-repeat;
  background-size: cover;
}

@media only screen and (max-width: 600px) {
  .content-body {
    max-width: 900px;
    width: 100%;
    padding: 2rem 0.5rem;
    position: relative;
    display: flex;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}

@media only screen and (min-width: 600px) {
  .content-body {
    max-width: 900px;
    width: 100%;
    padding: 2rem;
    position: relative;
    display: flex;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}

.content-body .logo {
  width: 10rem;
}

.headstone-image {
  width: auto;
  height: 15rem;
  max-height: 80%;
  margin-top: 2rem;
}

.powered-image {
  position: absolute;
  bottom: 0%;
}

.powered-image img {
  width: auto;
}

.url {
  font-weight: 700;
  background: white;
  padding: 1rem;
  padding: 1rem 2rem;
}

.text-center {
  text-align: center;
}

img {
  max-width: 100% !important;
}

.button {
  text-align: center;
  vertical-align: middle;
  display: inline-block;
  font-weight: 400;
  color: #3c4b64;
  padding: 0.375rem 5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  border: unset;
  position: relative;
  /* width: 20rem; */
  height: 3rem;
  max-width: 100%;
}
.button.black {
  background: black;
  color: white;
}
.button.white {
  background: white;
  color: black;
}
button:focus {
  outline: unset !important;
}
</style>
