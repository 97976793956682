var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("div", { staticClass: "title", staticStyle: { margin: "10px 0" } }, [
        _vm._v("\n    " + _vm._s(_vm.$t("sustainability.page.title")) + "\n  "),
      ]),
      _vm._v(" "),
      _vm.source?.additionalInfo?.sustainabilityInfo
        ? _c("div", { staticClass: "body" }, [
            _c("p", {
              domProps: {
                innerHTML: _vm._s(
                  _vm.source?.additionalInfo?.sustainabilityInfo
                ),
              },
            }),
          ])
        : _c("EmptyState", {
            attrs: { type: "sustainability", source: _vm.source },
          }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }