var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm.source?.additionalInfo?.circularity
        ? _c("div", [
            _c(
              "div",
              { staticClass: "title", staticStyle: { margin: "10px 0" } },
              [
                _vm._v(
                  "\n      " +
                    _vm._s(_vm.$t("circularity.page.title")) +
                    "\n    "
                ),
              ]
            ),
            _vm._v(" "),
            _c("div", {
              staticClass: "body",
              domProps: {
                innerHTML: _vm._s(_vm.source?.additionalInfo?.circularity),
              },
            }),
          ])
        : _c("EmptyState", {
            attrs: { type: "sustainability", source: _vm.source },
          }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }