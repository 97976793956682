<template>
  <div class="smart-page__tabs">
    <button
      v-for="(tab, key) in tabs"
      :key="key"
      :class="{ 'tab-links': true, active: activeKey == tab.key }"
      @click="(event) => $emit('change', { tab, event })"
    >
      <component
        :is="tab.icon"
        v-if="tab.icon"
        class="icon"
        :filled="activeKey == tab.key"
      />
      <label class="label">{{ $t(`${tab.name}.tab.label`) }}</label>
    </button>
  </div>
</template>
<script>
export default {
  name: 'SmartPageTabLinksComponent',
  props: {
    tabs: {
      type: Array,
      default: () => [],
    },
    activeKey: {
      type: Number,
      default: () => 0,
    },
  },
  emits: ['change'],
}
</script>
<style lang="scss" scoped>
@import '~/assets/scss/tokens.scss';
.smart-page {
  &__tabs {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    border-top: 1px solid $white-elevation-0;
    background: #eeeff5;
    padding: 0.5rem;
    .tab-links {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      background: transparent;
      border: 0;
      outline: none;
      font-size: 11px !important;
      min-width: max-content;

      &.active {
        .label {
          color: $primary-color;
        }
      }
      .label {
        color: $DL-black;
        @include font-12-500;
        margin: 0;
        text-transform: capitalize;
      }
    }
  }
}
</style>
