var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "smart-page-tabs-wrapper" },
    [
      _c(
        "TabContent",
        { attrs: { source: _vm.source, "active-key": _vm.activekey } },
        [
          _c(_vm.activeTab?.tabContent, {
            tag: "component",
            attrs: { source: _vm.source },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c("TabLinksVue", {
        attrs: { tabs: _vm.tabs, "active-key": _vm.activekey },
        on: { change: _vm.setActiveTab },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }